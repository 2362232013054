import React, { useState, useEffect, useRef } from 'react';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { dataUriToBuffer } from 'data-uri-to-buffer';

// Components 

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { setImageUrl, setSection } from '../../../redux/features/doctor/Dashboard/consultation/camera';
import { uploadImageWithPin } from '../../../routes/doctor/consultation';
import { setNotificationMessage } from '../../../redux/features/general/notification';

const Upload: React.FC = () => {

    const dispatch = useAppDispatch(); 
    const { t } = useTranslation(); 
    const navigate = useNavigate(); 

    const { consultationId, securityPin } = useParams(); 

    const colors = useAppSelector(state => state.theme.colors); 

    const section = useAppSelector(state => state.camera.section); 
    const imageUrl = useAppSelector(state => state.camera.imageUrl); 

    const [isImageUploaded, setIsImageUploaded] = useState(false);

    // -- 

    const handleUpload = async () => { 

        const blob = await (await fetch(imageUrl)).blob(); 

        // const buffer = dataUriToBuffer(imageUrl) as any; 

        const newFile = new File([blob], String(consultationId), { type: 'image/png' });

        const formData = new FormData() as any; 

        formData.append('consultationId', consultationId); 
        formData.append('securityPin', securityPin); 
        formData.append('file', newFile); 

        const res = await uploadImageWithPin(formData, consultationId) as any; 

        if (res.status === 200) { 

            setIsImageUploaded(true); 
            dispatch(setNotificationMessage(t('imageUploadedToConsultation')));
            dispatch(setImageUrl(''));  

        }; 

    }; 

    // -- 

    const handleRetry = async () => { 

        setIsImageUploaded(false);
        dispatch(setImageUrl('')); 
        dispatch(setSection('video')); 

    }; 

    // -- 

    return (

        <Box sx={{ 
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center', 
            width: '100vw',
            height: '100vh', 
            overflow: 'hidden', 
            position: 'absolute',
            color: colors?.text,
            backgroundColor: colors?.container
        }}>

            <Box
                component="img"
                sx={{
                    height: `${20}px`,
                    width: 'auto',
                    marginTop: 2, 
                }}
                alt="Solutions Medca Inc."
                src={require('../../../assets/logos/medca_logo_blue_dark_blue.png')}
            />

            { imageUrl ? 
                <Box
                component="img"
                sx={{
                    maxWidth: `70%`,
                    height: 'auto',
                    marginTop: 5,
                }}
                alt="Solutions Medca Inc."
                src={imageUrl}
            />: 
            <Box
                component="img"
                sx={{
                    maxWidth: `70%`,
                    height: 'auto',
                    marginTop: 5,
                }}
                alt="Solutions Medca Inc."
                src={require('../../../assets/consultation/blank.png')}
            />}

            { isImageUploaded &&
                <Box
                component="img"
                sx={{
                    width: `50%`,
                    bottom: '40%', 
                    height: 'auto',
                    position: 'absolute', 
                    marginTop: 5,
                }}
                src={require('../../../assets/icons/consultation/upload-success.png')}
            />}

            {imageUrl &&
            <Button 
                onClick={handleUpload}
                sx={{ 
                    minWidth: 200, 
                    textTransform: 'none',
                    marginTop: 5, 
                    backgroundColor: colors?.createPatient 
                }} 
                variant="contained">{t('upload')}
            </Button>}

            <Button 
                onClick={handleRetry}
                sx={{ 
                    minWidth: 200, 
                    textTransform: 'none',
                    marginTop: 5, 
                    backgroundColor: colors?.createPatient 
                }} 
                variant="contained">{t('takeNewOne')}
            </Button>

        </Box>

  );

};

export default Upload;