import React, { useEffect } from 'react';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import { useAppDispatch, useAppSelector } from './../../../redux/hooks';

// Components
import NavBar from '../../general/navBar/NavBar';
import Settings from '../navigation/navBar/Settings';
import Sidebar from '../../general/navigation/Sidebar';

// Redux
import { setDashboardSection, setSideBarMenu } from '../../../redux/features/general/navigation';

// Navigation menus
import menuItems from '../../../util/navigation/sidebarMenu/doctor';
import doctorDashboardMenu from '../../../util/navigation/dashboard/doctor';

// Dashboard Interfaces 

import FileExplorer from './fileExplorer/FileExplorer';
import Templates from './templates/Templates';
import Consultation from './consultation/Consultation';

import PatientsInterface from './PatientsInterface';
import InsurersInterface from './InsurersInterface';

import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import ImageViewer from './viewers/image/ImageViewer';
import { getConsultation } from '../../../routes/doctor/consultation';
import { setConsultationId, setIsRefreshContent, setPatientId, setPatientName, setSection } from '../../../redux/features/doctor/Dashboard/consultation/consultation';
import { getMetaPatientInfo } from '../../../routes/doctor/patients';
import FileViewer from './viewers/file/FileViewer';
import NoteViewer from './viewers/note/NoteViewer';

const Container: React.FC = () => {

  const navigate = useNavigate(); 
  const dispatch = useAppDispatch();
  const { t, i18n } = useTranslation();  
  
  const { currentSection, consultationId } = useParams() as any; 

  const colors = useAppSelector(state => state.theme.colors); 
  const section = useAppSelector((state) => state.navigation.section);
  const consultation = useAppSelector((state) => state.consultation.consultationId);
  const isImageViewerOpened = useAppSelector(state => state.imageViewer.isImageViewerOpened); 
  const isFileViewerOpened = useAppSelector(state => state.fileViewer.isFileViewerOpened); 
  const isNoteViewerOpened = useAppSelector(state => state.noteViewer.isNoteViewerOpened); 

  const container = document.getElementById("mainContainer") as any; 

  container?.addEventListener("contextmenu", (e: any) => {e.preventDefault()});

  // -- 

  window.onbeforeunload = function (e: any) {

    if (consultation) { 

      // For IE and Firefox

      if (e) {
        e.returnValue;
      }; 
    
      // For Safari
      return e.returnValue;

    };

  };

  // -- 

  const handleConsultation = async () => { 

    const res = await getConsultation(consultationId) as any; 

    if (res.data.status === 200) { 

      if (res.data.consultation.status === 'pending') { 

        const result = await getMetaPatientInfo(res.data.consultation.patientId) as any; 

        dispatch(setPatientName(result.data.metaPatient.fullName)); 
        dispatch(setConsultationId(res.data.consultation._id)); 
        dispatch(setPatientId(res.data.consultation.patientId)); 
        dispatch(setIsRefreshContent(true)); 
        dispatch(setSection('dashboard'));
        dispatch(setDashboardSection('consultation')); 

      } else { 

        dispatch(setDashboardSection('documents')); 

      }; 

    } else { 

      navigate('/dashboard'); 

      window.location.reload(); 

    }; 

  }; 

  // -- 

  useEffect(() => {
    
    dispatch(setSideBarMenu(menuItems));

  }, []);

  // -- 

  useEffect(() => { 

    if (currentSection) {

      dispatch(setDashboardSection(currentSection)); 

    }; 

  },[currentSection]); 

  // -- 

  useEffect(() => { 

    if (consultationId) { 

      handleConsultation(); 

    }; 

  },[consultationId]); 

  // -- 

  return (
    <React.Fragment>
      <CssBaseline />
      <Box
        id='mainContainer'
        component="main"
        sx={{
          display: 'flex',
          height: '100vh',
          width: '100vw',
          left: 0,
          zIndex: 0,
          overflowX: 'hidden',
          position: 'absolute',
          color: colors?.text,
        }}
      >
        <NavBar>
          <Settings />
        </NavBar>

        <Sidebar />

        {/* SideBar sections */}

        {section === 'documents' && <FileExplorer />}
        {section === 'patients' && <PatientsInterface />}
        {section === 'insurers' && <InsurersInterface />}
        {section === 'templates' && <Templates />}
        {section === 'consultation' && <Consultation />}

        {isImageViewerOpened && <ImageViewer />}  
        {isFileViewerOpened && <FileViewer />}
        {isNoteViewerOpened && <NoteViewer />}

      </Box>
      
    </React.Fragment>
  );
};

export default Container;