import React, { useState, useEffect, useRef } from 'react'; 
import { useAppDispatch, useAppSelector } from '../../../../../redux/hooks';
import Box from '@mui/material/Box';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useNavigate } from 'react-router-dom';

// Components 

import { useTranslation } from 'react-i18next';
import { serveFile } from '../../../../../routes/doctor/files';
import Header from '../items/Header';
import CircularProgress from '@mui/material/CircularProgress';


const ImageViewer: React.FC = () => {

    const dispatch = useAppDispatch(); 
    const navigate = useNavigate(); 

    const { t } = useTranslation(); 

    const isSmallScreen = useMediaQuery('(max-width: 1300px)');
    const leftOffset = isSmallScreen ? '60px' : '5vw';

    const iframeRef = useRef<HTMLIFrameElement | null>(null);

    const colors = useAppSelector(state => state.theme.colors);

    const selectedImage = useAppSelector(state => state.imageViewer.selectedImage) as any; 

    const [fileUrl, setFileUrl] = useState(''); 

    // -- 

    const handleImage = async () => { 

        const res = await serveFile(selectedImage._id, 'image') as any; 
        
        const fileBlob = new Blob([res.data], { type: res.headers['content-type'] });
    
        setFileUrl(URL.createObjectURL(fileBlob)); 

    }; 

    // -- 

    useEffect(() => { 

        if (selectedImage) { 

            handleImage(); 

        }; 

    },[selectedImage]); 

    // --

    return (

        <Box 
            style={{ 
                display: 'flex', 
                flexDirection: 'row',
                alignItems: 'center', 
                justifyContent: 'space-evenly',
                paddingTop: 50, 
                width: '100vw',
                height: '100vh', 
                backgroundColor: 'black', 
                overflow: 'hidden', 
                position: 'absolute', 
                zIndex: 1000000000
        }}>

            <Header title={selectedImage?.title} />

            {fileUrl ?
                <Box
                    id='image-viewer'
                    component="img"
                    sx={{
                        height: '92vh',
                        maxWidth: 'auto',
                    }}
                    alt={selectedImage?.title}
                    src={fileUrl}
                />: 
                <CircularProgress size={30} />}

        </Box>
  );
};

export default ImageViewer;