import React, { useState, useEffect, useRef } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../../redux/hooks';
import { useTranslation } from 'react-i18next';

// Components 

import Box from '@mui/material/Box';

interface Props { 
    children?: React.ReactNode; 
    title: string; 
    description: string; 
}; 

const ShortcutRow: React.FC<Props> = ({ children, title, description }) => {

    const dispatch = useAppDispatch(); 
    const { t } = useTranslation(); 

    const colors = useAppSelector(state => state.theme.colors); 
    const sidebarSection = useAppSelector(state => state.consultationNote.sidebarSection); 

    return (

      <Box sx={{ 
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center', 
          justifyContent: 'space-between', 
          width: '90%',
          height: 40, 
          fontSize: 12, 
          marginTop: 1, 
      }}>

        <Box 
            sx={{ 
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center', 
            justifyContent: 'space-evenly', 
            height: '100%', 
        }}>{t(title)}

        </Box>

        <Box 
            sx={{ 
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',             
            borderStyle: 'solid', 
            borderWidth: 1,
            borderColor: 'grey',
            padding: 0.5,
            borderRadius: 1, 
            boxShadow: '0 4px 6px rgba(0, 0, 0, 0.2)',
        }}>{description}

        </Box>

      </Box>

    );

};

export default ShortcutRow;