import React, { useState, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../../redux/hooks';
import { useNavigate } from 'react-router-dom';
import { QRCodeCanvas } from 'qrcode.react';

// Redux 

import { useTranslation } from 'react-i18next';
import { setClosePopup } from '../../../../../redux/features/general/popup';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

type Props = {
    children?: React.ReactNode,
    isPortraitMode?: boolean,
};

const CameraPopup: React.FC<Props> = ({ children, isPortraitMode }) => {

    const dispatch = useAppDispatch();
    const navigate = useNavigate(); 
    const { t } = useTranslation();

    const colors = useAppSelector(state => state.theme.colors);

    const consultationId = useAppSelector(state => state.consultation.consultationId); 

    // -- 

    const goBackToConsultation = () => { 

        dispatch(setClosePopup()); 

    }; 

    // -- 

    const [url, setUrl] = useState(''); 

    // -- 

    useEffect(() =>  {

        if (consultationId) {
          
          setUrl(`https://bridge.medca.ai/login/mobile/consultation/${consultationId}/camera/007`); 

        }; 

    },[consultationId]); 



    return (

        <Box sx={{ 
            display: 'flex', 
            flexDirection: 'column',
            alignItems: 'center', 
            justifyContent: 'space-evenly',
            width: '100%',
            height: '100%',
            borderRadius: 5,
            backgroundColor: colors?.container,
            overflowY: 'auto',
            overflowX: 'hidden',
            textAlign: 'center', 
        }}>


            <QRCodeCanvas value={url} size={window.innerWidth * 0.125} />

            <Box 
                sx={{ 
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center', 
                    marginTop: 1, 
                    width: '80%', 
                    textAlign: 'center', 
                    color: colors?.text
            }}>{t('useCodeForCamera')}</Box>

            <Box
                sx={{ 
                    display: 'flex', 
                    flexDirection: 'column', 
                    alignItems: 'center',
                    width: '100%'
            }}>

            <Button 
            sx={{ 
                width: 200,
                backgroundColor: colors?.cancelButtonColor, 
                borderColor: colors?.text,
                color: colors?.text,
                '&:hover': 
                { 
                    backgroundColor: colors?.cancelButtonBackground,
                    color: colors?.text,
                    borderColor: colors?.cancelButtonBackground
                }
            }}
            onClick={() => goBackToConsultation()}
            variant="outlined">{t('back')}</Button>
            </Box>

    </Box>
  );
};

export default CameraPopup;