import React, { useState, useEffect, useRef } from 'react';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { useTranslation } from 'react-i18next';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';

// Components 

import Box from '@mui/material/Box';
import { getConsultationNote } from '../../routes/doctor/consultation';
import { setConsultationNoteId } from '../../redux/features/doctor/Dashboard/consultation/noteMicrophone';
import { TextField, Button } from '@mui/material';
import { accessConsultation, requestAccessToConsultation } from '../../routes/general/authentication';
import { any } from 'prop-types';
import { setAccountInfo, setAccountType } from '../../redux/features/general/meta';

// -- 

const Container: React.FC = () => {

    const dispatch = useAppDispatch(); 
    const { t } = useTranslation(); 
    const navigate = useNavigate(); 

    const { consultationId, action, generalId } = useParams() as any; 

    const colors = useAppSelector(state => state.theme.colors); 
    const accountInfo = useAppSelector(state => state.meta.accountInfo); 

    const [pin, setPin] = useState(''); 
    const [error, setError] = useState(''); 

    // -- 

    const handleAccessRequest = async () => { 

        await requestAccessToConsultation(consultationId) as any; 

    }; 

    // -- 

    const handleConsultationAccess = async () => { 

        const res = await accessConsultation(consultationId, pin);

        if (res?.data.status === 200) { 

            if (action === 'note') { 

                navigate(`/consultation/note/mic/${generalId}/${res.data.securityPin}`); 

            } else if (action === 'camera') { 

                navigate(`/camera/${consultationId}/${res.data.securityPin}`); 

            }; 

        }; 

    }; 

    // -- 

    useEffect(() => { 

        if (consultationId) { 

            handleAccessRequest(); 

        }; 

    },[consultationId]); 

    // -- 

    const handleText = async (e: any) => { 

        // const reg = new RegExp('^[0-9\-/]+$'); 
        setPin(e.target.value); 

    }; 

    // -- 

    const handleKey = async (e: any) => { 

        if (e.key === 'Enter') { 

            if (pin.length === 6) { 

                handleConsultationAccess(); 

            }; 

        }; 

    }; 

    // -- 

    useEffect(() => { 

        if (pin) { 

            if (pin.length === 6) { 

                handleConsultationAccess(); 

            }; 

        }; 

    },[pin]); 

    // -- 

    return (

      <Box
        sx={{ 
            display: 'flex', 
            flexDirection: 'column', 
            alignItems: 'center', 
            justifyContent: 'space-evenly', 
            width: '100vw', 
            height: '100vh', 
            bottom: 0,
            position: 'absolute', 
        }}
      >

            <Box
                component="img"
                sx={{
                    height: `${20}px`,
                    width: 'auto',
                    position: 'absolute',
                    top: '3vh',
                }}
                alt="Solutions Medca Inc."
                src={require('../../assets/logos/medca_logo_blue_dark_blue.png')}
            />

            <Box
                sx={{ 
                    width: '60%', 
                    top: '20vh', 
                    colors: colors?.text, 
                    position: 'absolute', 
                    textAlign: 'center'
                }}
            >{t('insertPin')}
            </Box>

            <TextField 
                sx={{ 
                    width: 200, 
                    top: '40vh', 
                    fontSize: 18, 
                    alignSelf: 'center',
                    position: 'absolute', 
                    color: colors?.text,
                    borderBottomColor: 'black',
                    borderColor: 'rgb(10,197,207)',
                    borderStyle: 'solid',
                    borderRadius: 1, 
                    borderWidth: 1, 
                }} 
                inputProps={{ 
                    style: { 
                        textAlign: 'center',
                        color: colors?.text,
                    }
                }}
                InputLabelProps={{
                    style: {
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        width: '100%',
                        color: colors?.text,
                    } 
                }} 
                onChange={handleText}
                id="mobile-login-text" 
                placeholder={String(t('securityPin'))}
                autoComplete='off'
                value={pin}
                error={error ? true : false}
                helperText={error}
                autoFocus
                onKeyDown={handleKey}
            />

      </Box>

  );

};

export default Container;