import React, { useState, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../../../../redux/hooks';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

// Redux 

import { setNotificationMessage } from '../../../../../../../redux/features/general/notification';

// Routes

import { removeMetaPatient } from '../../../../../../../routes/doctor/patients';

// Components 

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { resetPatient, setRefreshPatientsList } from '../../../../../../../redux/features/doctor/Dashboard/patients/patient';
import { setOpenPopup, setPopupAction } from '../../../../../../../redux/features/general/popup';



const Settings: React.FC = () => {

    const { t } = useTranslation(); 
    const dispatch = useAppDispatch(); 

    const colors = useAppSelector(state => state.theme.colors);
    const patient = useAppSelector(state => state.patient.patient);

    const openWarningPopup = async () => { 

        dispatch(setOpenPopup()); 
        dispatch(setPopupAction('removePatient')); 

    }; 

    // -- 

    return (

    <Box
        sx={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            maxHeight: '90vh',
            overflow: 'auto',
            alignItems: 'center',
            scrollbarWidth: 'none',
        }}
    >

        <Box
            sx={{ 
                display: 'flex', 
                flexDirection: 'row', 
                alignItems: 'center', 
                width: '100%', 
                paddingLeft: 10,
                marginTop: 10
            }}
        >
            <Box component="img" sx={{ width: 20 }} src={require('../../../../../../../assets/icons/patient/name.png')} />
            <Box sx={{ marginLeft: 1 }}>{`${patient.firstName} ${patient.lastName}`}</Box>
        </Box>

        <Box
            sx={{ 
                display: 'flex', 
                flexDirection: 'row', 
                alignItems: 'center', 
                width: '100%', 
                paddingLeft: 10,
                marginTop: 5,
                fontSize: 15, 
                color: colors?.text
            }}
        >
            {t('metaPatientDeletionWarning')}
        </Box>

        <Box
            sx={{ 
                display: 'flex', 
                flexDirection: 'row', 
                alignItems: 'center', 
                width: '100%', 
                paddingLeft: 10,
                marginTop: 5,
                fontSize: 15, 
                color: colors?.text
            }}
        >

            <Button 
                sx={{ 
                    width: 200,
                    backgroundColor: colors?.exitButtonColor,
                    borderColor: colors?.exitButtonColor,
                    color: 'white',
                    marginBottom: 2,
                    '&:hover': 
                    { 
                        backgroundColor: colors?.exitButtonColorHover,
                        color: colors?.exitButtonText,
                        borderColor: colors?.exitButtonColorHover
                     }
                }}
                onClick={openWarningPopup}
                variant="outlined">{t('removePatient')}
            </Button>

        </Box>



    </Box>

  );
};

export default Settings;