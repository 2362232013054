import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import { useAppDispatch, useAppSelector } from '../../../../../redux/hooks';
import { useTranslation } from 'react-i18next';

// Components
import { default as MuiList } from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import PatientRow from './PatientRow';
import { getMetaPatientsList } from '../../../../../routes/doctor/patients';
import { setInitialRendering, setPatientId, setPatientName, setPatientsList } from '../../../../../redux/features/doctor/Dashboard/consultation/consultation';

const List: React.FC = () => {

    const { t } = useTranslation();
    const dispatch = useAppDispatch(); 

    const colors = useAppSelector(state => state.theme.colors);
    const patientId = useAppSelector(state => state.consultation.patientId);  
    const patientsList = useAppSelector(state => state.consultation.patientsList); 
    const initialRendering = useAppSelector(state => state.consultation.initialRendering); 
    const selectPatientSearchQuery = useAppSelector(state => state.consultation.selectPatientSearchQuery); 

    const [patients, setPatients] = useState([]) as any; 

    // -- 

    const handlePatientsList = async () => { 

        const res = await getMetaPatientsList() as any; 

        if (res.data.status === 200) { 

            dispatch(setPatientsList(res.data.metaPatientsList)); 
            dispatch(setPatients(res.data.metaPatientsList)); 

        }; 

    }; 

    // -- 

    const selectMetaPatient = (metaPatient: any) => { 

        dispatch(setPatientId(metaPatient.patientId)); 
        dispatch(setPatientName(metaPatient.fullName)); 

    }; 

    // -- 

    useEffect(() => { 

        if (initialRendering) { 

            handlePatientsList(); 

            dispatch(setInitialRendering(false)); 

        }; 

    },[initialRendering]); 

    // -- 

    useEffect(() => { 

        if (selectPatientSearchQuery) {

            const updatedPatientsList = patientsList.filter((patient) => { 
                        
                const lowercasedName = patient.fullName.toLocaleLowerCase(); 
                const lowercasedQuery = selectPatientSearchQuery.toLocaleLowerCase(); 
                
                if ((lowercasedName.includes(lowercasedQuery)) || (patient.patientId.includes(lowercasedQuery)) || (patient.updatedAt.includes(lowercasedQuery))) { 

                    return patient; 

                }; 

            }); 

            setPatients(updatedPatientsList); 

        } else { 

            setPatients(patientsList); 

        }; 


    },[selectPatientSearchQuery]); 
    
    // --

    return (

        <Box 
            sx={{ 
            display: 'flex', 
            flexDirection: 'column',
            alignItems: 'center', 
            width: '100%',
            height: '75%', 
        }}>

        { patientsList?.length >= 1 && 

            <Box 
                
                sx={{ 
                    overflow: 'auto',
                    width: '100%',
                    height: '100%',
                    marginTop: 1, 
                    scrollbarWidth: 'none',
                }}>
    
                <MuiList dense disablePadding>
        
                {patients?.map((item, index) => (
        
                    <ListItem 
                        onClick={(e) => selectMetaPatient(item)} 
                        button 
                        key={index} 
                        disablePadding>

                        <PatientRow 
                            id={item.patientId}
                            fullName={item.fullName}
                            updatedAt={item.updatedAt}
                        /> 
                    </ListItem>

                ))}
        
                </MuiList>

        
            </Box>}

            {(patients?.length === 0) &&   
          
                <Box
                    sx={{ 
                        display: 'flex', 
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'space-around',
                        width: '100%',
                        height: '100%',
                    }}>
                
                    <Box
                        sx={{
                            width: 252,
                            height: 214, 
                            marginTop: '5%',
                        }}
                        component="img"
                        alt="Solutions Medca Inc."
                        src={require('../../../../../assets/logos/empty_list.png')}
                    />

                    <Box sx={{ 
                        color: colors?.text,
                        fontSize: 12,
                        marginBottom: '20%',
                    }}>{t('addPatients')}</Box>

                </Box>}

          </Box>

    );
};

export default List;