import darkTheme from '../../../styles/themes/darkTheme';
import lightTheme from '../../../styles/themes/lightTheme';

const savedTheme = localStorage.getItem('theme');

const currentTheme = (savedTheme === 'dark') ? darkTheme : lightTheme as any; 

const menuItems = (t) => [
    { type: 'item', section: 'createTicket', text: 'help', iconName: 'help', iconColor: currentTheme?.sidebarIconColor, key:'i-createTicket' },
    { type: 'item', section: 'history', text: 'history', iconName: 'history', iconColor: currentTheme?.sidebarIconColor, key:'i-history'},
];

export default menuItems; 