import React, { useEffect } from 'react';
import Box from '@mui/material/Box';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import { setCategory } from '../../../../redux/features/support/supportEmail';
import TextField from '@mui/material/TextField';
import { useTranslation } from 'react-i18next';

const Category: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch(); 
  
  const colors = useAppSelector(state => state.theme.colors);
  const category = useAppSelector(state => state.supportEmail.category);

  // Dispatch the description change directly to Redux
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setCategory(e.target.value));
  };

  return (
    <Box sx={{ 
      marginTop: '3vh',
      marginBottom: '3vh',
    }}>
      <TextField
        sx={{
          width: '50%',
          backgroundColor: colors?.supportEmailBackground,
          color: colors?.text,
          boxShadow: '0 4px 6px rgba(0, 0, 0, 0.2)',
        }}
        InputProps={{ 
          style: { 
            color: colors?.text
          }
        }}
        onChange={handleChange}
        id="outlined-multiline-static"
        label={t('category')}
        placeholder={String(t('category'))}
        autoFocus
        multiline
        value={category}
        rows={1}
      />
    </Box>
  );
};

export default Category;