import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { useAppDispatch, useAppSelector } from '../../../../../../redux/hooks';
import { getDoctorName, getMetaPatientInfo } from '../../../../../../routes/doctor/patients';
import moment from 'moment';

// Components 

import PersonIcon from '@mui/icons-material/Person';
import { getConsultation } from '../../../../../../routes/doctor/consultation';
import { useTranslation } from 'react-i18next';
import { setMode } from '../../../../../../redux/features/doctor/Dashboard/documents/fileExplorer';
import { resetSavedConsultation, setConsultationId, setSection } from '../../../../../../redux/features/doctor/Dashboard/consultation/savedConsultation';

const Directory: React.FC = () => {

    const dispatch = useAppDispatch(); 
    const { t } = useTranslation(); 

    const colors = useAppSelector(state => state.theme.colors); 

    const mode = useAppSelector(state => state.fileExplorer.mode); 

    const consultationId = useAppSelector(state => state.savedConsultation.consultationId); 
    const patientId = useAppSelector(state => state.savedConsultation.patientId); 
    const accountInfo = useAppSelector(state => state.meta.accountInfo); 
    const section = useAppSelector(state => state.savedConsultation.section); 
    const selectedConsultation = useAppSelector(state => state.savedConsultation.selectedConsultation); 

    const [directory, setDirectory] = useState([]); 
    const [patientFullName, setPatientFullName] = useState(''); 
    const [pathName, setPathName] = useState([]) as any; 

    // -- 

    const handlePath = async (depth: number) => { 

        if (depth === 1) { 

            const patientFullName = await getPatientFullName();

            setPathName([patientFullName]); 

            setPatientFullName(patientFullName); 

        } else if (depth === 2) { 

            const patientName = patientFullName ? patientFullName : await getPatientFullName();

            const consultationTitle = await getConsultationTitle(); 

            setPathName([patientName, consultationTitle, String(t('categories'))]); 

        } else if (depth === 3) { 

            const patientName = patientFullName ? patientFullName : await getPatientFullName();

            const consultationTitle = await getConsultationTitle(); 

            setPathName([patientName, consultationTitle, String(t('categories')), String(t(section))]); 

        }; 

    }; 

    // -- 

    const getPatientFullName = async () => { 

        const res = await getMetaPatientInfo(patientId, accountInfo?._id) as any; 

        if (res?.data.status === 200) { 

            return res.data.metaPatient.fullName; 

        }; 

    }; 

    // -- 

    const getConsultationTitle = async () => { 

        const res = await getDoctorName(selectedConsultation.doctorId); 

        if (res?.data.status === 200) { 

            return `${moment.utc(selectedConsultation.createdAt).format('L')}_${res.data.fullName}`; 

        }; 

    }; 

    // -- 

    const changeDirectory = async (section: any, index: any) => { 

        if (index === 0) { 

            dispatch(resetSavedConsultation()); 
            dispatch(setMode('')); 

        } else if (index === 1) { 

            dispatch(setConsultationId('')); 
            dispatch(setSection('consultations')); 

            handlePath(1); 

        } else if (index === 2) { 

            dispatch(setSection('categories')); 

            handlePath(2); 

        }; 

    }; 

    // -- 

    useEffect(() => { 

        if (mode === 'consultations') { 

            if (consultationId) { 

                if (section && (section !== 'categories')) { 
                    
                    handlePath(3); 

                } else { 

                    handlePath(2); 

                }; 

            } else { 

                handlePath(1); 

            }; 

        }; 

    },[mode, consultationId, section]); 

    // -- 

    return (

        <Box style={{ 
            display: 'flex', 
            flexDirection: 'row',
            alignItems: 'center', 
            width: '75%', 
            overflowX: 'scroll',
            scrollbarWidth: 'none', 
            marginLeft: 10, 
            fontSize: 15,
        }}>

        {pathName?.map((item, index) => (

             <Box 
                key={index}
                sx={{ 
                display: 'flex', 
                flexDirection: 'row',
                alignItems: 'center',
            }}>
        
                <Box 
                    onClick={() => changeDirectory(item, index)}
                    sx={{ 
                        marginLeft: 1,
                        cursor: index === directory?.length - 1 ? 'default': 'pointer', 
                        '&:hover': { color: index === directory?.length - 1 ? colors?.highlightedText : colors?.directory }
                    }}
                >{item}</Box>
                <Box sx={{ marginLeft: 1 }}>{'>'}</Box>

            </Box>

        ))}
            
        </Box>
    );

};

export default Directory;