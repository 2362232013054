import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import attributesList from '../../../../../components/consultationNote/dropdown/attributes/attributesList';

interface ConsultationNoteState {
    sidebarSection: string; 
    consultationId: string; 
    consultationNoteId: string; 
    type: string; 
    isSavingEnabled: boolean; 
    fileUrl: string; 
    mode: string; 
    title: string; 
    selectionStart: number; 
    originalTitle: string; 
    titleError: string; 
    action: string; 
    securityPin: string; 
    noteFields: any[]; 
    isTextModified: boolean; 
    isSecurityPopupOpened: boolean;
    attributesQueryList: any[]; 
    selectedAttribute: string; 
    searchQuery: string; 
    manualAttributeSearchQuery: string; 
    fieldSearchQuery: string; 
    isAttributesDropdownOpened: boolean; 
    hightlightedAttribute: string; 
    isRefreshFieldsList: boolean; 
    newNoteFieldTitle: string; 
    newNoteFieldSelectionStart: number; 
    newAttributeSelectionStart: number; 
    isExistingNoteFieldTitle: boolean; 
    isSidebarOpened: boolean; 
    isCurrentlySaving: boolean; 

}; 

const initialState: ConsultationNoteState = {
    sidebarSection: 'code',
    consultationId: '', 
    consultationNoteId: '',
    type: '', 
    isSavingEnabled: false, 
    fileUrl: '', 
    mode: '',
    title: '', 
    selectionStart: 0, 
    originalTitle: '', 
    titleError: '', 
    action: '', 
    securityPin: '', 
    noteFields: [], 
    isTextModified: false, 
    isSecurityPopupOpened: false, 
    attributesQueryList: attributesList,
    selectedAttribute: '', 
    searchQuery: '', 
    manualAttributeSearchQuery: '', 
    fieldSearchQuery: '', 
    isAttributesDropdownOpened: false, 
    hightlightedAttribute: attributesList[0], 
    isRefreshFieldsList: false, 
    newNoteFieldTitle: '', 
    newNoteFieldSelectionStart: 0, 
    newAttributeSelectionStart: 0, 
    isExistingNoteFieldTitle: false, 
    isSidebarOpened: true,
    isCurrentlySaving: false, 
};

const consultationNoteSlice = createSlice({
  name: 'consultationNote',
  initialState,
  reducers: {
    setSidebarSection: (state, action: PayloadAction<string>) => {
      state.sidebarSection = action.payload;
    },
    setConsultationId: (state, action: PayloadAction<string>) => {
      state.consultationId = action.payload;
    },
    setConsultationNoteId: (state, action: PayloadAction<string>) => {
      state.consultationNoteId = action.payload;
    },
    setType: (state, action: PayloadAction<string>) => {
      
      state.type = action.payload;

      if ((action.payload === 'template') && (state.sidebarSection === 'code')) { 

        state.sidebarSection = 'fields'; 

      }; 

    },
    setIsSavingEnabled: (state, action: PayloadAction<boolean>) => {
      state.isSavingEnabled = action.payload;
    },
    setFileUrl: (state, action: PayloadAction<string>) => {
      state.fileUrl = action.payload;
    },
    setMode: (state, action: PayloadAction<string>) => {
      state.mode = action.payload;
    },
    setTitle: (state, action: PayloadAction<string>) => {
      state.title = action.payload;
    },
    setSelectionStart: (state, action: PayloadAction<number>) => {
      state.selectionStart = action.payload;
    },
    setOriginalTitle: (state, action: PayloadAction<string>) => {
      state.originalTitle = action.payload;
    },
    setTitleError: (state, action: PayloadAction<string>) => {
      state.titleError = action.payload;
      state.isSavingEnabled = action.payload ? false : state.isSavingEnabled; 
    },
    setAction: (state, action: PayloadAction<string>) => {
      state.action = action.payload;
    },
    setSecurityPin: (state, action: PayloadAction<string>) => {
      state.securityPin = action.payload;
    },
    setNoteFields: (state, action: PayloadAction<any>) => {
      state.noteFields = action.payload;
    },
    setIsTextModified: (state, action: PayloadAction<boolean>) => {
      state.isTextModified = action.payload;
    },
    setIsSecurityPopupOpened: (state, action: PayloadAction<boolean>) => {
      state.isSecurityPopupOpened = action.payload;
    },
    setAttributesQueryList: (state, action: PayloadAction<any>) => {
      state.attributesQueryList = action.payload;
    },
    setSelectedAttribute: (state, action: PayloadAction<string>) => {
      state.selectedAttribute = action.payload;
    },
    setSearchQuery: (state, action: PayloadAction<string>) => {
      state.searchQuery = action.payload;
    },
    setManualAttributeSearchQuery: (state, action: PayloadAction<string>) => {
      state.manualAttributeSearchQuery = action.payload;
    },
    setFieldSearchQuery: (state, action: PayloadAction<string>) => {
      state.fieldSearchQuery = action.payload;
    },
    setIsAttributesDropdownOpened: (state, action: PayloadAction<boolean>) => {
      state.isAttributesDropdownOpened = action.payload;
    },
    setHightlightedAttribute: (state, action: PayloadAction<string>) => {
      state.hightlightedAttribute = action.payload;
    },
    setIsRefreshFieldsList: (state, action: PayloadAction<boolean>) => {
      state.isRefreshFieldsList = action.payload;
    }, 
    setNewNoteFieldTitle: (state, action: PayloadAction<string>) => {
      state.newNoteFieldTitle = action.payload;
    },
    setNewNoteFieldSelectionStart: (state, action: PayloadAction<number>) => {
      state.newNoteFieldSelectionStart = action.payload;
    }, 
    setNewAttributeSelectionStart: (state, action: PayloadAction<number>) => {
      state.newAttributeSelectionStart = action.payload;
    }, 
    setIsExistingNoteFieldTitle: (state, action: PayloadAction<boolean>) => {
      state.isExistingNoteFieldTitle = action.payload;
    },
    setIsSidebarOpened: (state, action: PayloadAction<boolean>) => {
      state.isSidebarOpened = action.payload;
    }, 
    setIsCurrentlySaving: (state, action: PayloadAction<boolean>) => {
      state.isCurrentlySaving = action.payload;
    }, 
    resetConsultationNote: (state) => { 
        
      state.sidebarSection = 'code'; 
      state.consultationId = ''; 
      state.consultationNoteId = '';
      state.type = ''; 
      state.isSavingEnabled = false; 
      state.fileUrl; 
      state.mode = ''; 
      state.title = '';
      state.selectionStart = 0; 
      state.originalTitle = ''; 
      state.titleError = ''; 
      state.action = ''; 
      state.securityPin = ''; 
      state.noteFields = []; 
      state.isTextModified = false; 
      state.isSecurityPopupOpened = false; 
      state.attributesQueryList = attributesList; 
      state.selectedAttribute = ''; 
      state.searchQuery = ''; 
      state.manualAttributeSearchQuery = ''; 
      state.fieldSearchQuery = ''; 
      state.isAttributesDropdownOpened = false; 
      state.hightlightedAttribute = attributesList[0]; 
      state.isRefreshFieldsList = false; 
      state.newNoteFieldSelectionStart = 0; 
      state.newAttributeSelectionStart = 0; 
      state.newNoteFieldTitle = ''; 
      state.isExistingNoteFieldTitle = false; 
      state.isSidebarOpened = true; 
      state.isCurrentlySaving = false; 

    } 
  },
});

export const { 
  setSidebarSection, 
  setConsultationId, 
  setConsultationNoteId, 
  setType, 
  setIsSavingEnabled, 
  setFileUrl,
  setMode, 
  setTitle, 
  setSelectionStart, 
  setOriginalTitle, 
  setTitleError, 
  setAction, 
  setSecurityPin, 
  setNoteFields, 
  setIsTextModified, 
  setAttributesQueryList, 
  setIsSecurityPopupOpened, 
  setSearchQuery, 
  setManualAttributeSearchQuery, 
  setFieldSearchQuery, 
  setSelectedAttribute,
  setIsAttributesDropdownOpened, 
  setHightlightedAttribute, 
  setIsRefreshFieldsList, 
  setNewNoteFieldSelectionStart, 
  setNewAttributeSelectionStart, 
  setNewNoteFieldTitle, 
  setIsExistingNoteFieldTitle, 
  setIsSidebarOpened, 
  setIsCurrentlySaving, 
  resetConsultationNote } = consultationNoteSlice.actions;

export default consultationNoteSlice.reducer;