const iconStyle = { 
    display: 'flex', 
    flexDirection: 'row', 
    alignItems: 'center', 
    justifyContent: 'space-evenly', 
    width: 20, 
    height: 20, 
    marginLeft: 2, 
}; 

export default iconStyle; 