import React, { useState, useEffect, useRef } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

// Components 

import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import DescriptionIcon from '@mui/icons-material/Description';
import { setIsSavingEnabled, setTitle, setTitleError } from '../../../../redux/features/doctor/Dashboard/consultation/consultationNote';

const Title: React.FC = () => {

    const dispatch = useAppDispatch(); 
    const { t } = useTranslation(); 
    const navigate = useNavigate(); 

    const colors = useAppSelector(state => state.theme.colors); 
    const originalTitle = useAppSelector(state => state.consultationNote.originalTitle); 
    const titleError = useAppSelector(state => state.consultationNote.titleError); 

    const [text, setText] = useState(''); 

    // -- 

    const onTextchange = (e: any) => { 

        setText(e.target.value); 

        dispatch(setTitle(e.target.value)); 

        const titleRestrictions = new RegExp('^[\\w\\s.()-]{1,255}$');  

        if (!titleRestrictions.test(e.target.value)) { 

            dispatch(setTitleError(String(t('invalidTitle')))); 

        } else { 

            dispatch(setTitleError('')); 

        }; 
    
    }; 

    // -- 

    useEffect(() => { 

        if (originalTitle) { 

            setText(originalTitle); 

        }; 

    },[originalTitle]); 

    // -- 

    return (

        <Box sx={{ 
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center', 
            marginLeft: 1, 
            width: 'auto',
            height: '100%', 
        }}>

            <DescriptionIcon sx={{ color: colors?.lightBlue, marginRight: 2 }} /> 

            <TextField 
                sx={{ width: 200, textAlign: 'left' }} 
                onChange={onTextchange}
                InputProps={{ 
                    style: { 
                        color: colors?.text,
                        fontSize: 12, 
                    }
                }}
                id="standard-basic" 
                value={text}
                autoComplete='off'
                placeholder={'Untitled'}
                variant="standard" 
            />

            <Box
                sx={{ 
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center', 
                    marginLeft: 2, 
                    width: 'auto',
                    height: '100%',
                    color: colors?.red,
                    fontSize: 12 
                }}
            >{titleError}
            </Box>

        </Box>

  );

};

export default Title;