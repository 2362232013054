import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import { useAppDispatch, useAppSelector } from '../../../../../../../redux/hooks';
import { useTranslation } from 'react-i18next';

// Components
import { default as MuiList } from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
// import NoteRow from './NoteRow';
import { getAttachedFiles } from '../../../../../../../routes/doctor/consultation';
import { setAttachedFiles, setNotes, setSection, setSelectedAttachedFile, setSelectedConsultationNote } from '../../../../../../../redux/features/doctor/Dashboard/consultation/consultation';
import FileRow from './FileRow';
import { setIsFileViewerOpened, setSelectedFile } from '../../../../../../../redux/features/doctor/Dashboard/documents/fileViewer';



const List: React.FC = () => {

    const { t } = useTranslation();
    const dispatch = useAppDispatch(); 

    const colors = useAppSelector(state => state.theme.colors);
    const consultationId = useAppSelector(state => state.consultation.consultationId); 
    const patientId = useAppSelector(state => state.consultation.patientId);  
    const attachedFiles = useAppSelector(state => state.consultation.attachedFiles); 

    // -- 

    const handleAttachedFiles = async () => { 

        const res = await getAttachedFiles(consultationId) as any; 

        if (res.data.status === 200) { 

            dispatch(setAttachedFiles(res.data.attachedFiles)); 

        }; 

    }; 

    // -- 

    const handleSelection = async (e: any, item: any) => { 

        if (e.detail === 2) { 

            dispatch(setSelectedFile(item)); 
            dispatch(setIsFileViewerOpened(true)); 

            // dispatch(setSelectedAttachedFile(item)); 

            // dispatch(setSection('fileViewer')); 

        } else if (e.detail === 1) { 

            dispatch(setSelectedAttachedFile(item)); 

        }; 

    }; 

    // -- 

    useEffect(() => { 

        handleAttachedFiles(); 

    },[]);

    // --

    return (

        <Box 
            sx={{ 
            display: 'flex', 
            flexDirection: 'column',
            alignItems: 'center', 
            width: '100%',
            minHeight: 700, 
            overflow: 'scroll',
            scrollbarWidth: 'none',
        }}>

            { attachedFiles?.length >= 1 && 

            <Box 
                
                sx={{ 
                    width: '100%',
                    height: '100%',
                    marginTop: 1, 
                }}>
    
                <MuiList dense disablePadding>
        
                {attachedFiles?.map((item, index) => (
        
                    <ListItem 
                        onClick={(e) => handleSelection(e, item)} 
                        button 
                        key={index} 
                        disablePadding>

                        <FileRow 
                            title={item.title}
                            updatedAt={item.updatedAt}
                        /> 

                    </ListItem>

                ))}
        
                </MuiList>

        
            </Box>}

            {(attachedFiles?.length === 0) &&   
          
            <Box
              sx={{ 
                  display: 'flex', 
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'space-around',
                  width: '100%',
                  height: '100%',
              }}>
          
              <Box
                  sx={{
                      width: 252,
                      height: 214, 
                      marginTop: '5%',
                  }}
                  component="img"
                  alt="Solutions Medca Inc."
                  src={require('../../../../../../../assets/logos/empty_list.png')}
              />

              <Box sx={{ 
                  color: colors?.text,
                  fontSize: 12,
                  marginBottom: '20%',
              }}>{t('emptyFolder')}</Box>

            </Box>}
      

          </Box>

    );
};

export default List;