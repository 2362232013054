import React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
// Redux 

import { useTranslation } from 'react-i18next';
import { setClosePopup } from '../../../redux/features/general/popup';
import { useNavigate } from 'react-router-dom';

type Props = {
    children?: React.ReactNode,
    isPortraitMode?: boolean,
};

const AccessPopup: React.FC<Props> = ({ children, isPortraitMode }) => {

    const dispatch = useAppDispatch();
    const navigate = useNavigate(); 
    const { t } = useTranslation();

    const colors = useAppSelector(state => state.theme.colors);
    const securityPin = useAppSelector(state => state.consultationNote.securityPin); 

    // -- 

    const goBackToConsultation = () => { 

        dispatch(setClosePopup()); 

    }; 


  return (
    <Box sx={{ 
        display: 'flex', 
        flexDirection: 'column',
        alignItems: 'center', 
        justifyContent: 'space-evenly',
        width: '100%',
        height: '100%',
        borderRadius: 5,
        backgroundColor: colors?.container,
        overflowY: 'auto',
        overflowX: 'hidden',
        textAlign: 'center', 
    }}>

        <Box
            sx={{ 
                width: '75%',
                textAlign: 'center', 
                display: 'flex', 
                flexDirection: 'column',
                alignItems: 'center', 
                justifyContent: 'space-evenly',
            }}
        >{t('consultationNoteMobileAccess')}</Box>

        <Box
            sx={{ 
                fontWeight: 'bold', 
                fontSize: 20, 
                color: colors?.text
            }}
        >{securityPin}

        </Box>

        <Box
            sx={{ 
                display: 'flex', 
                flexDirection: 'column', 
                alignItems: 'center',
                width: '100%'
        }}>

        <Button 
          sx={{ 
            width: 200,
            backgroundColor: colors?.cancelButtonColor, 
            borderColor: colors?.text,
            color: colors?.text,
            '&:hover': 
            { 
                backgroundColor: colors?.cancelButtonBackground,
                color: colors?.text,
                borderColor: colors?.cancelButtonBackground
             }
          }}
          onClick={() => goBackToConsultation()}
          variant="outlined">{t('back')}</Button>
        </Box>

    </Box>
  );
};

export default AccessPopup;