import React from 'react';
import { useState, useEffect } from 'react'; 
import Box from '@mui/material/Box';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { useNavigate } from 'react-router-dom';

import CssBaseline from '@mui/material/CssBaseline';

// Components 

import CreateTicket from './createSupportEmail/CreateTicket';
import SupportTicketsList from './history/SupportTicketsList';
import BackButton from './navigation/BackButton';

const Container: React.FC = () => {

  const dispatch = useAppDispatch(); 

  const navigate = useNavigate(); 

  const colors = useAppSelector(state => state.theme.colors);
  const section = useAppSelector(state => state.navigation.section);

  return (

    <React.Fragment>

        <CssBaseline />

        <Box component="main" sx={{ 
            display: 'flex', 
            height: '100vh', 
            width: '100vw',
            left: 0, 
            overflowX: 'hidden',
            position: 'absolute',
            backgroundColor: colors?.interface,
            color: colors?.highlightedText }}>
          
          { section === 'createTicket' && <CreateTicket />}
          { section === 'history' && <SupportTicketsList />}

        </Box>

    </React.Fragment>

  );

};

export default Container;