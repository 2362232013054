import React, { useState, useEffect, useRef } from 'react';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { useTranslation } from 'react-i18next';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';

// Components 

import Box from '@mui/material/Box';
import { getConsultationLanguage, getConsultationNote, getNoteFieldsWithPin } from '../../routes/doctor/consultation';
import { setConsultationNoteId, setIsMicWarningOpened, setLanguage, setMode, setNoteFields, setSecurityPin } from '../../redux/features/doctor/Dashboard/consultation/noteMicrophone';
import Header from './header/Header';
import Footer from './footer/Footer';
import OptionsList from './footer/lists/OptionsList';
import SettingsList from './footer/lists/SettingsList';
import AutoComplete from './autoComplete/AutoComplete';
import MicrophoneDisabled from './popups/MicrophoneDisabled';

// -- 

const Container: React.FC = () => {

    const dispatch = useAppDispatch(); 
    const { t } = useTranslation(); 
    const navigate = useNavigate(); 

    const { consultationNoteId } = useParams() as any; 
    const { securityPin } = useParams() as any; 

    const { transcript, listening, resetTranscript, browserSupportsSpeechRecognition } = useSpeechRecognition();

    const colors = useAppSelector(state => state.theme.colors); 
    const accountInfo = useAppSelector(state => state.meta.accountInfo); 

    const isOptionsListOpened = useAppSelector(state => state.noteMicrophone.isOptionsListOpened); 
    const isSettingsListOpened = useAppSelector(state => state.noteMicrophone.isSettingsListOpened);
    const isMicWarningOpened = useAppSelector(state => state.noteMicrophone.isMicWarningOpened); 
    const mode = useAppSelector(state => state.noteMicrophone.mode);

    const [timeLeft, setTimeLeft] = useState(2); 

    // -- 

    const handleConsultationLanguage = async () => { 

      const res = await getConsultationLanguage(consultationNoteId) as any; 

      if (res.data.status === 200) { 

        dispatch(setLanguage(res.data.language)); 

      }; 

    }; 

    // -- 

    const updateNoteFields = async () => { 

      const res = await getNoteFieldsWithPin(consultationNoteId, securityPin); 

      if (res?.data.status === 200) { 

        dispatch(setMode(res.data.mode)); 
        dispatch(setNoteFields(res.data.noteFields)); 

      }; 

    }; 

    // -- 

    useEffect(() => { 

        if (consultationNoteId) { 

            dispatch(setConsultationNoteId(consultationNoteId)); 

            handleConsultationLanguage(); 

        }; 

    },[consultationNoteId]); 

    // -- 

    useEffect(() => { 

      if (securityPin) { 

        dispatch(setSecurityPin(securityPin)); 

      }; 

    },[securityPin]); 

    // -- 

    useEffect(() => { 

      if (browserSupportsSpeechRecognition) { 

        dispatch(setIsMicWarningOpened(false)); 

      } else { 

        dispatch(setIsMicWarningOpened(true)); 

      }; 

    },[browserSupportsSpeechRecognition]); 

    // -- 
  
    useEffect(()=>{
  
        let myInterval = setInterval(() => {
  
            if (timeLeft > 0) {
  
                setTimeLeft(timeLeft - 1);
  
            }; 
  
            if (timeLeft === 1) { 
  
              if (consultationNoteId && securityPin) { 
  
                updateNoteFields(); 
  
              };
  
              setTimeLeft(2); 
  
            }; 
  
            if (timeLeft === 0) {
  
                if (timeLeft === 0) {
                    clearInterval(myInterval)
                };
            }; 
  
        }, 500); 
  
        return ()=> {
            clearInterval(myInterval);
        };
  
    },[timeLeft]);

    // -- 

    return (

      <Box
        sx={{ 
            display: 'flex', 
            flexDirection: 'column', 
            alignItems: 'center', 
            justifyContent: 'space-evenly', 
            width: '100vw', 
            height: '100vh', 
        }}
      >

        <Header /> 

        {(!browserSupportsSpeechRecognition && isMicWarningOpened) && <MicrophoneDisabled />}

        <Footer /> 

        { (mode === 'autoComplete') && <AutoComplete />}

        {isOptionsListOpened && <OptionsList />} 

        {isSettingsListOpened && <SettingsList />} 

      </Box>

  );

};

export default Container;