import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import { useAppDispatch, useAppSelector } from '../../../../../redux/hooks';
import moment from 'moment';

// Components 

import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import EventNoteIcon from '@mui/icons-material/EventNote';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import TextRotationNoneIcon from '@mui/icons-material/TextRotationNone';
import { editConsultationNote } from '../../../../../routes/doctor/consultation';
import { setIsRefreshFieldsList } from '../../../../../redux/features/doctor/Dashboard/consultation/consultationNote';
import { setNotificationMessage } from '../../../../../redux/features/general/notification';

type Props = {
    _id: string, 
    title: string, 
};

const FieldRow: React.FC<Props> = ({ _id, title }) => {

    const dispatch = useAppDispatch(); 

    const colors = useAppSelector(state => state.theme.colors);
    const consultationNoteId = useAppSelector(state => state.consultationNote.consultationNoteId); 
    const securityPin = useAppSelector(state => state.consultationNote.securityPin); 

    // -- 

    const handleDelete = async () => { 
        
        const content = { 
            consultationNoteId, 
            mode: 'deleteNoteField',
            securityPin: securityPin, 
            selectedNoteFieldId: _id
        }; 

        await editConsultationNote(content) as any; 

        dispatch(setNotificationMessage('noteFieldRemoved')); 

        dispatch(setIsRefreshFieldsList(true)); 

    }; 

    // -- 

    return (

        <Box 
            sx={{
            display: 'flex', 
            flexDirection: 'column',
            alignItems: 'center', 
            marginLeft: '1%',
            marginRight: '1%',
            width: '95%',
            height: 'auto',
            borderRadius: 3, 
            marginBottom: 1, 
            backgroundColor: colors?.formRow,
            color: colors?.text,
            '&:hover': { backgroundColor: colors?.formRowHover},
            transition: 'height 0.3s',
            boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
            textWrap: 'nowrap'
        }}>

            <Box
                sx={{ 
                    display: 'flex',
                    flexDirection: 'row', 
                    alignItems: 'center', 
                    justifyContent: 'space-between', 
                    width: '100%', 
                    height: 40,
                }}
            >

                <Box
                    // onClick={handleSelection}
                    sx={{
                        display: 'flex', 
                        flexDirection: 'row',
                        alignItems: 'center', 
                        cursor: 'pointer',
                    }}
                >
                    <TextRotationNoneIcon sx={{ color: colors?.lightBlue, fontSize: 25, marginLeft: 2 }} />

                    <Box sx={{ fontSize: 14, marginLeft: 2, overflow: 'hidden', marginRight: 1 }}>{title}</Box>
                </Box>
                
                <HighlightOffIcon 
                    onClick={handleDelete}
                    sx={{ 
                        fontSize: 18, 
                        color: 'rgb(100, 100, 100)',
                        marginRight: 1, 
                        cursor: 'pointer',
                        '&:hover': { color: colors?.red }
                    }} />
            
            </Box>

        </Box>
  );
};

export default FieldRow;