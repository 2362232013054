import React, { useState, useEffect, useRef } from 'react';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

// Components 

import Box from '@mui/material/Box';
import { setIsMicWarningOpened } from '../../../redux/features/doctor/Dashboard/consultation/noteMicrophone';

const MicrophoneDisabled: React.FC = () => {

    const dispatch = useAppDispatch(); 
    const { t } = useTranslation(); 
    const navigate = useNavigate(); 

    const colors = useAppSelector(state => state.theme.colors); 

    const handleWarning = () => {

      dispatch(setIsMicWarningOpened(false)); 

    }; 

    return (

      <Box sx={{ 
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center', 
          width: '80%',
          color: colors?.text,
          fontSize: 12 
      }}>

        <Box sx={{ marginBottom: 2 }}>{t('microphoneUnavailable')}</Box>

        <Box onClick={handleWarning}>Close</Box>

      </Box>

  );

};

export default MicrophoneDisabled;