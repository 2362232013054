import React, { useState, useEffect, useRef } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import { useTranslation } from 'react-i18next';

// Components 

import Box from '@mui/material/Box';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { setIsSidebarOpened, setSidebarSection } from '../../../../redux/features/doctor/Dashboard/consultation/consultationNote';

const CloseSidebar: React.FC = () => {

    const dispatch = useAppDispatch(); 
    const { t } = useTranslation(); 

    const colors = useAppSelector(state => state.theme.colors); 
    const sidebarSection = useAppSelector(state => state.consultationNote.sidebarSection); 

    // -- 

    const handleClose = () => { 

        dispatch(setIsSidebarOpened(false)); 

    }; 

    // -- 

    return (

        <Box 
            onClick={handleClose}
            sx={{ 
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center', 
                justifyContent: 'space-evenly', 
                color: colors?.text, 
                width: 50,
                height: 50, 
                top: 5, 
                right: -25, 
                bottom: 0,  
                borderRadius: '50%', 
                position: 'absolute', 
                backgroundColor: 'rgb(255, 255, 255)',
                boxShadow: '0 4px 6px rgba(0, 0, 0, 0.2)',
                cursor: 'pointer', 
                '&:hover': { backgroundColor: colors?.sectionHover }
        }}>

            <ChevronLeftIcon sx={{ fonSize: 40 }} /> 

        </Box>

    );

};

export default CloseSidebar;