import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import { useAppDispatch, useAppSelector } from '../../../../../../redux/hooks';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

// Components
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { createConsultationNote, deleteAttachedFile, deleteConsultationImage, deleteConsultationNote, getAttachedFiles, getConsultationImages, getConsultationNotes } from '../../../../../../routes/doctor/consultation';
import { setMode } from '../../../../../../redux/features/doctor/Dashboard/documents/fileExplorer';
import { setDashboardSection } from '../../../../../../redux/features/general/navigation';
import UploadFileButton from './UploadFileButton';
import UploadImageButton from './UploadImageButton';
import { setIsImageViewerOpened, setSelectedImage } from '../../../../../../redux/features/doctor/Dashboard/documents/imageViewer';
import { setNotificationMessage } from '../../../../../../redux/features/general/notification';
import { setAttachedFiles, setImages, setNotes, setSection, setSelectedAttachedFile, setSelectedConsultationNote } from '../../../../../../redux/features/doctor/Dashboard/consultation/consultation';
import { setOpenPopup, setPopupAction } from '../../../../../../redux/features/general/popup';
import { resetConsultationNote } from '../../../../../../redux/features/doctor/Dashboard/consultation/consultationNote';

type Props = {
    children?: React.ReactNode;
    title: any,
    disabled?: boolean,
    iconColor?: string, 
    action: string
    type: string; 
    hideLabel?: boolean, 
};


const Action: React.FC<Props> = ({ children, title, disabled, iconColor, action, type, hideLabel }) => {

    const { t } = useTranslation();
    const navigate = useNavigate(); 
    const dispatch = useAppDispatch(); 

    const colors = useAppSelector((state) => state.theme.colors);
    const patientId = useAppSelector((state) => state.consultation.patientId);  
    const consultationId = useAppSelector((state) => state.consultation.consultationId);  
    const selectedImage = useAppSelector((state) => state.consultation.selectedImage); 
    const selectedConsultationNote = useAppSelector((state) => state.consultation.selectedConsultationNote); 
    const selectedAttachedFile = useAppSelector((state) => state.consultation.selectedAttachedFile); 

    // -- 

    const handleAction = async () => { 

        if (action === 'newNote') { 

            dispatch(resetConsultationNote()); 

            const res = await createConsultationNote({ patientId, consultationId }); 

            if (res?.data.status === 200) { 

                navigate(`/consultation/note/${consultationId}/${res.data.consultationNote._id}`); 

            }; 

        } else if (action === 'attachFile') { 
        
            dispatch(setMode('attachFileToConsultation')); 
            dispatch(setDashboardSection('documents')); 
        
        } else if (action === 'viewFile') { 

            dispatch(setSection('fileViewer')); 

        } else if (action === 'viewImage') { 

            dispatch(setSelectedImage(selectedImage)); 
            dispatch(setIsImageViewerOpened(true)); 

        } else if (action === 'viewNote') { 

            dispatch(setSection('noteViewer')); 

        } else if (action === 'editNote') { 
        
            navigate(`/consultation/note/${consultationId}/${selectedConsultationNote._id}`); 

        } else if (action === 'removeNote') { 

            const res = await deleteConsultationNote(selectedConsultationNote._id); 

            if (res?.data.status === 200) { 

                dispatch(setSelectedConsultationNote(null)); 

                dispatch(setNotificationMessage(t('noteDeleted'))); 

                const res = await getConsultationNotes(consultationId) as any; 
        
                if (res.data.status === 200) { 
        
                    dispatch(setNotes(res.data.consultationNotes)); 
        
                }; 

            }; 

        } else if (action === 'template') { 

            const res = await createConsultationNote({ type: 'template' }); 

            if (res?.data.status === 200) { 

                navigate(`/consultation/note/template/${consultationId}/${res.data.consultationNote._id}`); 

            }; 

        } else if (action === 'removeFile') { 

            const res = await deleteAttachedFile(selectedAttachedFile._id); 

            if (res?.data.status === 200) { 

                dispatch(setSelectedAttachedFile(null)); 

                dispatch(setNotificationMessage(t('attachedFileRemoved'))); 

                const res = await getAttachedFiles(consultationId) as any; 
        
                if (res.data.status === 200) { 
        
                    dispatch(setAttachedFiles(res.data.attachedFiles)); 
        
                }; 

            }; 
        
        } else if (action === 'removeImage') { 

            const res = await deleteConsultationImage(selectedImage._id); 

            if (res?.data.status === 200) { 

                dispatch(setNotificationMessage(t('consultationImageRemoved'))); 

                const res = await getConsultationImages(consultationId) as any; 
        
                if (res.data.status === 200) { 
        
                    dispatch(setImages(res.data.images)); 
        
                }; 
            }; 

        } else if (action === 'takePicture') { 

            dispatch(setPopupAction('camera')); 
            dispatch(setOpenPopup()); 

        }; 

    }; 

    // -- 

    return (

        <>
        
            { type === 'default' && 
            
                <Box 
                    onClick={handleAction}
                    sx={{ 
                    display: 'flex', 
                    flexDirection: 'row',
                    alignItems: 'center',
                    color: disabled ? colors?.disabledRelatedPatient : iconColor,
                    marginLeft: 3,
                    padding: 1, 
                    paddingTop: 3, 
                    paddingBottom: 3, 
                    borderRadius: 3, 
                    cursor: disabled ? 'default' : 'pointer', 
                    height: 30,
                    fontSize: 20, 
                    zIndex: 0, 
                    '&:hover': { backgroundColor: disabled ? 'transparent' : colors?.sectionHover }
                }}>
                    {children}
                    { !hideLabel && <Box sx={{ marginLeft: 1, fontSize: 12, color: disabled ? colors?.disabledRelatedPatient : colors?.text }}>{t(title)}</Box>}

                    { (action === 'uploadFile') && <UploadFileButton />}

                    { (action === 'uploadImage') && <UploadImageButton />}

                </Box>}

            { type === 'dropdown' &&
                
                <Box
                    onClick={handleAction}
                    sx={{ 
                        display: 'flex', 
                        flexDirection: 'row',
                        alignItems: 'center',
                        width: '100%', 
                        height: 40,
                        fontSize: 12,  
                        borderRadius: 3,
                        '&:hover': { backgroundColor: colors?.sectionHover}
                    }}
                >
                    <Box
                        style={{ 
                            display: 'flex', 
                            flexDirection: 'row',
                            alignItems: 'center',
                            marginLeft: 10
                        }}
                    >
                        {children}
                        <Box sx={{ marginLeft: 3 }}>{t(title)}</Box>
                    </Box>

            </Box>}
            
        </>


    );
};

export default Action;