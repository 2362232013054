import React from 'react';
import Box from '@mui/material/Box';
import { useAppSelector } from '../../../../../../redux/hooks';
import { useTranslation } from 'react-i18next';

import Action from './Action';

const ActionsRow: React.FC = () => {

    const { t } = useTranslation();

    const colors = useAppSelector(state => state.theme.colors);
    const patientId = useAppSelector((state) => state.consultation.patientId);  

    return (

        <Box 
            sx={{ 
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                height: 30, 
                width: '100%', 
        }}>

            <Action title={'note'} type='default' iconColor={colors?.purple} action={'newNote'}>
                <Box component="img" sx={{ width: 30 }} src={require('../../../../../../assets/icons/consultation/add-note.png')} />
            </Action>

            <Action title={'attachFile'} type='default' iconColor={colors?.file} action={'attachFile'}>
                <Box component="img" sx={{ width: 30 }} src={require('../../../../../../assets/icons/consultation/attach-file.png')} />
            </Action>

            <Action title={'addImage'} type='default' iconColor={colors?.darkPurple} action={'uploadImage'}>
                <Box component="img" sx={{ width: 30 }} src={require('../../../../../../assets/icons/consultation/add-picture.png')} />
            </Action>

            <Action title={'uploadFile'} type='default' iconColor={colors?.green} action={'uploadFile'}>
                <Box component="img" sx={{ width: 30 }} src={require('../../../../../../assets/icons/consultation/upload-file.png')} />
            </Action>

            <Action title={'template'} type='default' iconColor={colors?.darkBlue} action={'template'}>
                <Box component="img" sx={{ width: 30 }} src={require('../../../../../../assets/icons/consultation/template.png')} />
            </Action>

            <Action title={'takePicture'} type='default' iconColor={colors?.red} action={'takePicture'}>
                <Box component="img" sx={{ width: 28 }} src={require('../../../../../../assets/icons/consultation/take-picture.png')} />
            </Action>

        </Box>
    );
};

export default ActionsRow;