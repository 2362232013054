import React, { useEffect, useState, useRef } from 'react';
import Box from '@mui/material/Box';
import { useAppDispatch, useAppSelector } from '../../../../../../../redux/hooks';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { getDoctorName } from '../../../../../../../routes/doctor/patients';
import { serveFile } from '../../../../../../../routes/doctor/files';

// Redux

// Components 


type Props = {
    index?: any;  
    item?: any; 
    type: string; 
    numberOfItems?: number; 
};


const BoxView: React.FC<Props> = ({ index, item, type, numberOfItems }) => {

    const { t } = useTranslation(); 
    const dispatch = useAppDispatch(); 

    const colors = useAppSelector(state => state.theme.colors);
    const section = useAppSelector(state => state.savedConsultation.section);

    const [title, setTitle] = useState(''); 
    const [iconSource, setIconSource] = useState(''); 

    const [blobFileUrl, setBlobFileUrl] = useState('');

    // -- 

    const handleDoctorFullName = async () => { 

        const res = await getDoctorName(item?.doctorId); 

        if (res?.data.status === 200) { 

            setTitle(`${moment.utc(item?.updatedAt).format('L')}_${res.data.fullName}`); 

        }; 

    }; 

    // -- 

    const handleFileThumbnail = async () => { 

        const res = await serveFile(item?._id, 'image') as any; 
        
        const fileBlob = new Blob([res.data], { type: res.headers['content-type'] });

        setIconSource(URL.createObjectURL(fileBlob)); 

    }; 

    // -- 

    useEffect(() => { 

        if (type === 'consultation') { 

            setIconSource(require('../../../../../../../assets/icons/consultation-icon.png')); 

            handleDoctorFullName(); 

        } else if (type === 'images') { 

            setIconSource(require('../../../../../../../assets/icons/images-icon.png'));

            setTitle(String(t('images'))); 

        } else if (type === 'attachedFiles') { 

            setIconSource(require('../../../../../../../assets/icons/files-icon.png'));

            setTitle(String(t('files'))); 

        } else if (type === 'notes') { 

            setIconSource(require('../../../../../../../assets/icons/notes-icon.png'));

            setTitle(String(t('notes'))); 

        } else if (type === 'note') { 

            setIconSource(require('../../../../../../../assets/icons/note-thumbnail.png'));

            setTitle(item?.title); 

        } else if (type === 'file') { 

            setIconSource(require('../../../../../../../assets/icons/pdf-thumbnail.png'));

            setTitle(item?.title); 

        } else if (type === 'image') { 

            handleFileThumbnail(); 

            setTitle(item?.title); 

        }; 

    },[type]); 

    // -- 

    return (

        <Box 
            sx={{ 
            display: 'flex', 
            flexDirection: 'column',
            alignItems: 'center', 
            width: 150,
            marginLeft: 5,
            marginRight: 5,
            borderRadius: 3,
            marginTop: 1, 
            height: 200, 
            cursor: 'pointer',
            overflow: 'hidden', 
            backgroundColor: 'transparent',
            '&:hover': { backgroundColor: 'rgba(77, 75, 89, 0.2)' }
        }}>
            
            <Box
                component="img"
                sx={{
                    width: 100,
                    marginTop: 1, 
                    marginBottom: 2, 
                }}
                src={iconSource}
            />

            <div style={{ 
                width: '95%',
                color: colors?.text, 
                textAlign: 'center',
                overflow: 'hidden',
                fontSize: 12, 
                whiteSpace: 'wrap'
            }}>{title}</div>

            { (section === 'categories') && 
            <div style={{ 
                width: '95%',
                color: 'grey', 
                textAlign: 'center',
                overflow: 'hidden',
                fontSize: 12, 
                marginTop: 5, 
                whiteSpace: 'wrap'
            }}>{numberOfItems} Items</div>}

        </Box>

    );

};

export default BoxView;