import React, { useState, useEffect }from 'react';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { useNavigate } from 'react-router-dom';

// Components 

import Box from '@mui/material/Box';
import ArrowCircleLeftOutlinedIcon from '@mui/icons-material/ArrowCircleLeftOutlined';
import LanguageSelector from '../../general/navBar/LanguageSelector';

const Language: React.FC = () => {

  const navigate = useNavigate(); 

  const colors = useAppSelector(state => state.theme.colors);
  const section = useAppSelector(state => state.navigation.section);

  // -- 

  return (

    <Box
        sx={{ 
            display: 'flex', 
            flexDirection: 'row',
            alignItems: 'center',
            height: 50, 
            marginLeft: 10, 
            marginTop: 5,
            color: colors?.text,
    }}>

        <Box sx={{ marginLeft: 2 }}>Language</Box>

        <Box
            sx={{ 
                display: 'flex', 
                flexDirection: 'row',
                alignItems: 'center',
                height: '100%', 
            }}
        >
            <LanguageSelector /> 

        </Box>



    </Box>

  );

};

export default Language;