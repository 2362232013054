import React from 'react';
import Box from '@mui/material/Box';
import { useAppSelector } from '../../../../../../redux/hooks';

// Components 

import AccountCircleIcon from '@mui/icons-material/AccountCircle';


type Props = {
    _id: string, 
    fullName?: React.ReactNode,
};

const PatientRow: React.FC<Props> = ({ _id, fullName }) => {

    const colors = useAppSelector(state => state.theme.colors);
    const patient = useAppSelector(state => state.patient.patient) as any; 

    return (

        <Box 
            sx={{
            display: 'flex', 
            flexDirection: 'row',
            alignItems: 'center', 
            width: '100%',
            height: 40,
            color: colors?.text,
            backgroundColor: patient?._id === _id ? colors?.selectedSectionHover : 'transparent',
            '&:hover': { backgroundColor: patient?._id === _id ? colors?.selectedSectionHover : colors?.patientRowUnselected }
        }}>

            <Box 
                component="img" 
                sx={{ 
                    width: 20, 
                    marginLeft: 2 
                    }} 
                src={require('../../../../../../assets/icons/consultation/select-patient-avatar.png')} 
            />

            <Box sx={{ fontSize: 14, marginLeft: 2 }}>{fullName}</Box>

        </Box>
  );
};

export default PatientRow;