import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { useAppDispatch, useAppSelector } from '../../../../../../redux/hooks';
import { useTranslation } from 'react-i18next';

// Components 

import Action from '../../actions/Action';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import RefreshIcon from '@mui/icons-material/Refresh';
import GridViewIcon from '@mui/icons-material/GridView';
import WrapTextOutlinedIcon from '@mui/icons-material/WrapTextOutlined';

const HeaderActions: React.FC = () => {

    const dispatch = useAppDispatch(); 
    const { t } = useTranslation();

    const colors = useAppSelector(state => state.theme.colors); 
    const section = useAppSelector(state => state.savedConsultation.section); 
    const consultationId = useAppSelector(state => state.savedConsultation.consultationId); 
    const folderView = useAppSelector((state) => state.fileExplorer.folderView); 

    const [isBackNavigationDisabled, setIsBackNavigationDisabled] = useState(false); 
    const [isForwardNavigationDisabled, setIsForwardNavigationDisabled] = useState(false);  
    const [isRefreshDisabled, setIsRefreshDisabled] = useState(false); 

    // -- 

    useEffect(() => { 

        if ((section !== 'consultations') || !consultationId) {

            setIsForwardNavigationDisabled(true); 

        } else { 

            setIsForwardNavigationDisabled(false); 

        }; 

        if (section === 'consultations') { 

            setIsRefreshDisabled(false); 

        } else { 

            setIsRefreshDisabled(true); 

        }; 

    },[section, consultationId]); 

    // -- 

    // --

    return (

        <Box style={{ 
            display: 'flex', 
            flexDirection: 'row',
            alignItems: 'center', 
            width: '100%',
            height: '70%', 
            fontSize: 20,
        }}>

            <Action title={'goBack'} iconColor={colors?.consultationNavBarArrows} action={'goBackConsultations'} type='default' disabled={isBackNavigationDisabled} hideLabel>
                <ArrowBackIcon /> 
            </Action>
            
            <Action title={'goForward'} iconColor={colors?.consultationNavBarArrows} action={'goForwardConsultations'} type='default' disabled={isForwardNavigationDisabled} hideLabel>
                <ArrowForwardIcon /> 
            </Action>

            <Action title={'patientInfo'} iconColor={'rgb(30, 144, 255)'} action={'patientInfo'} type='default'>
                <AccountCircleIcon /> 
            </Action>

            <Action title={'refresh'} iconColor='rgb(80,199,199)' action={'refreshConsultations'} type='default' disabled={isRefreshDisabled}>
                <RefreshIcon /> 
            </Action>

            <Action title={'changeView'} iconColor='rgba(188, 45, 207, 0.7)' action={'changeFolderView'} type='default'>
                { folderView === 'row' ? <WrapTextOutlinedIcon /> : <GridViewIcon />}
            </Action>

        </Box>

    );

};

export default HeaderActions;