import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import { useAppDispatch, useAppSelector } from '../../../../../../redux/hooks';
import FileOptions from './FileOptions';

const Footer: React.FC = () => {

    const dispatch = useAppDispatch(); 

    const colors = useAppSelector(state => state.theme.colors);
    const selectedAttachedFile = useAppSelector(state => state.consultation.selectedAttachedFile);

    return (

        <Box sx={{ 
            display: 'flex', 
            flexDirection: 'row',
            alignItems: 'center', 
            width: '75vw',
            height: 40, 
            backgroundColor: colors?.container,
            position: 'absolute', 
            bottom: '3vh', 
        }}>

            { selectedAttachedFile && <FileOptions />}

        </Box>

    );

};

export default Footer;