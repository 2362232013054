import React from 'react';
import Box from '@mui/material/Box';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { useNavigate } from 'react-router-dom';

// Components 

import NavBar from '../general/navBar/NavBar';
import BannerHeader from './BannerHeader';
import PagesDescription from './PagesDescription';
import FAQ from './FAQ';
import Footer from '../general/navigation/Footer';

const Container: React.FC = () => {

  const navigate = useNavigate(); 

  const colors = useAppSelector(state => state.theme.colors);

  return (

    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100vh',
        width: '100vw',
        overflow: 'auto',
        overflowY: 'scroll',
        scrollbarWidth: 'none',
        position: 'absolute',
        fontSize: 12,
        bottom: 0,
        color: colors?.text,
        textAlign: 'center',
        backgroundColor: colors?.interface,
      }}
    >

        <NavBar />
        <BannerHeader /> 
        <PagesDescription />
        <FAQ />
        <Footer isScrollPage={true} />

    </Box>
  );
};

export default Container;