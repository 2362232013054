import React from 'react';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';

// Component

import DescriptionBox from './DescriptionBox';

// Icon

import BalanceIcon from '@mui/icons-material/Balance';

const PagesDescription: React.FC = () => {

    const { t } = useTranslation();

    const colors = useAppSelector(state => state.theme.colors);

    const privacyPolicy = t('privacyContent', { returnObjects: true }) as any;

    const terms = t('tosContent', { returnObjects: true }) as any;

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'space-evenly', 
        alignItems: 'center',
        width: '100vw',
        minHeight: 300,
        color: colors?.legalDescription,
        backgroundColor: colors?.legalDescriptionBackground
      }}
    >

        <DescriptionBox 
            icon={<BalanceIcon sx={{ fontSize: 50, marginBottom: 3 }} />}
            title={t('privacyPolicy')}
            description={privacyPolicy?.introduction}
            navigateTo={'/privacy'}
        />

        <DescriptionBox 
            icon={<BalanceIcon sx={{ fontSize: 50, marginBottom: 3 }} />}
            title={t('termsOfService')}
            description={privacyPolicy?.introduction}
            navigateTo={'/tos'}
        />

    </Box>
  );
};

export default PagesDescription;