import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

// Components

import TextFieldsIcon from '@mui/icons-material/TextFields';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';


const Info: React.FC = () => {

    const { t } = useTranslation();
    const navigate = useNavigate(); 
    const dispatch = useAppDispatch(); 

    const colors = useAppSelector((state) => state.theme.colors);
    const newNoteFieldTitle = useAppSelector((state) => state.consultationNote.newNoteFieldTitle);

    // -- 

    return (
                
        <Box
            sx={{ 
                display: 'flex', 
                flexDirection: 'column',
                width: '100%', 
                fontSize: 12,  
                marginTop: 2, 
                paddingLeft: 1, 
                backgroundColor: colors?.fileExplorerDropdown,
                boxShadow: '0 4px 6px rgba(0, 0, 0, 0.2)',
                borderRadius: 4, 
                color: 'grey', 
                paddingTop: 1,
                paddingBottom: 1, 
            }}
        >

            <Box
                style={{ 
                    display: 'flex', 
                    flexDirection: 'row',
                    alignItems: 'center',
                    marginLeft: 10,
                    height: 30, 
                }}
            >{t('enterCreateNoteField')}
            </Box>

            <Box
                style={{ 
                    display: 'flex', 
                    flexDirection: 'row',
                    alignItems: 'center',
                    marginTop: 1, 
                    marginLeft: 10,
                    height: 30, 
                }}
            >{t('quitCreateNotField')}
            </Box>

    </Box>

    );
};

export default Info;