import React, { useState, useEffect, useRef } from 'react';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { useTranslation } from 'react-i18next';
import { Navigate, useLocation, useNavigate, useParams } from 'react-router-dom';

// Components 

import Box from '@mui/material/Box';
import Sidebar from './sidebar/Sidebar';
import { resetConsultationNote, setConsultationNoteId, setIsSecurityPopupOpened, setSecurityPin } from '../../redux/features/doctor/Dashboard/consultation/consultationNote';
import Document from './document/Document';
import Navbar from './navbar/Navbar';
import Popup from '../general/Popup';
import ExitPopup from './navbar/items/ExitPopup';
import AccessPopup from './document/AccessPopup';
import ExistingFieldPopup from './dropdown/fields/ExistingFieldPopup';
import AttributesDropdown from './dropdown/attributes/AttributesDrowpdown';
import FieldsDropdown from './dropdown/fields/FieldsDropdown';
import { getConsultation } from '../../routes/doctor/consultation';
import { setClosePopup, setOpenPopup, setPopupAction } from '../../redux/features/general/popup';
import { setNotificationMessage } from '../../redux/features/general/notification';

// -- 

const Container: React.FC = () => {

    const dispatch = useAppDispatch(); 
    const { t } = useTranslation(); 
    const navigate = useNavigate(); 
    const location = useLocation(); 

    const { consultationId, consultationNoteId } = useParams() as any; 

    const isPopupOpened = useAppSelector((state) => state.popup.isPopupOpened); 
    const isSavingEnabled = useAppSelector(state => state.consultationNote.isSavingEnabled); 
    const securityPin = useAppSelector(state => state.consultationNote.securityPin); 
    const popupAction = useAppSelector(state => state.popup.action); 
    const action = useAppSelector(state => state.consultationNote.action); 
    const isSecurityPopupOpened = useAppSelector(state => state.consultationNote.isSecurityPopupOpened); 
    const [timeLeft, setTimeLeft] = useState(2); 

    // -- 

    window.onbeforeunload = function (e: any) {

      if (isSavingEnabled) { 
  
        // For IE and Firefox
  
        if (e) {
          e.returnValue;
        }; 
      
        // For Safari
        return e.returnValue;
  
      };
  
    };

    // -- 

    const verifyConsultation = async () => { 

      const res = await getConsultation(consultationId) as any; 

      if (res.data.status === 200) { 

            if (res.data.consultation.status !== 'pending') { 

                dispatch(resetConsultationNote()); 

                navigate('/dashboard'); 

            }; 

            if (res.data.consultation.securityPin !== securityPin) { 
                  
                dispatch(setSecurityPin(res.data.consultation.securityPin)); 

            }; 

            if (res.data.isAccessRequested) { 

                if (!isSecurityPopupOpened) { 

                    dispatch(setPopupAction('securityPin')); 
                    dispatch(setOpenPopup()); 

                    dispatch(setIsSecurityPopupOpened(true)); 

                }; 

            }; 

            if (res.data.isDeviceConnected) { 

              dispatch(setNotificationMessage(t('deviceConnected'))); 

              if (isPopupOpened && (popupAction === 'securityPin')) { 

                dispatch(setClosePopup()); 

              }; 

            }; 
            
      } else { 

        console.log('There'); 

        dispatch(resetConsultationNote()); 

        navigate('/dashboard'); 

      }; 

    }; 

    // -- 

    useEffect(() => { 

        if (consultationNoteId) { 

            dispatch(setConsultationNoteId(consultationNoteId)); 

        }; 

    },[consultationNoteId]); 

    // -- 
  
    useEffect(()=>{

        let myInterval = setInterval(() => {

            if (timeLeft > 0) {

                setTimeLeft(timeLeft - 1);

            }; 

            if (timeLeft === 1) { 

              if (consultationId && (!location?.pathname.includes('template'))) { 

                verifyConsultation(); 

              }; 

              setTimeLeft(2); 

            }; 

            if (timeLeft === 0) {

                if (timeLeft === 0) {
                    clearInterval(myInterval); 
                };
            }; 

        }, 500); 

        return ()=> {
            clearInterval(myInterval);
        };

    },[timeLeft]);

    // -- 

    return (

      <Box
        sx={{ 
          width: '100%', 
          height: '100%', 
        }}
      >

        <Sidebar /> 

        <Document /> 

        <Navbar /> 

        {(action === 'attribute') && <AttributesDropdown />}
        {(action === 'newNoteField') && <FieldsDropdown />}

        { isPopupOpened && 
          <Popup>
            { popupAction === 'exitConsultationNote' && <ExitPopup />} 
            { popupAction === 'securityPin' && <AccessPopup />}
            { popupAction === 'existingNoteField' && <ExistingFieldPopup />}
          </Popup>}

        

      </Box>

  );

};

export default Container;