import React, { useState, useEffect, useRef } from 'react'; 
import { useAppDispatch, useAppSelector } from '../../../../../redux/hooks';
import Box from '@mui/material/Box';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// Components 

import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import { resetImageViewer } from '../../../../../redux/features/doctor/Dashboard/documents/imageViewer';
import { resetFileViewer } from '../../../../../redux/features/doctor/Dashboard/documents/fileViewer';
import { resetNoteViewer } from '../../../../../redux/features/doctor/Dashboard/documents/noteViewer';

interface Props { 
    title: string
}; 

const Header: React.FC<Props> = ({ title }) => {

    const dispatch = useAppDispatch(); 
    const navigate = useNavigate(); 

    const { t } = useTranslation(); 

    const isSmallScreen = useMediaQuery('(max-width: 1300px)');

    const colors = useAppSelector(state => state.theme.colors); 

    // -- 

    const leaveViewer = () => { 

        dispatch(resetImageViewer()); 
        dispatch(resetFileViewer()); 
        dispatch(resetNoteViewer()); 

    }; 

    // --

    return (

        <Box 
            style={{ 
                display: 'flex', 
                flexDirection: 'row',
                alignItems: 'center', 
                justifyContent: 'space-evenly',
                top: 0, 
                width: '100vw',
                height: 50, 
                overflow: 'hidden', 
                position: 'absolute', 
                boxShadow: '0 4px 6px rgba(0, 0, 0, 0.2)',
                zIndex: 10000
            
        }}>

            <Box
                component="img"
                sx={{
                    height: `${20}px`,
                    width: 'auto',
                    position: 'absolute',
                    top: 15,
                    left: 15, 
                }}
                alt="Solutions Medca Inc."
                src={require('../../../../../assets/logos/emblem.png')}
            />

            <Box
                sx={{ 
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'space-evenly',
                    color: 'rgb(150, 150, 150)', 
                    fontSize: 12, 
                }}
            >{title}
            </Box>

            <CancelOutlinedIcon 
                onClick={leaveViewer}
                sx={{ 
                    fontSize: 30, 
                    color: colors?.cancelButtonText, 
                    cursor: 'pointer', 
                    position: 'absolute',
                    top: 15,
                    right: 15, 
                    '&:hover': { color: colors?.cancelButtonColor },
                }} />

        </Box>
  );
};

export default Header;