import React, { useState, useEffect, useRef } from 'react';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { useTranslation } from 'react-i18next';

// Components 

import {default as MuiTextField} from '@mui/material/TextField';
import Box from '@mui/material/Box';
import { setAction, setAttributesQueryList, setHightlightedAttribute, setIsExistingNoteFieldTitle, setIsRefreshFieldsList, setIsSavingEnabled, setIsSecurityPopupOpened, setIsSidebarOpened, setIsTextModified, setManualAttributeSearchQuery, setNewAttributeSelectionStart, setNewNoteFieldSelectionStart, setNewNoteFieldTitle, setOriginalTitle, setSearchQuery, setSecurityPin, setSelectedAttribute, setSelectionStart, setSidebarSection, setTitle, setType } from '../../../redux/features/doctor/Dashboard/consultation/consultationNote';
import { editConsultationNote, getConsultation, getConsultationNote } from '../../../routes/doctor/consultation';
import { setMode, setIsAttributesDropdownOpened } from '../../../redux/features/doctor/Dashboard/consultation/consultationNote';
import { setClosePopup, setOpenPopup, setPopupAction } from '../../../redux/features/general/popup';
import { setNotificationMessage } from '../../../redux/features/general/notification';
import attributesList from '../dropdown/attributes/attributesList';
import { componentsToColor, copyStringIntoBuffer } from 'pdf-lib';
import AttributesDropdown from '../dropdown/attributes/AttributesDrowpdown';
import FieldsDropdown from '../dropdown/fields/FieldsDropdown';

interface Props { 
    width: number;
    height: number; 
}

const Text: React.FC<Props> = ({ width, height }) => {

    const dispatch = useAppDispatch(); 
    const { t } = useTranslation(); 

    const textFieldComponent = document?.getElementById("consultationNote-textField") as any; 

    const colors = useAppSelector(state => state.theme.colors); 
    const mode = useAppSelector(state => state.consultationNote.mode); 
    const consultationNoteId = useAppSelector(state => state.consultationNote.consultationNoteId); 
    const securityPin = useAppSelector(state => state.consultationNote.securityPin); 
    const attributesQueryList = useAppSelector(state => state.consultationNote.attributesQueryList); 
    const hightlightedAttribute = useAppSelector(state => state.consultationNote.hightlightedAttribute); 
    const selectedAttribute = useAppSelector(state => state.consultationNote.selectedAttribute); 
    const searchQuery = useAppSelector(state => state.consultationNote.searchQuery); 
    const isPopupOpened = useAppSelector(state => state.popup.isPopupOpened); 
    const popupAction = useAppSelector(state => state.popup.action); 
    const action = useAppSelector(state => state.consultationNote.action); 
    const newNoteFieldTitle = useAppSelector(state => state.consultationNote.newNoteFieldTitle); 
    const newNoteFieldSelectionStart = useAppSelector(state => state.consultationNote.newNoteFieldSelectionStart); 
    const newAttributeSelectionStart = useAppSelector(state => state.consultationNote.newAttributeSelectionStart); 
    const isExistingNoteFieldTitle = useAppSelector((state) => state.consultationNote.isExistingNoteFieldTitle);
    const isSidebarOpened = useAppSelector((state) => state.consultationNote.isSidebarOpened);
    const title = useAppSelector((state) => state.consultationNote.title);
    const type = useAppSelector((state) => state.consultationNote.type);
    const isTextModified = useAppSelector((state) => state.consultationNote.isTextModified);
    const manualAttributeSearchQuery = useAppSelector((state) => state.consultationNote.manualAttributeSearchQuery); 

    const [timeLeft, setTimeLeft] = useState(2); 
    const [text, setText] = useState(''); 

    const [dropdownTop, setDropdownTop] = useState(0); 
    const [dropdownLeft, setDropdownLeft] = useState(0); 

    // -- 

    const onTextChange = async (e: any) => { 

        dispatch(setSelectionStart(e.target.selectionStart)); 

        if (action === 'attribute') { 

            setText(e.target.value); 

            if (searchQuery && (!e.target.value.includes(`@${searchQuery}`))) { 

                resetNoteAttribute(); 

            }; 

        } else if (action === 'newNoteField') { 

            setText(e.target.value); 

            if (newNoteFieldTitle && (!e.target.value.includes(`#${newNoteFieldTitle}`))) { 

                resetNoteField(); 

            }; 

        } else { 

            if (!newNoteFieldTitle) { 

                setText(e.target.value); 

                const content = { 
                    consultationNoteId, 
                    newDescription: e.target.value,
                    overWrite: true,
                    mode: 'writing',
                    securityPin: securityPin, 
                    selectionStart: e.target.selectionStart
                }; 
        
                await editConsultationNote(content) as any; 

                (e.target.value) ? dispatch(setIsTextModified(true)) : dispatch(setIsTextModified(false)); 

            }; 

        }; 

    }; 

    // -- 

    const getTextUpdate = async () => { 

        const res = await getConsultationNote(consultationNoteId) as any; 

        if (res.data.status === 200) { 

            dispatch(setType(res.data.consultationNote.type)); 
            dispatch(setMode(res.data.consultationNote.mode)); 

            if (res.data.consultationNote.mode !== 'writing') { 

                dispatch(setIsTextModified(true)); 

                setText(res.data.consultationNote.description); 

                (res.data.consultationNote.description) ? dispatch(setIsTextModified(true)) : dispatch(setIsTextModified(false)); 

            }; 

        }; 

    }; 

    // -- 

    const handleTextReset = async () => { 

        setText(''); 

        const content = { 
            consultationNoteId, 
            newDescription: '',
            overWrite: true,
            mode: 'writing',
            securityPin: securityPin, 
        }; 

        await editConsultationNote(content) as any; 

        textFieldComponent.focus(); 

    }; 

    // -- 

    const handleNewNoteField = async () => { 

        const content = { 
            consultationNoteId, 
            newDescription: '',
            overWrite: false,
            mode: 'newNoteField',
            securityPin: securityPin, 
            newField: newNoteFieldTitle, 
            selectionStart: newNoteFieldSelectionStart
        }; 

        const res = await editConsultationNote(content) as any; 

        if (res.data.status === 200) { 

            setText(res.data.consultationNote.description); 

            dispatch(setIsRefreshFieldsList(true)); 

            resetNoteField(); 

        }; 

    }; 

    // -- 

    const handleAttribute = async () => { 

        const content = { 
            consultationNoteId, 
            newDescription: '',
            overWrite: false,
            mode: (type === 'template') ? 'newAttribute': 'attribute',
            securityPin: securityPin, 
            attribute: selectedAttribute,
            selectionStart: newAttributeSelectionStart
        }; 

        const res = await editConsultationNote(content) as any; 

        resetNoteAttribute(); 

        if (res.data.status === 200) { 

            setText(res.data.description); 

        }; 

    }; 

    // -- 

    const handleInitialState = async () => { 

        const res = await getConsultationNote(consultationNoteId) as any; 

        if (res.data.status === 200) { 

            dispatch(setType(res.data.consultationNote.type)); 
            setText(res.data.consultationNote.description); 

            if (res.data.consultationNote.title && !title) { 

                dispatch(setOriginalTitle(res.data.consultationNote.title)); 

            }; 

        }; 

    }; 

    // -- 

    const resetNoteField = () => { 

        dispatch(setNewNoteFieldTitle('')); 
        dispatch(setNewNoteFieldSelectionStart(0)); 
        dispatch(setAction('')); 
        dispatch(setIsExistingNoteFieldTitle(false)); 

        textFieldComponent.focus(); 

    }; 

    // -- 

    const resetNoteAttribute = () => { 

        dispatch(setNewAttributeSelectionStart(0)); 
        dispatch(setAction('')); 
        dispatch(setSearchQuery('')); 
        dispatch(setManualAttributeSearchQuery('')); 
        dispatch(setAttributesQueryList(attributesList)); 
        dispatch(setHightlightedAttribute(attributesList[0])); 

        textFieldComponent.focus(); 

    }; 

    // -- 

    const onKeyChange = (e: any) => { 

        // -- 

        if ((e.key === 's') && (e.ctrlKey) && (e.altKey) && text) { 

            dispatch(setMode('saveConsultationNote')); 

            return; 

        } else if ((e.key === 'b') && (e.ctrlKey) && (e.altKey)) { 

            dispatch(setIsSidebarOpened(!isSidebarOpened)); 

            return; 

        } else if (e.key === ' ' && (e.ctrlKey) && (e.altKey)) { 

            const content = { 
                consultationNoteId, 
                mode: 'writing',
                securityPin: securityPin, 
                selectionStart: e.target.selectionStart,
            }; 
    
            editConsultationNote(content);

        }; 

        // -- 

        if (action === 'attribute') {

            if (e.key === 'Enter') { 

                dispatch(setSelectedAttribute(hightlightedAttribute)); 
                dispatch(setAction('newAttribute'));              

            }  else if ((e.key === 'Backspace') && (e.ctrlKey)) { 

                if (text) { 

                    setText(text.replace(`@${searchQuery}`, '')); 

                }; 

                resetNoteAttribute(); 
    
            } else if (e.key === 'Backspace') { 

                if (searchQuery) { 

                    dispatch(setSearchQuery(searchQuery.substring(0, searchQuery.length - 1)));

                } else { 

                    resetNoteAttribute(); 

                }; 
                
            } else if (e.key === 'ArrowDown') { 

                const currentIndex = attributesQueryList.indexOf(hightlightedAttribute); 

                if ((currentIndex > -1) && (attributesQueryList.length > (currentIndex + 1))) { 

                    dispatch(setHightlightedAttribute(attributesQueryList[currentIndex + 1])); 

                } else if ((currentIndex > -1) && (currentIndex === (attributesQueryList.length - 1))) { 

                    dispatch(setHightlightedAttribute(attributesQueryList[0])); 

                }; 

            } else if (e.key === 'ArrowUp') { 

                const currentIndex = attributesQueryList.indexOf(hightlightedAttribute); 

                if ((currentIndex > -1) && (currentIndex !== 0)) { 

                    dispatch(setHightlightedAttribute(attributesQueryList[currentIndex - 1])); 

                } else if ((currentIndex > -1) && (currentIndex === 0)) { 

                    dispatch(setHightlightedAttribute(attributesQueryList[attributesQueryList.length - 1])); 

                }; 

            } else { 
                
                const reg = new RegExp('^[a-zA-Z]+$'); 

                if ((e.key === 'BackspaceShift') || (e.key === 'Shift') || !reg.test(e.key) || (e.key.length > 1)) { 
                    
                    return; 
                    
                } else { 

                    const updatedSearchQuery = `${searchQuery}${e.key}`; 

                    dispatch(setSearchQuery(updatedSearchQuery)); 

                }; 

            };

        } else if (action === 'newNoteField') { 

            if ((e.key === 'Enter')) { 

                if (newNoteFieldTitle) { 

                    dispatch(setAction('addNewNoteField')); 

                } else { 

                    resetNoteField(); 

                }; 

            } else if ((e.key === 'Backspace') && (e.ctrlKey)) { 

                if (text) { 

                    setText(text.replace(`#${newNoteFieldTitle}`, '')); 

                }; 

                resetNoteField(); 
    
            } else if (e.key === 'Backspace') { 

                if (newNoteFieldTitle) { 

                    dispatch(setNewNoteFieldTitle(newNoteFieldTitle.substring(0, newNoteFieldTitle.length - 1)));

                } else { 

                    resetNoteField(); 

                }; 
                
            } else { 

                const reg = new RegExp('^[a-zA-Z\s]+$'); // This regex does not working, since it is supposed to allow spaces.

                if (((e.key === 'BackspaceShift') || (e.key === 'Shift') || !reg.test(e.key) || (e.key.length > 1)) && (e.key !== ' ')) { 
                    
                    return; 
                    
                } else { 

                    const updatedFieldTitle = (e.key === ' ') ? newNoteFieldTitle + ' ' : `${newNoteFieldTitle}${e.key}`; 

                    console.log(updatedFieldTitle); 

                    dispatch(setNewNoteFieldTitle(updatedFieldTitle)); 

                }; 

            }; 
        
        } else { 

            if (e.key === '@') { 

                dispatch(setAction('attribute'));
                dispatch(setNewAttributeSelectionStart(e.target.selectionStart)); 

                // dispatch(setAttributesQueryList(attributesList)); 
                // dispatch(setHightlightedAttribute(attributesList[0])); 
    
            } else if (e.key === '#') { 

                dispatch(setAction('newNoteField')); 
                dispatch(setNewNoteFieldTitle('')); 
                dispatch(setNewNoteFieldSelectionStart(e.target.selectionStart)); 

            }; 

        }; 

    }; 

    // -- 

    useEffect(() => { 

        if (mode === 'resetText') { 

            handleTextReset(); 

        }; 

    },[mode]); 

    // -- 

    useEffect(() => { 

        if (action === 'attribute') { 

            setText(`${text}@`); 

        } else if (action === 'newAttribute') { 

            handleAttribute(); 

        } else if (action === 'newNoteField') { 

            setText(`${text}#`); 

        } else if (action === 'addNewNoteField') { 

            if (isExistingNoteFieldTitle) { 

                dispatch(setPopupAction('existingNoteField')); 
                dispatch(setOpenPopup()); 

            } else { 

                handleNewNoteField(); 

            }; 

        } else if (action === 'replaceNoteField') { 

            handleNewNoteField(); 

        } else if (action === 'resetNoteFieldCreation') { 

            resetNoteField(); 

        }; 

    },[action]); 

    // -- 

    useEffect(() => { 

        if (action === 'attribute') { 

            setText(text.replace(`@${searchQuery}`, `@${manualAttributeSearchQuery}`)); 
            dispatch(setSearchQuery(manualAttributeSearchQuery)); 

        }; 

    },[manualAttributeSearchQuery]); 

    // -- 

    useEffect(() => { 

        if (searchQuery) { 

            const updatedAttributesList = attributesList.filter((attribute) => { 
                        
                const lowercasedAttribute = attribute.toLocaleLowerCase(); 
                const lowercasedQuery = searchQuery.toLocaleLowerCase(); 
                
                if (lowercasedAttribute.includes(lowercasedQuery)) { 
    
                    return attribute; 
    
                }; 
    
            }); 
    
            dispatch(setAttributesQueryList(updatedAttributesList)); 
    
            if (updatedAttributesList.length > 0) { 
    
                dispatch(setHightlightedAttribute(updatedAttributesList[0])); 
    
            }; 

        } else { 

            dispatch(setAttributesQueryList(attributesList)); 

        }; 


    },[searchQuery]); 

    // --

    useEffect(() => { 

        if (consultationNoteId) { 

            handleInitialState(); 

        }; 

    },[consultationNoteId]); 

    // -- 

    useEffect(()=>{

        let myInterval = setInterval(() => {

            if (timeLeft > 0) {

                setTimeLeft(timeLeft - 1);

            }; 

            if (timeLeft === 1) { 

                getTextUpdate(); 

                setTimeLeft(2); 

            }; 

            if (timeLeft === 0) {

                if (timeLeft === 0) {
                    clearInterval(myInterval)
                };
            }; 

        }, 500); 

        return ()=> {
            clearInterval(myInterval);
        };

    },[timeLeft]);

    // -- 
    
    return (
      
      <Box 
        sx={{ 
            display: 'flex',
            flexDirection: 'column',
            width: '100%', 
            height: '100%', 
            cursor: 'default',
        }}
        >
        
            <MuiTextField
                id="consultationNote-textField"
                variant="standard"
                value={text}
                autoFocus
                autoComplete='off'
                multiline={true}
                sx={{ 
                    marginTop: 5, 
                    marginLeft: '40px', 
                    height: '860px', 
                    width: '560px',
                }}
                inputProps={{ 
                    style: { 
                        textAlign: 'left',
                        textDecorationLine: 'none',
                        fontSize: 12, 
                        color: colors?.text,
                        fontWeight: 'normal', 
                        fontStyle: 'normal', 
                        maxHeight: '820px', 
                        width: '560px',
                    }
                }}
                InputProps={{
                    disableUnderline: true, 
                }}
                onChange={onTextChange}
                onKeyDown={onKeyChange} 
            />

      </Box>

  );

};

export default Text;