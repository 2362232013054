import React, { useState, useEffect, useRef } from 'react';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

// Components 

import Box from '@mui/material/Box';
import Video from './video/Video';
import Upload from './upload/Upload';

const Container: React.FC = () => {

    const dispatch = useAppDispatch(); 
    const { t } = useTranslation(); 
    const navigate = useNavigate(); 

    const colors = useAppSelector(state => state.theme.colors); 

    const section = useAppSelector(state => state.camera.section); 

    return (

        <Box sx={{ 
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center', 
            width: '100vw',
            height: '100vh', 
            overflow: 'hidden', 
            position: 'absolute',
            color: colors?.text,
            backgroundColor: colors?.container
        }}>

            {section === 'video' && <Video />}

            {section === 'upload' && <Upload />}

      </Box>

  );

};

export default Container;