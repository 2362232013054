import React from 'react';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import { getDoctorPreferences, updateDoctorPreferences } from '../../../routes/doctor/patients';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { setLanguage } from '../../../redux/features/doctor/Account/preferences';


const LanguageSelector: React.FC = () => {

  const dispatch = useAppDispatch(); 
  const { i18n } = useTranslation();

  const colors = useAppSelector(state => state.theme.colors); 
  const accountInfo = useAppSelector(state => state.meta.accountInfo); 

  const [languageTitle, setLanguageTitle] = useState(''); 
  const [isDropdownOpened, setIsDropdownOpened] = useState(false); 

  const handleChange = async (newLanguage: string) => {

    i18n.changeLanguage(newLanguage);

    const content = { 
      language: newLanguage
    }; 

    await updateDoctorPreferences(content); 

  };

  // -- 

  const handlePreferences = async () => { 

    const res = await getDoctorPreferences() as any; 

    if (res.data.status === 200) { 

      dispatch(setLanguage(res?.data.doctorPreferences.language)); 

      i18n.changeLanguage(res?.data.doctorPreferences.language);

    }; 

  }; 

  // -- 

  useEffect(() => { 

    if (i18n) { 

      if (i18n.language === 'en-US') { 

        setLanguageTitle('English'); 

      } else if (i18n.language === 'fr') { 

        setLanguageTitle('Français'); 

      };

    }; 

  },[i18n.language]); 

  // -- 

  useEffect(() => { 

    if (accountInfo) { 

      handlePreferences(); 

    }; 

  },[accountInfo]); 

  // -- 

  return (

    <Box
      sx={{ 
        display: 'flex', 
        flexDirection: 'row',
        alignItems: 'center', 
        marginLeft: 3, 
        cursor: 'pointer', 
        backgroundColor: colors?.lightBlue,
        padding: 1, 
        borderRadius: 2, 
        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.2)',
        color: 'white', 
      }}
    >
      
      {languageTitle}

      {isDropdownOpened ? 
      <KeyboardArrowUpIcon onClick={() => setIsDropdownOpened(!isDropdownOpened)} sx={{ color: 'white', marginLeft: 2 }} />:
      <KeyboardArrowDownIcon onClick={() => setIsDropdownOpened(!isDropdownOpened)} sx={{ color: 'white', marginLeft: 2 }} />}

      <Box
          sx={{ 
              display: 'flex', 
              flexDirection: 'column',
              alignItems: 'center', 
              justifyContent: 'space-evenly',
              width: 200,
              height: isDropdownOpened ? 40 : 0,
              position: 'absolute',
              backgroundColor: colors?.fileExplorerDropdown,
              boxShadow: '0 4px 6px rgba(0, 0, 0, 0.2)',
              borderRadius: 1,
              marginTop: 15, 
              transition: 'height 0.3s',
              '&:hover': { backgroundColor: colors?.sectionHover}
          }}
      >

          {isDropdownOpened && 
          
          <>
              
              { i18n.language === 'en-US' && 
              <Box
                  onClick={() => handleChange('fr')}
                  sx={{ 
                      display: 'flex', 
                      flexDirection: 'row',
                      alignItems: 'center',
                      width: '100%', 
                      height: 40,
                      fontSize: 12,  
                      borderRadius: 1,
                      marginLeft: 4, 
                      cursor: 'pointer', 
                      color: colors.text, 
                  }}
              >{'Français'}
              </Box>}

              { i18n.language === 'fr' &&
              <Box
                  onClick={() => handleChange('en-US')}
                  sx={{ 
                      display: 'flex', 
                      flexDirection: 'row',
                      alignItems: 'center',
                      width: '100%', 
                      height: 40,
                      fontSize: 12,  
                      borderRadius: 1,
                      marginLeft: 4, 
                      cursor: 'pointer', 
                      color: colors.text, 
                  }}
              >{'English'}
              </Box>}

          </>} 

      </Box>

    </Box>
  );
};

export default LanguageSelector;
