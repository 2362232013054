import React, { useState, useEffect, useRef } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../../redux/hooks';
import { useTranslation } from 'react-i18next';

// Components 

import Box from '@mui/material/Box';
import ShortcutRow from './ShortcutRow';

const Shortcuts: React.FC = () => {

    const dispatch = useAppDispatch(); 
    const { t } = useTranslation(); 

    const colors = useAppSelector(state => state.theme.colors); 
    const sidebarSection = useAppSelector(state => state.consultationNote.sidebarSection); 
    const type = useAppSelector(state => state.consultationNote.type); 

    return (

      <Box sx={{ 
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center', 
          width: '100%',
          height: 'auto', 
      }}>

        <Box
          sx={{ 
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center', 
            marginBottom: 1, 
          }}
        >{t('inTextShortcuts')}
        </Box>

        <ShortcutRow title={(type === 'template') ? 'saveTemplate' : 'saveNote'} description={'CTRL + ALT + S'} />

        <ShortcutRow title={'newField'} description={'#'} />

        <ShortcutRow title={'newAttribute'} description={'@'} />

        <ShortcutRow title={'saveField'} description={'Enter'} />

        <ShortcutRow title={'locateMicrophone'} description={'CTRL + ALT + SPACE'} />

        <ShortcutRow title={'openClosePanel'} description={'CTRL + ALT + B'} />

      </Box>

    );

};

export default Shortcuts;