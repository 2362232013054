import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { useAppDispatch, useAppSelector } from '../../../../../../../redux/hooks';

import ActionBox from './ActionBox';
import EventNoteIcon from '@mui/icons-material/EventNote';
import AddToDriveOutlinedIcon from '@mui/icons-material/AddToDriveOutlined';
import { useTranslation } from 'react-i18next';
import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined';
import TemplatesList from '../templates/TemplatesList';
import TemplatesHeader from '../templates/TemplatesHeader';


const Actions: React.FC = () => {

    const dispatch = useAppDispatch(); 
    const { t } = useTranslation(); 

    const colors = useAppSelector(state => state.theme.colors); 

    const templates = useAppSelector(state => state.consultation.templates); 

    return (

        <Box             
            sx={{ 
                display: 'flex', 
                flexDirection: 'column',
                width: '100%',
                height: 'auto', 
                paddingLeft: '2%',
                overflowY: 'scroll',
                scrollbarWidth: 'none',
                marginBottom: 1, 
            }}>

                <Box
                    sx={{ 
                        display: 'flex',
                        flexDirection: 'row', 
                        alignItems: 'center',
                        width: '100%', 
                    }}
                >

                    <ActionBox 
                        title={'Note'} 
                        section={'consultationNote'}
                        data={'Add a new note to your consultation.'} 
                        hoverColor={colors?.consultationNoteHover}
                        backgroundColor={colors?.consultationNote}>
                        <Box component="img" 
                            sx={{ 
                                width: 25, 
                                }} 
                            src={require('../../../../../../../assets/icons/consultation/notes.png')} 
                        />
                    </ActionBox>

                    <ActionBox 
                        title={'Template'} 
                        section={'template'}
                        data={'Create a template for future notes.'} 
                        hoverColor={colors?.templateBannerHover}
                        backgroundColor={colors?.templateBanner}>
                        <Box component="img" 
                            sx={{ 
                                width: 25, 
                                }} 
                            src={require('../../../../../../../assets/icons/consultation/template.png')} 
                        />
                    </ActionBox>

                </Box>

                <Box
                    sx={{ 
                        display: 'flex',
                        flexDirection: 'row', 
                        alignItems: 'center',
                        width: '100%', 
                        marginTop: 1, 
                        marginBottom: 2, 
                    }}
                >

                    <ActionBox 
                        title={String(t('attachFile'))} 
                        section={'attachFile'}
                        data={'Link an existing file to your consultation.'} 
                        hoverColor={colors?.attachFileHover}
                        backgroundColor={colors?.attachFile}>
                        <Box component="img" 
                            sx={{ 
                                width: 25, 
                                }} 
                            src={require('../../../../../../../assets/icons/consultation/attach-file.png')} 
                        />
                    </ActionBox>

                    <ActionBox 
                        title={'Camera'} 
                        section={'camera'}
                        data={'Upload pictures in real-time with your phone.'} 
                        hoverColor={colors?.cameraBannerHover}
                        backgroundColor={colors?.cameraBanner}>
                        <Box component="img" 
                            sx={{ 
                                width: 25, 
                                }} 
                            src={require('../../../../../../../assets/icons/consultation/take-picture.png')} 
                        />
                    </ActionBox>

                </Box>

                <TemplatesHeader />

                <TemplatesList />

        </Box>

    );

};

export default Actions;