import React from 'react';
import { Container, Box, Typography, Link, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import LanguageSelector from '../components/general/navBar/LanguageSelector';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import Logo from '../components/general/navBar/Logo';
import ThemeToggle from '../components/general/navBar/ThemeToggle';
import Footer from '../components/general/navigation/Footer';
import { useAppSelector } from '../redux/hooks';
import NavBar from '../components/general/navBar/NavBar';
import Settings from '../components/doctor/navigation/navBar/Settings';

const Privacy: React.FC = () => {

  const { t, ready } = useTranslation();
  const navigate = useNavigate();
  const theme = useTheme();

  if (!ready) {
    return <div>Loading...</div>;
  }

  const privacyContent = t('privacyContent', { returnObjects: true }) as any;

  const colors = useAppSelector(state => state.theme.colors); 

  const paragraphStyling = { 
    marginBottom: 2,
    color: colors?.text
  };

  const titleStyling = { 
    marginBottom: 2, 
    color: colors?.legalHeader
  };

  const subTitleStyling = { 
    marginBottom: 2, 
    color: colors?.legalSubTitle
  }

  return (
    <Box
      component="main"
      maxWidth="xs"
      sx={{
        display: 'flex', 
        flexDirection: 'column', 
        alignItems: 'center', 
        height: '100vh', 
        width: '100vw',
        left: 0, 
        overflowX: 'hidden',
        position: 'absolute',
        color: colors?.highlightedText,
        backgroundColor: colors?.interface
    }}
    >

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems:'center',
          width: '50%',
          height: 'auto', 
          marginTop: '10vh', 
          borderRadius: 4, 
          padding: 10, 
          marginBottom: 10, 
          backgroundColor: colors?.container,
          color: colors?.text,
          boxShadow: '0 4px 6px rgba(0, 0, 0, 0.2)',
        }}
      >

        <Box sx={{ mb: 2 }}>
          <Logo size={30} />
        </Box>
        <Typography component="h1" variant="h5" sx={{ mb: 2, color: theme.palette.mode === 'dark' ? '#fff' : '#111' }}>
          {t('privacyPolicy')}
        </Typography>

        <Box sx={{ textAlign: 'justify', width: '100%', marginBottom: 4 }}>
          <Typography variant="body1" sx={paragraphStyling}>
            <p>{privacyContent?.introduction}</p>
          </Typography>

          <Typography variant="h6" sx={titleStyling}>
            {privacyContent?.dataCollection?.title}
          </Typography>
          <Typography variant="body1" sx={subTitleStyling}>
            <p>{privacyContent?.dataCollection?.description}</p>
          </Typography>
          <Box sx={{ pl: 4 }}>
            <ul>
              {privacyContent?.dataCollection?.healthcareProfessionals && (
                <li>{privacyContent.dataCollection.healthcareProfessionals}</li>
              )}
              {privacyContent?.dataCollection?.insurers && <li>{privacyContent.dataCollection.insurers}</li>}
            </ul>
          </Box>

          <br />

          <Typography variant="h6" sx={titleStyling}>
            {privacyContent?.dataUsage?.title}
          </Typography>
          <Typography variant="body1" sx={subTitleStyling}>
            <p>{privacyContent?.dataUsage?.description}</p>
          </Typography>
          <Box sx={{ pl: 4 }}>
            <ul>
              {privacyContent?.dataUsage?.usage1 && <li>{privacyContent.dataUsage.usage1}</li>}
              {privacyContent?.dataUsage?.usage2 && <li>{privacyContent.dataUsage.usage2}</li>}
              {privacyContent?.dataUsage?.usage3 && <li>{privacyContent.dataUsage.usage3}</li>}
            </ul>
          </Box>

          <br />

          <Typography variant="h6" sx={titleStyling}>
            {privacyContent?.dataSecurity?.title}
          </Typography>
          <Typography variant="body1" sx={subTitleStyling}>
            <p>{privacyContent?.dataSecurity?.description}</p>
          </Typography>
          <Box sx={{ pl: 4 }}>
            <ul>
              {privacyContent?.dataSecurity?.security1 && <li>{privacyContent.dataSecurity.security1}</li>}
              {privacyContent?.dataSecurity?.security2 && <li>{privacyContent.dataSecurity.security2}</li>}
              {privacyContent?.dataSecurity?.security3 && <li>{privacyContent.dataSecurity.security3}</li>}
            </ul>
          </Box>

          <br />

          <Typography variant="h6" sx={titleStyling}>
            {privacyContent?.dataSharing?.title}
          </Typography>
          <Typography variant="body1" sx={paragraphStyling}>
            <p>{privacyContent?.dataSharing?.description}</p>
          </Typography>

          <Typography variant="h6" sx={titleStyling}>
            {privacyContent?.userRights?.title}
          </Typography>
          <Typography variant="body1" sx={subTitleStyling}>
            <p>{privacyContent?.userRights?.description}</p>
          </Typography>
          <Box sx={{ pl: 4 }}>
            <ul>
              {privacyContent?.userRights?.rights1 && <li>{privacyContent.userRights.rights1}</li>}
              {privacyContent?.userRights?.rights2 && <li>{privacyContent.userRights.rights2}</li>}
              {privacyContent?.userRights?.rights3 && <li>{privacyContent.userRights.rights3}</li>}
            </ul>
          </Box>

          <br />

          <Typography variant="h6" sx={titleStyling}>
            {privacyContent?.dataRetention?.title}
          </Typography>
          <Typography variant="body1" sx={paragraphStyling}>
            <p>{privacyContent?.dataRetention?.description}</p>
          </Typography>

          <Typography variant="h6" sx={titleStyling}>
            {privacyContent?.cookiesAndTracking?.title}
          </Typography>
          <Typography variant="body1" sx={paragraphStyling}>
            <p>{privacyContent?.cookiesAndTracking?.description}</p>
          </Typography>

          <Typography variant="h6" sx={titleStyling}>
            {privacyContent?.changesToPrivacyPolicy?.title}
          </Typography>
          <Typography variant="body1" sx={paragraphStyling}>
            <p>{privacyContent?.changesToPrivacyPolicy?.description}</p>
          </Typography>

          <Typography variant="h6" sx={titleStyling}>
            {privacyContent?.contactInformation?.title}
          </Typography>
          <Typography variant="body1" sx={paragraphStyling}>
            <p>{privacyContent?.contactInformation?.description}</p>
          </Typography>

        </Box>

    </Box>

        <Footer isScrollPage={true} />

        <NavBar>
          <Settings />
        </NavBar>

    </Box>
  );
};

export default Privacy;