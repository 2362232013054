import React, { useState, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../../../../redux/hooks';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

// Components 

import Box from '@mui/material/Box';
import Section from './Section';
import SectionRow from './SectionRow';

const PatientDataList: React.FC = () => {

    const { t } = useTranslation(); 
    const dispatch = useAppDispatch(); 

    const colors = useAppSelector(state => state.theme.colors);

    const patient = useAppSelector(state => state.patient.patient); 
    const emergencyContacts = useAppSelector(state => state.patient.emergencyContacts); 

    return (

    <Box
        sx={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            padding: '2rem',
            maxHeight: '90vh',
            overflow: 'auto',
            alignItems: 'center',
            scrollbarWidth: 'none',
        }}
    >

        <Section title={'patientUniqueId'}>

            <SectionRow title={'patientId'} description={patient?._id} copyEnabled>
                <Box component="img" sx={{ width: 20 }} src={require('../../../../../../../assets/logos/emblem.png')} />
            </SectionRow>

        </Section>

        <Section title={'patientInformation'}>

            <SectionRow title={'firstName'} description={patient?.firstName}>
                <Box component="img" sx={{ width: 20 }} src={require('../../../../../../../assets/icons/patient/firstName.png')} />
            </SectionRow>

            <SectionRow title={'lastName'} description={patient?.lastName}>
                <Box component="img" sx={{ width: 20 }} src={require('../../../../../../../assets/icons/patient/lastName.png')} />
            </SectionRow>

            <SectionRow title={'sex'} description={patient?.gender}>
                <Box component="img" sx={{ width: 20 }} src={require('../../../../../../../assets/icons/patient/sex.png')} />
            </SectionRow>

            <SectionRow title={'dateOfBirth'} description={moment.utc(patient?.dateOfBirth).format('L')}>
                <Box component="img" sx={{ width: 20 }} src={require('../../../../../../../assets/icons/patient/dateOfBirth.png')} />
            </SectionRow>
        
        </Section>

        <Section title='contactInfo'>

            <SectionRow title={'healthCardNumber'} description={patient?.healthCardNumber}>
                <Box component="img" sx={{ width: 20 }} src={require('../../../../../../../assets/icons/patient/healthCardNumber.png')} />
            </SectionRow>

            <SectionRow title={'phone'} description={patient?.contactInfo.phone}>
                <Box component="img" sx={{ width: 20 }} src={require('../../../../../../../assets/icons/patient/phone.png')} />
            </SectionRow>

            <SectionRow title={'address'} description={patient?.contactInfo.address}>
                <Box component="img" sx={{ width: 20 }} src={require('../../../../../../../assets/icons/patient/address.png')} />
            </SectionRow>

            <SectionRow title={'email'} description={patient?.contactInfo.email}>
                <Box component="img" sx={{ width: 20 }} src={require('../../../../../../../assets/icons/patient/email.png')} />
            </SectionRow>

        </Section>

        { emergencyContacts?.length > 0 &&
        
            <Section title='emergencyContact'>

                {emergencyContacts?.map((item, index) => (

                    <>

                    <SectionRow title={'emergencyContactName'} description={item.name}>
                        <Box component="img" sx={{ width: 20 }} src={require('../../../../../../../assets/icons/patient/name.png')} />
                    </SectionRow>

                    <SectionRow title={'emergencyContactRelation'} description={item.relation}>
                        <Box component="img" sx={{ width: 20 }} src={require('../../../../../../../assets/icons/patient/relation.png')} /> 
                    </SectionRow>

                    <SectionRow title={'emergencyContactPhone'} description={item.phone}>
                        <Box component="img" sx={{ width: 20 }} src={require('../../../../../../../assets/icons/patient/phone.png')} />
                    </SectionRow>

                    </>
    
                ))}

            </Section>}
    
        </Box>

  );
};

export default PatientDataList;