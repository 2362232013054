import React, { useState, useEffect, useRef } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import { useTranslation } from 'react-i18next';

// Components 

import Box from '@mui/material/Box';
// import SidebarItem from './SidebarItem';
import NavigationItem from './NavigationItem';
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';
import TextFieldsIcon from '@mui/icons-material/TextFields';
import KeyboardCommandKeyIcon from '@mui/icons-material/KeyboardCommandKey';

const Navigation: React.FC = () => {

    const dispatch = useAppDispatch(); 
    const { t } = useTranslation(); 

    const colors = useAppSelector(state => state.theme.colors);
    const isSidebarOpened = useAppSelector(state => state.consultationNote.isSidebarOpened);
    const type = useAppSelector(state => state.consultationNote.type); 

    return (

      <Box sx={{ 
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center', 
          width: '5vw',
          height: '90vh',
          position: 'absolute', 
          paddingTop: 5, 
          boxShadow: isSidebarOpened ? '0 4px 6px rgba(0, 0, 0, 0.2)' : '',
          backgroundColor: colors?.consultationNoteSideBar, 
          left: 0, 
      }}>

        {(type !== 'template') && 
        <NavigationItem title={'Code'} section={'code'}>
          <QrCodeScannerIcon  sx={{ fontSize: 35 }} />
        </NavigationItem>}

        <NavigationItem title={'Fields'} section={'fields'}>
          <TextFieldsIcon  sx={{ fontSize: 35 }} />
        </NavigationItem>

        <NavigationItem title={'Shortcuts'} section={'shortcuts'}>
          <KeyboardCommandKeyIcon  sx={{ fontSize: 35 }} />
        </NavigationItem>

      </Box>

  );

};

export default Navigation;