import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import { useAppDispatch, useAppSelector } from '../../../../../../../redux/hooks';
import { useTranslation } from 'react-i18next';
import { createNoteWithTemplate, getNoteTemplates } from '../../../../../../../routes/doctor/consultation';
import { setSelectedTemplate, setTemplateOptionsXPosition, setTemplateOptionsYPosition, setTemplates } from '../../../../../../../redux/features/doctor/Dashboard/consultation/consultation';
import { useNavigate } from 'react-router-dom';
import Template from './Template';
import { setClosePopup, setOpenPopup, setPopupAction } from '../../../../../../../redux/features/general/popup';
import BlankTemplate from './BlankTemplate';

// Components

const TemplatesList: React.FC = () => {

    const { t } = useTranslation();
    const dispatch = useAppDispatch(); 
    const navigate = useNavigate(); 

    const colors = useAppSelector(state => state.theme.colors);
    const consultationId = useAppSelector(state => state.consultation.consultationId); 
    const templates = useAppSelector(state => state.consultation.templates); 
    const selectedTemplate = useAppSelector(state => state.consultation.selectedTemplate); 

    const [timeLeft, setTimeLeft] = useState(0); 
    const [templateRedirectionUrl, setTemplateRedirectionUrl] = useState(''); 
    const [showBlankTemplate, setShowBlankTemplate] = useState(true); 

    // -- 

    const handleTemplates = async () => { 

        const res = await getNoteTemplates() as any; 

        if (res.data.status === 200) { 

            dispatch(setTemplates(res.data.noteTemplates)); 

            // (res.data.noteTemplates.length === 0) ? setShowBlankTemplate(true) : setShowBlankTemplate(false); 

        }; 

    }; 

    // -- 

    const handleSelection = async (e: any, item: any) => { 

        if (e.detail === 2) { 

            dispatch(setSelectedTemplate('')); 

            const res = await createNoteWithTemplate(item._id, consultationId) as any; 

            if (res.data.status === 200) { 

                setTimeLeft(3); 

                dispatch(setPopupAction('creatingNote')); 
                dispatch(setOpenPopup()); 

                setTemplateRedirectionUrl(`/consultation/note/${consultationId}/${res.data.consultationNote._id}`); 

            }; 

        } else if (e.detail === 1) { 

            if (selectedTemplate?._id == item._id) { 

                dispatch(setSelectedTemplate('')); 

            } else { 

                dispatch(setSelectedTemplate(item)); 

                dispatch(setTemplateOptionsXPosition(e.clientX)); 
                dispatch(setTemplateOptionsYPosition(e.clientY)); 

            }; 

        }; 

    }; 

    // -- 

    const handleMouse = (e: any, item: any) => { 

        if (e.button == 2) { 

            if (selectedTemplate?._id == item._id) { 

                dispatch(setSelectedTemplate('')); 

            } else { 

                dispatch(setSelectedTemplate(item)); 

                dispatch(setTemplateOptionsXPosition(e.clientX)); 
                dispatch(setTemplateOptionsYPosition(e.clientY)); 

            }; 

        }; 

    }; 

    // -- 

    useEffect(() => { 

        if (consultationId) { 

            handleTemplates(); 

        }; 

    },[consultationId]);

    // -- 

    useEffect(()=>{

        let myInterval = setInterval(() => {

            if (timeLeft > 0) {

                setTimeLeft(timeLeft - 1);

            }; 

            if (timeLeft === 1) { 

                dispatch(setClosePopup()); 

                navigate(templateRedirectionUrl); 

            }; 

            if (timeLeft === 0) {

                if (timeLeft === 0) {
                    clearInterval(myInterval)
                };
            }; 

        }, 500); 

        return ()=> {
            clearInterval(myInterval);
        };

    },[timeLeft]);

    // --

    return (

        <Box
            sx={{
                display: 'flex',
                flexWrap: 'wrap',
                flexDirection: 'row',
                overflowWrap: 'normal',
                width: '100%',
                height: 'auto', 
                scrollbarWidth: 'none',
                marginBottom: 4, 
            }}
        >

            <BlankTemplate />

            {templates?.map((item, index) => (
                                
                <Box 
                    onClick={(e) => handleSelection(e, item)} 
                    onMouseDown={(e) => handleMouse(e, item)} 
                    key={index} 
                >
                    <Template template={item} /> 

                </Box>

            ))}

        </Box>

    );
};

export default TemplatesList;