import React, { useState, useEffect, useRef } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../../redux/hooks';
import { useTranslation } from 'react-i18next';

// Components 

import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import SearchIcon from '@mui/icons-material/Search';
import { setFieldSearchQuery, setIsRefreshFieldsList } from '../../../../../redux/features/doctor/Dashboard/consultation/consultationNote';
import { editConsultationNote } from '../../../../../routes/doctor/consultation';

const Header: React.FC = () => {

    const dispatch = useAppDispatch(); 
    const { t } = useTranslation(); 

    const colors = useAppSelector(state => state.theme.colors);
    const consultationNoteId = useAppSelector(state => state.consultationNote.consultationNoteId); 
    const securityPin = useAppSelector(state => state.consultationNote.securityPin); 
    const mode = useAppSelector(state => state.consultationNote.mode); 
    const noteFields = useAppSelector(state => state.consultationNote.noteFields); 
    const type = useAppSelector(state => state.consultationNote.type); 

    const [text, setText] = useState(''); 

    const [headerColor, setHeaderColor] = useState(colors?.lightBlue); 
    const [headerTitle, setHeaderTitle] = useState(''); 

    // -- 

    const handleSearch = (e: any) => { 

        setText(e.target.value); 

        if (e.target.value) { 

            dispatch(setFieldSearchQuery(e.target.value)); 

        } else { 

            dispatch(setIsRefreshFieldsList(true)); 

        }; 

    }; 

    // -- 

    const handleAutoComplete = async () => { 

        if (noteFields.length > 0) { 

            if (mode === 'autoComplete') { 

                const content = { 
                    consultationNoteId: consultationNoteId, 
                    mode: 'writing', 
                    securityPin: securityPin, 
                }; 

                await editConsultationNote(content); 

            } else { 

                const content = { 
                    consultationNoteId: consultationNoteId, 
                    mode: 'autoComplete', 
                    securityPin: securityPin, 
                }; 

                await editConsultationNote(content); 

            };

        }; 

    }; 

    // -- 

    useEffect(() => { 

        if (noteFields.length > 0) { 

            if (mode === 'autoComplete') { 

                setHeaderColor(colors?.microphoneEnabled); 
                setHeaderTitle(String(t('stopAutoComplete'))); 

            } else { 

                setHeaderColor(colors?.green); 
                setHeaderTitle(String(t('startAutoComplete'))); 

            };

        } else { 

            setHeaderColor(colors?.lightBlue); 
            setHeaderTitle(String(t('newNoteFieldInfo'))); 

        }; 

    },[noteFields, mode]); 

    // -- 

    return (

      <Box sx={{ 
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center', 
          width: '100%',
          height: 'auto',
      }}>

        { (type !== 'template') && 
        <Box 
            onClick={handleAutoComplete}
            sx={{ 
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center', 
            justifyContent: 'space-evenly', 
            width: '95%',
            borderRadius: 3,
            marginTop: 1,  
            paddingTop: 1, 
            paddingBottom: 1, 
            marginBottom: 1, 
            height: 40, 
            fontSize: 12, 
            color: 'white',
            cursor: (noteFields?.length > 0) ? 'pointer' : 'default', 
            backgroundColor: headerColor,
            boxShadow: '0 4px 6px rgba(0, 0, 0, 0.2)',
        }}>{headerTitle}</Box>}

        <Box sx={{ 
            display: 'flex', 
            flexDirection: 'row',
            alignItems: 'center',
            width: '95%',
            borderRadius: 3,
            paddingTop: 1, 
            paddingBottom: 1, 
            marginBottom: 1, 
            height: 40, 
            backgroundColor: colors?.fileExplorerSearchBar,
            boxShadow: '0 2px 3px rgba(0, 0, 0, 0.1)',
        }}>
            <SearchIcon sx={{ fontSize: 12, marginLeft: 2, color: colors?.text }}/>
            <TextField
                id="consultation-note-fields-search"
                placeholder={String(t('findField'))}
                type="search"
                variant="standard"
                value={text}
                InputProps={{
                    style: {fontSize: 12, color: colors?.text },
                    disableUnderline: true, 
                }}
                onChange={handleSearch}
                sx={{
                    marginLeft: 2,
                    fontSize: 12,
                    width: '80%',
                    borderBottomColor: colors?.fileExplorerSearchBar
                }}
            />
        </Box>


      </Box>

  );

};

export default Header;