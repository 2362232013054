import React, { useState, useEffect, useRef } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../../../../redux/hooks';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

// Components 

import Box from '@mui/material/Box';

const TemplatesHeader: React.FC = () => {

    const dispatch = useAppDispatch(); 
    const { t } = useTranslation(); 
    const navigate = useNavigate(); 

    const colors = useAppSelector(state => state.theme.colors); 

    return (

      <Box sx={{ 
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center', 
          width: '100%',
          height: 'auto', 
          paddingLeft: 2,
          paddingRight: 3,
          marginTop: 2, 
      }}>

        <Box
            sx={{
                display: 'flex', 
                flexDirection: 'row',
                alignItems: 'center',
                width: '100%', 
                marginBottom: 2, 
                fonSize: 11, 
            }}
        >{t('myTemplates')}

        </Box>

        <Box
            sx={{
                display: 'flex', 
                flexDirection: 'row',
                alignItems: 'center',
                height: 1, 
                width: '100%',
                borderBottomWidth: 2, 
                borderBottomColor: 'rgb(224, 224, 224)',
                borderBottomStyle: 'solid', 
                marginBottom: 3, 

            }}
        >
        </Box>


      </Box>

  );

};

export default TemplatesHeader;