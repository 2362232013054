

const menuItems = [

    { type: 'item', section: 'overview', text: 'data', iconName: 'bubble_chart', iconColor: '#FFFFFF', key:'i-overview' },
    { type: 'item', section: 'doctors', text: 'doctors', iconName: 'groups', iconColor: '#FFFFFF', key:'i-doctors' },
    { type: 'item', section: 'patients', text: 'patients', iconName: 'folder_shared', iconColor: '#FFFFFF', key: 'i-patients'}, 
    { type: 'item', section: 'supportTickets', text: 'tickets', iconName: 'toll', iconColor: '#FFFFFF', key:'i-tickets' },
    { type: 'item', section: 'hackers', text: 'hackers', iconName: 'security', iconColor: '#FFFFFF', key:'i-hackers' },

];

export default menuItems; 