import React, { useState, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../../redux/hooks';
import { useTranslation } from 'react-i18next';

// Components 

import Box from '@mui/material/Box';
import TollIcon from '@mui/icons-material/Toll';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import DownloadIcon from '@mui/icons-material/Download';
import LogoutIcon from '@mui/icons-material/Logout';
import { resetConsultation } from '../../../../../redux/features/doctor/Dashboard/consultation/consultation';
import ActionsRow from './header/ActionsRow';
import { setOpenPopup, setPopupAction } from '../../../../../redux/features/general/popup';
import { saveConsultation } from '../../../../../routes/doctor/consultation';
import { setNotificationMessage } from '../../../../../redux/features/general/notification';
import CircularProgress from '@mui/material/CircularProgress';
import { useNavigate } from 'react-router-dom';
import { resetSavedConsultation, setPatientId } from '../../../../../redux/features/doctor/Dashboard/consultation/savedConsultation';
import { resetFileExplorer, setMode } from '../../../../../redux/features/doctor/Dashboard/documents/fileExplorer';
import { setDashboardSection } from '../../../../../redux/features/general/navigation';
import { display } from 'html2canvas/dist/types/css/property-descriptors/display';

const Header: React.FC = () => {

    const { t } = useTranslation();
    const navigate = useNavigate(); 
    const dispatch = useAppDispatch(); 

    const colors = useAppSelector(state => state.theme.colors);
    const patientId = useAppSelector((state) => state.consultation.patientId);  
    const patientName = useAppSelector(state => state.consultation.patientName); 
    const consultationId = useAppSelector(state => state.consultation.consultationId); 
    const notes = useAppSelector(state => state.consultation.notes); 
    const images = useAppSelector(state => state.consultation.images); 
    const attachedFiles = useAppSelector(state => state.consultation.attachedFiles); 

    const [isSavingEnabled, setIsSavingEnabled] = useState(false); 

    const [isSaving, setIsSaving] = useState(false); 

    // -- 

    const handleQuit = () => { 

        dispatch(setOpenPopup()); 
        dispatch(setPopupAction('quitConsultation')); 

    }; 

    // -- 

    const openConfirmationPopup = () => { 

        dispatch(setOpenPopup()); 
        dispatch(setPopupAction('saveConsultation')); 

    }; 

    // -- 

    useEffect(() => { 

        if ((notes?.length > 0) || (attachedFiles?.length > 0) || (images?.length > 0)) { 

            setIsSavingEnabled(true); 

        } else { 

            setIsSavingEnabled(false); 

        }; 

    },[notes, attachedFiles, images]); 

    // -- 

    return (

        <Box sx={{ 
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-evenly', 
            height: 100,
            marginBottom: 3, 
            width: '100%',
            fontSize: 12,     
            color: colors?.text,
            flexShrink: 0,
        }}>

            <Box
                sx={{ 
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center', 
                    justifyContent: 'space-between', 
                    width: '100%', 
                    paddingLeft: 4, 
                }}
            >

                <Box
                    sx={{ 
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center', 
                    }}
                >

                </Box>

                <Box
                    sx={{ 
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center', 
                    }}
                >
                    {isSaving && <CircularProgress size={15} />}

                    <Box
                        sx={{ 
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center', 
                        }}
                    >

                        <Box 
                            component="img" 
                            sx={{ 
                                width: 20, 
                                }} 
                            src={require('../../../../../assets/icons/consultation/select-patient-avatar.png')} 
                        />

                        <Box sx={{ color: colors?.text, marginLeft: 1, marginRight: 1 }}>{patientName}</Box>

                    </Box>


                    <Box
                        onClick={openConfirmationPopup}
                        // onClick={handleSaveConsultation}
                        sx={{ 
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center', 
                            padding: 1, 
                            fontSize: 12, 
                            marginLeft: 3, 
                            borderRadius: 1, 
                            marginRight: 2, 
                            height: 30,
                            color: colors?.text, 
                            cursor: isSavingEnabled ? 'pointer' : 'default', 
                            boxShadow: '0 4px 6px rgba(0, 0, 0, 0.3)',
                            backgroundColor: isSavingEnabled ? colors?.navbarToolEnabled : colors?.navbarToolDisabled, 
                            '&:hover': { backgroundColor: isSavingEnabled ? colors?.navbarToolEnabledHover : colors?.navbarToolDisabled  }
                        }}
                    >

                        <Box>{'Save Consultation'}</Box>

                        <DownloadIcon sx={{ marginLeft: 1, fontSize: 16 }} /> 

                    </Box>

                    <Box
                        onClick={handleQuit}
                        sx={{ 
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center', 
                            justifyContent: 'space-evenly', 
                            padding: 1, 
                            fontSize: 12, 
                            marginLeft: 1, 
                            borderRadius: 1, 
                            marginRight: 2, 
                            height: 30,
                            color: colors?.text, 
                            cursor: 'pointer', 
                            boxShadow: '0 4px 6px rgba(0, 0, 0, 0.3)',
                            backgroundColor: colors?.consultationQuit, 
                            '&:hover': { backgroundColor: colors?.consultationQuitHover }
                        }}
                    >

                        <LogoutIcon sx={{ fontSize: 16 }} /> 

                    </Box>


                </Box>

            </Box>

            <ActionsRow /> 

        </Box>
    );
};

export default Header;