// To use the theme on the interface 

// const colors = useAppSelector(state => state.theme.colors);

// color: colors?.myColor            (don't forget the '?'). 


const lightTheme = { 

    // Theme

    theme: 'light', 

    // Backgrounds 

    interface: 'rgb(235,234,235)', 
    container: 'rgb(255,255,255)',
    legend: 'rgb(232,234,246)',
    text: 'rgb(51, 51, 51)',
    highlightedText: 'rgb(21, 21, 21)', 
    searchBar: 'rgb(215,214,245)', 
    blueButton: 'rgb(54,69,126)', 
    disabledIcon: 'rgb(121,124,128)', 
    disabledRelatedPatient: 'rgba(194, 197, 200, 0.5)',
    newFolderIcon: 'rgb(60,179,179)',
    uploadFileIcon: 'rgb(34,148,6)',
    relatedPatientIcon: 'rgb(127,52,235)',
    refreshIcon: 'rgb(60,179,179)',
    changeViewIcon: 'rgb(147,47,162)',
    fileExplorerSearchBar: 'rgb(215,214,245)', 
    selectedSibebarSection: 'rgb(60,179,179)',
    correctFileIcon: 'rgb(60,179,179)', 
    renameFileIcon: 'rgb(34, 148, 6)',
    moveToIcon: 'rgb(30, 144, 255)',
    patientInfoIcon: 'rgb(127, 52, 235)', 
    deleteIcon: 'rgb(201, 79, 42)', 
    viewFileIcon: 'rgb(201, 117, 42)', 
    actionBackground: 'rgb(255,255,255)', 
    removePinIcon: 'rgb(199, 131, 80)',
    pinFolderIcon: 'rgb(184, 160, 28)', 
    searchIcon: 'rgb(40, 40, 40)',

    sectionHover: 'rgb(232,234,246)', 
    selectedSectionHover: 'rgb(232,234,246)', 
    unselectedSection: 'rgb(255, 255, 255)', 

    // File Explorer folder data, 

    hiddenBox: 'rgba(46, 45, 46, 0.7)',
    hiddenRow: 'rgba(0, 255, 238, 0.1)',
    sharedFolder: 'rgb(34, 148, 6)',
    folderDataRowBorderColor: 'rgba(159, 171, 157, 0.6)', 

    searchQueryFile: 'rgb(60,179,179)',
    searchQueryFolder: 'rgb(30, 144, 255)', 

    // Correct File. 

    reviewIcon: 'rgb(34, 148, 6)',
    correction: 'rgb(215,214,245)', 
    correctionHoverQuit: 'rgba(127, 135, 135, 0.2)', 

    fileViewerQuit: 'rgba(77, 75, 89, 0.5)', 

    dropdown: 'rgb(215,214,245)',
    fileExplorerDropdown: 'rgb(215,214,245)', 
    fileExplorerFooter: 'rgb(215,214,245)', 

    // File Explorer: 

    recentFolderIcon: 'rgb(207,137,45)',
    patientsFolderIcon: 'rgba(10,124,225, 0.7)',
    downloadsFolderIcon: 'rgb(34,148,6)',
    documentsFolderIcon: 'rgb(60,179,179)', 
    pinIconColor: 'rgb(184, 160, 28)',
    legendCheckIcon: 'rgb(26, 255, 0)', 

    consultationFolder: 'rgb(30, 56, 172)', 
    folder: 'rgb(199,145,80)',
    file: 'rgb(227, 167, 0)', 

    listInfo: '#919191',

    closeButtonBackground: 'rgb(162,164,176)',
    closeButtonText: 'rgb(31, 31, 31)', 

    patientExitButton: 'rgb(107, 107, 107)',
    patientExitButtonBackground: 'rgb(135, 0, 0)',

    deleteUploadedFile: 'rgb(199, 119, 80)', 
    deleteUploadedFileHover: 'rgb(227, 96, 32)', 

    exitButtonColor: 'rgb(92, 0, 0)',
    exitButtonColorHover: 'rgb(135, 0, 0)',
    exitButtonText: 'rgb(187, 187, 187)',
    cancelButtonText: 'rgb(110, 110, 110)', 
    cancelButtonColor: 'rgb(177, 177, 177)',  
    cancelButtonBackground: 'rgb(162,164,176)',

    updatePatientDetails: 'rgba(10,124,225, 0.7)' ,
    confirmPatientInfoText: 'rgb(150, 150, 150)',

    emergencyContacts: 'rgb(232,234,246)',

    // File Explorer Footer

    driveIcon: 'rgb(255,185,0)',
    accountFooterIcon: 'rgb(175, 101, 230)',

    directory: 'rgb(60,179,179)',

//  const colors = useAppSelector(state => state.theme.colors);

    createPatientContainer: 'rgb(56, 58, 64)',
    createPatientDisabledIcon: 'rgb(232,234,246)',
    createPatientEnabledIcon: 'rgb(232,234,246)',
    createPatientRequired: 'rgb(252, 118, 50)', 
    createPatientSelect: 'rgba(10,124,225, 0.7)',
    createPatientError: 'rgb(252, 118, 50)',
    createPatientOptionsList: 'rgb(232,234,246)',
    createPatient: 'rgba(10,124,225, 0.7)',

    completedCategory: 'rgb(42, 209, 42)',
    incompletedCategory: 'rgb(174, 174, 174)',
    incompletedCategoryHover: 'rgb(204, 107, 10)', 
    erroredCategory: 'rgba(194, 0, 3, 0.7)',
    erroredCategoryHover: 'rgba(210, 0, 3, 0.7)', 

    requiredFieldsWarning: 'rgb(122, 133, 133)',
    patientContainer: 'rgb(56, 58, 64)',

    listHeaderBackground: 'rgb(232,234,246)',

    optionContainer: 'rgb(52, 53, 58)', 

    directiveIcon: 'rgb(34, 148, 6)',
    createDirective: 'rgba(10,124,225, 0.7)', 
    directiveAlertIcon: 'rgb(207, 137, 45)', 

    noteIcon: 'rgb(34, 148, 6)',
    createNote: 'rgba(10,124,225, 0.7)', 
    createNoteHover: 'rgba(170, 177, 179, 0.2)',
    noteDescription: 'rgb(157, 159, 163)', 
    noteDropdown: 'rgb(82,84,89)',
    noteDropdownHover: 'rgb(92 , 94 , 99)', 
    
    notificationsContainer: '#2B2D31',
    
    calendarBackground: 'rgba(207, 137, 45, 0.2)',
    calendarHover: 'rgba(207, 137, 45, 0.1)',
    calendarIcon: 'rgba(207, 137, 45, 0.8)',

    notificationsBackground: 'rgba(45, 207, 62, 0.2)', 
    notificationsHover: 'rgba(45, 207, 62, 0.1)',
    notificationsIcon: 'rgba(45, 207, 62, 0.8)',

    accessRequestsBackground: 'rgba(188, 45, 207, 0.2)',
    accessRequestsHover: 'rgba(188, 45, 207, 0.1)', 
    accessRequestsIcons: 'rgba(188, 45, 207, 0.8)',
    accessRequestVerifiedIcon: 'rgb(45, 207, 62)', 

    overviewRowHover: 'rgba(80,199,199, 0.2)',
    overviewIcon: 'rgb(32, 146, 168)',

    // Patient Info categories 

    firstName: 'rgb(32, 146, 168)',
    lastName: 'rgb(207, 137, 45)',
    gender: 'rgb(188, 45, 207)', 
    dateOfBirth: 'rgb(45, 207, 62)',
    healthCardNumber: 'rgb(207, 137, 45)',
    phone: 'rgb(45, 153, 207)' ,
    email: 'rgb(32, 146, 168)',
    address: 'rgb(45, 207, 62)',
    patientId: 'rgb(207, 137, 45)',
    emergencyContactName: 'rgb(32, 146, 168)',
    emergencyContactRelation: 'rgb(188, 45, 207)',
    emergencyContactPhone: 'rgb(45, 153, 207)',

    patientSearchDropdownRow: 'rgb(215,214,245)',
    patientSearchDropdownRowHover: 'rgb(232,234,246)',

    patientFoundContainer: 'rgb(215,214,245)',
    patientFoundAvatar: 'rgb(34, 148, 168)',
    patientFoundInfo: 'rgb(32, 146, 168)',
    patientFoundInfoSecure: 'rgb(45, 207, 62)',
    patientSearchRow: 'rgb(215,214,245)',
    patientSearchColor: 'rgb(31, 31, 31)',
    patientRowUnselected: 'rgb(200, 200, 200)',
    patientRowIcon: 'rgb(60,179,179)', 
    patientDataSection: 'rgb(232,234,246)',

    accessRequested: 'rgb(19, 156, 65)',
    accessRequestedHover: 'rgba(59, 196, 105, 0.9)',

    // Options 

    account: 'rgb(84, 147, 255)', 
    accountHover: 'rgba(84, 147, 255, 0.2)',

    editPatient: 'rgb(34, 148, 6)', 
    editPatientHover: 'rgba(34, 148, 6, 0.2)', 

    filesOption: 'rgb(60,179,179)', 
    filesOptionHover: 'rgba(80,199,199, 0.2)', 

    notesOption: 'rgb(188, 45, 207)',
    notesOptionHover: 'rgba(188, 45, 207, 0.2)', 
    
    directivesOption: 'rgb(207, 137, 45)',
    directivesOptionHover: 'rgba(207, 137, 45, 0.2)',

    logsOption: 'rgb(32, 146, 168)', 
    logsOptionHover: 'rgba(45, 207, 196, 0.2)', 

    settingsOption: 'rgb(170, 177, 179)',
    settingsOptionHover: 'rgba(170, 177, 179, 0.2)',

    // Templates 

    codeBox: 'rgb(215,214,245)',
    qrValueBackground: 'rgba(39, 56, 105, 0.2)', 
    qeValueBorder: 'rgba(84, 147, 255, 0.3)', 

    createTemplate: 'rgb(215,214,245)',
    createTemplateIcon: 'rgb(60,179,179)',
    templateSearch: 'rgb(200, 200, 200)', 
    templateBoxText: 'rgb(51, 51, 51)',  
    templateBoxBorder: 'rgb(60,179,179)',
    templateBoxIcon: 'rgb(60,179,179)',
    templateBoxHover: 'rgb(60,199,199)',
    templateBoxHoverBorder: 'rgba(45, 207, 196, 0.8)',
    templateHover: 'rgba(77, 75, 89,0.3)',
    templateTextHover: 'rgb(224, 224, 224)', 

    notification: 'rgb(39, 12, 135)',
    notificationText: 'rgb(255, 255, 255)', 

    popup: 'rgba(56, 58, 64, 0.75)',

    navBar: 'rgb(232,234,246)', 

    footerText: 'rgb(138, 138, 138)',
    footerLinkHover: 'rgb(5, 229, 211)', 

    sidebar: 'rgb(255,255,255)', 
    sidebarItemSelectedSection: 'rgb(32, 146, 168)',
    sidebarUnselectedItem: 'rgb(100, 100, 100)',
    subSidebarSection: 'rgb(77, 75, 89)',
    sidebarSelectedItemBackground: 'rgba(32, 146, 168, 0.2)',
    sidebarItemHover: 'rgba(32, 146, 168, 0.25)',

    legalText: 'rgb(138, 138, 138)', 
    legalBannerText: 'rgb(217, 217, 217)',
    legalDescription: 'rgb(217, 217, 217)',
    legalDescriptionBackground: 'rgb(112, 110, 110)',
    legalLearMore: 'rgb(61, 154, 248)',
    legalLearnMoreHover: 'rgb(99, 173, 248)',
    legalHeader: 'rgb(32, 146, 168)', 
    legalSubTitle: 'rgb(29, 108, 124)',

    scanTemplateCameraIcon: 'rgb(32, 146, 168)',
    scanTemplateFromPhotosIcon: 'rgb(45, 207, 62)',
    scanTemplateTakePicture: 'rgb(32, 146, 168)',
    scanTemplateFlipCamera: 'rgb(45, 207, 62)',

    scanTemplateUploadBackground: 'rgb(13, 14, 15)',
    scanTemplateUploadBorder: 'rgb(200, 200, 200)',
    scanTemplateSubmitButton: 'rgba(10,124,225, 0.7)',
    scanTemplateCreated: 'rgb(45, 207, 62)',

    settingsBackButtonHover: 'rgba(77, 75, 89, 0.4)',
    settingsAccountIcon: 'rgb(32, 146, 168)',
    settingsEmailIcon: 'rgb(45, 207, 62)',
    settingsStatusIcon: 'rgb(188, 45, 207)',
    settingsSoftwareVersion: 'rgb(45, 153, 207)',
    settingsThemeLightMode: 'rgb(255, 255, 255)',
    settingsThemeDarkMode: 'rgb(32, 146, 168)',
    settingsDarkTheme: 'rgb(255, 255, 255)', 
    settingsLightTheme: 'black', 

    supportEmailBackground: 'rgb(255,255,255)',
    supportTicketsListHeader: 'rgb(232,234,246)',
    supportHistoryHeader: 'rgb(232,234,246)',  
    supportHistory: 'rgb(200, 200, 200)', 

    templateNavBar: 'rgb(232,234,246)',
    templateDropdown: 'rgb(202,204,226)',
    templateFontSizeFunction: 'rgb(215,214,245)',
    templateFontSizeBackground: 'rgb(215,214,245)',
    templateModified: 'rgb(60,179,179)',
    templateModifiedHover: 'rgb(70,189,189)',
    templateNotModified: 'rgb(215,214,245)',

    templateToolEnabled: 'rgb(60,179,179)',
    templateToolDisabled: 'rgb(215,214,245)',
    templateToolEnabledHover: 'rgb(70,189,189)',
    templateToolDisabledHover: 'rgb(232,234,246)',

    attributeRow: 'rgb(52, 53, 58)',
    attributeRowHover: 'rgb(72, 73, 78)',
    selectedAttributeRow: 'rgb(34, 148, 6)',

    formRow: 'rgb(222,224,236)',
    formRowHover: 'rgb(232,234,246)', 
    selectedFormRow: 'rgb(245,245,245)',
    formRowTitle: 'rgb(45, 153, 207)',
    formRowArrow: 'rgb(93, 95, 99)',
    formRowMovingArrow: 'rgb(32, 146, 168)',
    formRowMovingArrowHover: 'rgba(45, 207, 196, 0.3)',
    formRowRemoveField: 'rgb(228, 45, 247)',
    formRowRemoveFieldHover: 'rgb(205, 30, 30)',

    autoCompleteQuitHover: 'rgba(116, 122, 122, 0.5)',
    autoCompleteAccountBackground: 'rgba(80,199,199, 0.5)',
    autoCompletePatientName: 'rgb(32, 146, 168)',
    autoCompleteSubmitButton: 'rgb(59, 87, 176)',
    autoCompleteSubmitButtonHover: 'rgb(49, 67, 200)',

    documentOptionsColor: 'rgb(60,179,179)',
    documentOptionsHover: 'rgba(80,199,199, 0.1)',

    documentOptionsKeepEditing: 'rgb(50,169,169)',
    documentOptionsKeepEditingHover: 'rgb(70,189,189)',

    templateSelectPatientInfo: 'rgb(63,65,89)',
    templatePatientsListSearch: 'rgb(56, 58, 64)',
    templatePatientsListSearchBackground: 'rgb(200, 200, 200)',  

    templatePatientRow: 'rgb(63,65,69)',
    templatePatientRowHover: 'rgb(73, 75, 79)',
    templatePatientRowIcon: 'rgb(60,179,179)',
    selectedPatientRow: 'rgb(83, 85, 89)',

    templateSidebar: 'rgb(255,255,255)',
    selectedSideBarItem: 'rgb(32, 146, 168)',
    templateSidebarItemHover: 'rgba(77, 75, 89, 0.3)',

    sidebarIconColor: 'rgb(51, 51, 51)', 

    // Consultation 

    consultationQuit: 'rgba(201,79,42, 0.4)', 
    consultationQuitHover: 'rgba(201,79,42, 0.8)', 

    consultationNote: 'rgba(188,45,207, 0.2)',
    consultationNoteHover: 'rgba(188,45,207, 0.4)',

    templateBanner: 'rgba(61, 45, 207, 0.2)',
    templateBannerHover:  'rgba(61, 45, 207, 0.4)',

    cameraBanner: 'rgba(45, 207, 58, 0.2)',
    cameraBannerHover:  'rgba(45, 207, 58, 0.4)',

    attachFileIcon: 'rgb(227, 167, 0)',
    attachFile: 'rgba(227, 167, 0, 0.2)', 
    attachFileHover: 'rgba(227, 167, 0, 0.4)',

    importFile: 'rgba(45,207,62, 0.2)', 
    importFileHover: 'rgba(45,207,62, 0.4)',  

    navbarToolEnabled: 'rgb(60,179,179)',
    navbarToolEnabledHover: 'rgb(70,189,189)',

    navbarToolDisabled: 'rgb(215,214,245)',
    navbarToolDisabledHover: 'rgb(232,234,246)',

    consultationNavBarArrows: 'rgb(120, 120, 120)', 

    consultationNoteSideBar: 'rgb(255,255,255)',
    consultationNoteFieldHeader: 'rgb(32, 146, 168)',

    consultaitonNoteFieldQuit: 'rgb(181,59,22)', 

    // Note Microphone 

    microphoneDisabled: 'rgb(60,179,179)', 
    microphoneEnabled: 'rgb(201,79,42)', 
    microphoneIcon: 'rgb(255, 255, 255)', 
    microphoneOption: 'rgb(60,179,179)', 
    microphoneOptionIcon: 'rgb(255, 255, 255)', 

    avatarBorder: 'rgba(60,179,179, 0.6)',
    avatar: 'rgba(60,179,179, 0.1)',

    micOptionsListBackground: 'rgb(255, 255, 255)',
    micOptionsListShadow: 'rgba(30, 30, 30, 0.1)',

    // Colors  (most of the fields above used one of them). 

    green: 'rgb(45,207,62)', 
    orange: 'rgb(207,137,45)', 
    purple: 'rgb(188,45,207)', 
    grey: 'rgb(170,177,179)', 
    red: 'rgb(201,79,42)', 

    lightBlue: 'rgb(60,179,179)',
    darkGreen: 'rgb(34,148,6)', 
    darkBlue: 'rgba(10,124,225, 0.7)', 
    darkPurple: 'rgb(127,52,235)', 

}; 

export default lightTheme; 