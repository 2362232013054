import React, { useState, useEffect, useRef } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../../../../redux/hooks';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

// Components 

import Box from '@mui/material/Box';
import BookmarkAddIcon from '@mui/icons-material/BookmarkAdd';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';

import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import CopyAllIcon from '@mui/icons-material/CopyAll';
import OptionRow from './OptionRow';

const Options: React.FC = () => {

    const dispatch = useAppDispatch(); 
    const { t } = useTranslation(); 
    const navigate = useNavigate(); 

    const colors = useAppSelector(state => state.theme.colors); 

    const templateOptionsXPosition = useAppSelector(state => state.consultation.templateOptionsXPosition); 
    const templateOptionsYPosition = useAppSelector(state => state.consultation.templateOptionsYPosition); 



    return (

        <Box
        sx={{ 
            display: 'flex', 
            flexDirection: 'column',
            alignItems: 'center', 
            justifyContent: 'space-evenly',
            width: 200,
            height: 150,
            left: templateOptionsXPosition - 100,
            top: templateOptionsYPosition, 
            position: 'absolute',
            backgroundColor: colors?.fileExplorerDropdown,
            boxShadow: '0 4px 6px rgba(0, 0, 0, 0.2)',
            borderRadius: 4,
            zIndex: 10000000, 
            transition: 'height 0.3s',
        }}
    >

            <OptionRow section={'useTemplate'}>
                <DescriptionOutlinedIcon /> 
            </OptionRow>

            <OptionRow section={'editTemplate'}>
                <DriveFileRenameOutlineIcon /> 
            </OptionRow>

            <OptionRow section={'duplicateTemplate'}>
                <CopyAllIcon /> 
            </OptionRow>

            <OptionRow section={'deleteTemplate'}>
                <DeleteForeverIcon /> 
            </OptionRow>

    </Box>

  );

};

export default Options;