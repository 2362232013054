import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import { useAppDispatch, useAppSelector } from '../../../../../../../redux/hooks';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

// Components
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { deleteAccessRequest } from '../../../../../../../routes/doctor/patients';
import { setNotificationMessage } from '../../../../../../../redux/features/general/notification';
import { setIsRefreshAccessRequests } from '../../../../../../../redux/features/doctor/Dashboard/patients/accessRequests';

type Props = {
    children?: React.ReactNode;
    title: any, 
};


const DropdownRow: React.FC<Props> = ({ children, title }) => {

    const { t } = useTranslation();
    const navigate = useNavigate(); 
    const dispatch = useAppDispatch(); 

    const colors = useAppSelector((state) => state.theme.colors);

    const selectedAccessRequestId = useAppSelector((state) => state.accessRequests.selectedAccessRequestId);

    // -- 

    const handleDropdownRow = async () => { 

        if (title === 'removeRequest') { 

            const res = await deleteAccessRequest(selectedAccessRequestId) as any; 

            if (res.data.status === 200) { 

                dispatch(setIsRefreshAccessRequests(true)); 

                dispatch(setNotificationMessage(t('accessRequestDeleted')));

            }; 

        }; 

    }; 

    // -- 

    return (

                
        <Box
            onClick={handleDropdownRow}
            sx={{ 
                display: 'flex', 
                flexDirection: 'row',
                alignItems: 'center',
                width: '100%', 
                height: 40,
                fontSize: 12,  
                borderRadius: 3,
                '&:hover': { backgroundColor: colors?.sectionHover}
            }}
        >
            <Box
                style={{ 
                    display: 'flex', 
                    flexDirection: 'row',
                    alignItems: 'center',
                    marginLeft: 10
                }}
            >
                {children}
                <Box sx={{ marginLeft: 3 }}>{t(title)}</Box>
            </Box>

        </Box>


    );
};

export default DropdownRow;