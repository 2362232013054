import React, { useState, useEffect, useRef } from 'react'; 
import { useAppDispatch, useAppSelector } from '../../../../../redux/hooks';
import Box from '@mui/material/Box';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useNavigate } from 'react-router-dom';

// Components 

import { useTranslation } from 'react-i18next';
import { serveFile } from '../../../../../routes/doctor/files';
import Header from '../items/Header';
import CircularProgress from '@mui/material/CircularProgress';


const FileViewer: React.FC = () => {

    const dispatch = useAppDispatch(); 
    const navigate = useNavigate(); 

    const { t } = useTranslation(); 

    const isSmallScreen = useMediaQuery('(max-width: 1300px)');

    const colors = useAppSelector(state => state.theme.colors);

    const iframeRef = useRef<HTMLIFrameElement | null>(null);
    const containerRef = useRef<HTMLDivElement | null>(null);

    const selectedFile = useAppSelector(state => state.fileViewer.selectedFile) as any; 

    const [fileUrl, setFileUrl] = useState(''); 

    // -- 

    const handleFile = async () => { 

        const res = await serveFile(selectedFile?._id) as any; 
        
        const fileBlob = new Blob([res.data], { type: res.headers['content-type'] });
    
        setFileUrl(URL.createObjectURL(fileBlob)); 

    }; 

    // -- 

    useEffect(() => { 

        if (selectedFile) { 

            handleFile(); 

        }; 

    },[selectedFile]); 

    // --

    return (

        <Box 
            style={{ 
                display: 'flex', 
                flexDirection: 'row',
                alignItems: 'center', 
                justifyContent: 'space-evenly',
                paddingTop: 50, 
                width: '100vw',
                height: '100vh', 
                backgroundColor: 'black', 
                overflow: 'hidden', 
                position: 'absolute', 
                zIndex: 1000000000
        }}>

            <Header title={selectedFile?.title} />

                {fileUrl ?
                    <iframe
                    ref={iframeRef}
                    src={fileUrl}
                    style={{ 
                        width: '100%', 
                        height: '90vh',
                    }}
                    title="PDF Viewer"
                    />: 
                <CircularProgress size={30} />}

                        
                {/* { fileExtension === 'docx' && 

                    <div
                    ref={containerRef}
                    style={{ 
                        width: '50vw', 
                        height: '80vh',
                        position: 'absolute', 
                    }}
                />} */}

        </Box>
  );
};

export default FileViewer;