import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

// Components
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

import clearCookies from '../../../../util/storage/clearCookies';
import clearLocalStorage from '../../../../util/storage/clearLocalStorage';
import { setIsNavigationDropdownOpened } from '../../../../redux/features/general/navigation';

type Props = {
    children?: React.ReactNode;
    section: string; 
};


const SettingsRow: React.FC<Props> = ({ children, section }) => {

    const { t } = useTranslation();
    const navigate = useNavigate(); 
    const dispatch = useAppDispatch(); 

    const colors = useAppSelector((state) => state.theme.colors);
    const location = useLocation();

    const isNavigationDropdownOpened = useAppSelector(state => state.navigation.isNavigationDropdownOpened);
    const [isCurrentSection, setIsCurrentSection] = useState(false); 
    const [currentSection, setCurrentSection] = useState(`/${section}`); 

    // -- 

    const handleSettings = async () => { 

        if (section === 'logout') { 

            clearCookies(); 
            clearLocalStorage();
    
            navigate('/login');
    
            window.location.reload();

        } else if (section === 'dashboard') { 

            navigate('/dashboard'); 

        } else if (section === 'support')  {

            navigate('/support'); 

        } else if (section === 'legal') { 

            navigate('/legal'); 

        } else if (section === 'settings') { 

            navigate('/settings'); 

        }; 

        dispatch(setIsNavigationDropdownOpened(false)); 

    }; 

    // -- 

    useEffect(() => { 

        if (location.pathname) { 

            if (location.pathname == currentSection) { 

                setIsCurrentSection(true); 

            } else { 

                setIsCurrentSection(false); 
                
            };

        }; 

    },[location]); 

    // -- 

    return (
                
        <Box
            onClick={handleSettings}
            sx={{ 
                display: 'flex', 
                flexDirection: 'row',
                alignItems: 'center',
                width: '100%', 
                height: 40,
                fontSize: 12,  
                borderRadius: 1,
                cursor: 'pointer', 
                '&:hover': { backgroundColor: colors?.sectionHover}
            }}
        >
            <Box
                style={{ 
                    display: 'flex', 
                    flexDirection: 'row',
                    alignItems: 'center',
                    marginLeft: 10,
                    color: isCurrentSection ? colors?.darkBlue : colors?.text
                }}
            >
                {children}
                <Box sx={{ marginLeft: 3 }}>{t(section)}</Box>
            </Box>

    </Box>

    );
};

export default SettingsRow;