import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

import { useAppDispatch, useAppSelector } from './../../redux/hooks';

// Redux 

import { setNotificationMessage } from '../../redux/features/general/notification';

const Notification: React.FC = () => {

    const dispatch = useAppDispatch(); 

    const [open, setOpen] = useState(false);
    const [textMessage, setTextMessage] = useState(''); 

    const colors = useAppSelector(state => state.theme.colors);
    const message = useAppSelector(state => state.notification.message); 

    const handleClose = () => {

    setOpen(false);

  };

  useEffect(() => { 

    if (message) { 

        setTextMessage(message); 
        setOpen(true); 

        dispatch(setNotificationMessage('')); 

    }; 

  },[message]);

  // To remove the notification message 

  const action = (

    <React.Fragment>

      {/* <Button color="secondary" size="small" onClick={() => handleClose('clickaway')}>
        UNDO
      </Button> */}

      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={() => handleClose()}
      >
      
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>

  );

  return (

      <Snackbar
        open={open}
        autoHideDuration={5000}
        onClose={handleClose}
        message={textMessage}
        action={action}
        ContentProps={{
            sx:{
                backgroundColor: colors?.notification,
                color: colors?.notificationText,
                zIndex: 10000000
            }
        }}
      />
  );
};

export default Notification;