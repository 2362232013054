import React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
// Redux 

import { useTranslation } from 'react-i18next';
import { setClosePopup } from '../../../../redux/features/general/popup';
import { resetConsultationNote, setAction } from '../../../../redux/features/doctor/Dashboard/consultation/consultationNote';
import { deleteConsultationNote } from '../../../../routes/doctor/consultation';
import { useNavigate, useParams } from 'react-router-dom';

type Props = {
    children?: React.ReactNode,
    isPortraitMode?: boolean,
};

const ExistingFieldPopup: React.FC<Props> = ({ children, isPortraitMode }) => {

    const dispatch = useAppDispatch();
    const navigate = useNavigate(); 
    const { t } = useTranslation();

    const { consultationId } = useParams(); 
    const colors = useAppSelector(state => state.theme.colors);
    const consultationNoteId = useAppSelector(state => state.consultationNote.consultationNoteId); 

    // -- 

    const handleReplace = async () => { 

        dispatch(setAction('replaceNoteField')); 
        dispatch(setClosePopup()); 

    }; 

    // -- 

    const goBack = () => { 

        dispatch(setClosePopup()); 

    }; 


  return (
    <Box sx={{ 
        display: 'flex', 
        flexDirection: 'column',
        alignItems: 'center', 
        justifyContent: 'space-evenly',
        width: '100%',
        height: '100%',
        borderRadius: 5,
        backgroundColor: colors?.container,
        overflowY: 'auto',
        overflowX: 'hidden',
    }}>

        <Box
            sx={{ 
                width: '75%',
                textAlign: 'center'
            }}
        >{t('existingNoteField')}</Box>

        <Box
            sx={{ 
                display: 'flex', 
                flexDirection: 'column', 
                alignItems: 'center',
                width: '100%'}}>
        <Button 
          sx={{ 
            width: 200,
            backgroundColor: colors?.darkBlue,
            borderColor: colors?.darkBlue,
            color: 'white',
            marginBottom: 2,
            '&:hover': 
            { 
                backgroundColor: colors?.lightBlue,
                color: colors?.exitButtonText,
                borderColor: colors?.lightBlue
             }
          }}
          onClick={() => handleReplace()}
          variant="outlined">{t('continue')}</Button>
                  <Button 
          sx={{ 
            width: 200,
            backgroundColor: colors?.cancelButtonColor, 
            borderColor: colors?.text,
            color: colors?.text,
            '&:hover': 
            { 
                backgroundColor: colors?.cancelButtonBackground,
                color: colors?.text,
                borderColor: colors?.cancelButtonBackground
             }
          }}
          onClick={() => goBack()}
          variant="outlined">{t('back')}</Button>
          </Box>

    </Box>
  );
};

export default ExistingFieldPopup;