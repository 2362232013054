import React, { useState, useEffect, useRef } from 'react';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Camera, { FACING_MODES, IMAGE_TYPES } from 'react-html5-camera-photo';
import 'react-html5-camera-photo/build/css/index.css';

// Components 

import Box from '@mui/material/Box';
import { setImageUrl, setSection } from '../../../redux/features/doctor/Dashboard/consultation/camera';

const Video: React.FC = () => {

    const dispatch = useAppDispatch(); 
    const { t } = useTranslation(); 
    const navigate = useNavigate(); 

    const colors = useAppSelector(state => state.theme.colors); 

    const handleTakePhoto = (dataUri: any) => { 

        dispatch(setImageUrl(dataUri)); 
        dispatch(setSection('upload')); 

    }; 

    return (

        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column', 
                alignItems: 'center',  
                width: '100vw',
                height: '95vh',
                top: 0,
                position: 'absolute', 
                borderBottomLeftRadius: 2, 
                borderBottomRightRadius: 2, 
            }}
        >

            <Camera 
                onTakePhoto={(dataUri: any) => handleTakePhoto(dataUri)} 
                imageType={IMAGE_TYPES.PNG} 
                idealFacingMode={FACING_MODES.ENVIRONMENT}
            />

        </Box>

    );

};

export default Video;