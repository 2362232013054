import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import { useAppDispatch, useAppSelector } from '../../../../../../../redux/hooks';
import { useTranslation } from 'react-i18next';

// Components
import { default as MuiList } from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import NoteRow from './NoteRow';
import { getConsultationNotes } from '../../../../../../../routes/doctor/consultation';
import { setNotes, setSection, setSelectedConsultationNote } from '../../../../../../../redux/features/doctor/Dashboard/consultation/consultation';
import { setIsNoteViewerOpened, setSelectedNote } from '../../../../../../../redux/features/doctor/Dashboard/documents/noteViewer';
import { useNavigate } from 'react-router-dom';

const List: React.FC = () => {

    const { t } = useTranslation();
    const navigate = useNavigate(); 
    const dispatch = useAppDispatch(); 

    const colors = useAppSelector(state => state.theme.colors);
    const consultationId = useAppSelector(state => state.consultation.consultationId); 
    const patientId = useAppSelector(state => state.consultation.patientId);  
    const notes = useAppSelector(state => state.consultation.notes); 

    // -- 

    const handleConsultationNotesList = async () => { 

        const res = await getConsultationNotes(consultationId) as any; 

        if (res.data.status === 200) { 

            dispatch(setNotes(res.data.consultationNotes)); 

        }; 

    }; 

    // -- 

    const handleSelection = async (e: any, item: any) => { 

        if (e.detail === 2) { 

            navigate(`/consultation/note/${consultationId}/${item._id}`); 

        } else if (e.detail === 1) { 

            dispatch(setSelectedConsultationNote(item)); 

        }; 

    }; 

    // -- 

    useEffect(() => { 

        handleConsultationNotesList(); 

    },[]);

    // --

    return (

        <Box 
            sx={{ 
            display: 'flex', 
            flexDirection: 'column',
            alignItems: 'center', 
            width: '100%',
            minHeight: 700, 
            overflow: 'scroll',
            scrollbarWidth: 'none', 
        }}>

            { notes?.length >= 1 && 

            <Box 
                
                sx={{ 
                    width: '100%',
                    height: '100%',
                    marginTop: 1, 
                    overflow: 'scroll',
                    scrollbarWidth: 'none',
                }}>
    
                <MuiList dense disablePadding>
        
                {notes?.map((item, index) => (
        
                    <ListItem 
                        onClick={(e) => handleSelection(e, item)} 
                        button 
                        key={index} 
                        disablePadding>

                        <NoteRow 
                            title={item.title}
                            updatedAt={item.updatedAt}
                        /> 

                    </ListItem>

                ))}
        
                </MuiList>

        
            </Box>}

            {(notes?.length === 0) &&   
          
            <Box
              sx={{ 
                  display: 'flex', 
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'space-around',
                  width: '100%',
                  height: '100%',
              }}>
          
              <Box
                  sx={{
                      width: 252,
                      height: 214, 
                      marginTop: '5%',
                  }}
                  component="img"
                  alt="Solutions Medca Inc."
                  src={require('../../../../../../../assets/logos/empty_list.png')}
              />

              <Box sx={{ 
                  color: colors?.text,
                  fontSize: 12,
                  marginBottom: '20%',
              }}>{t('emptyFolder')}</Box>

            </Box>}
      

          </Box>

    );
};

export default List;