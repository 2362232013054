import React, { useState, useEffect, useRef } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../../../../redux/hooks';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

// Components 

import Box from '@mui/material/Box';
import AddIcon from '@mui/icons-material/Add';
import { createConsultationNote } from '../../../../../../../routes/doctor/consultation';

const BlankTemplate: React.FC= () => {

    const dispatch = useAppDispatch(); 
    const { t } = useTranslation(); 
    const navigate = useNavigate(); 

    const colors = useAppSelector(state => state.theme.colors); 
    const consultationId = useAppSelector(state => state.consultation.consultationId); 

    // -- 

    const handleCreateTemplate = async () => { 

        const res = await createConsultationNote({ type: 'template' }); 

        if (res?.data.status === 200) { 

            navigate(`/consultation/note/template/${consultationId}/${res.data.consultationNote._id}`); 

        }; 

    }; 

    // -- 
    
    return (

        <Box 
            onClick={handleCreateTemplate}
            sx={{ 
            display: 'flex', 
            flexDirection: 'column',
            alignItems: 'center', 
            width: 150,
            marginLeft: 5,
            marginRight: 5,
            borderRadius: 3,
            marginTop: 1, 
            height: 200, 
            cursor: 'pointer',
            overflow: 'hidden', 
            '&:hover': { backgroundColor: 'rgba(77, 75, 89, 0.2)' }
        }}>

            <Box
                sx={{ 
                    display: 'flex',
                    flexDirection: 'column', 
                    alignItems: 'center', 
                    justifyContent: 'space-evenly', 
                    width: 100, 
                    height: 143, 
                    marginTop: 1, 
                    marginBottom: 2,
                    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.2)',
                    backgroundColor: 'white', 
                }}
            >

                <AddIcon sx={{  color: colors?.lightBlue }} /> 

            </Box>

            <div style={{ 
                width: '95%',
                color: colors?.text, 
                textAlign: 'center',
                overflow: 'hidden',
                fontSize: 12, 
                whiteSpace: 'wrap'
            }}>{t('blank')}</div>

        </Box>

  );

};

export default BlankTemplate;