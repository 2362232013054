import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface SavedConsultationState {
    consultationId: string; 
    section: string;
    patientId: string; 
    patientName: string; 
    patientsList: any[]; 
    notes: any[]; 
    attachedFiles: any[]; 
    images: any[], 
    selectedConsultation: any; 
    selectedConsultationNote: any; 
    selectedAttachedFile: any; 
    selectedImage: any; 
    isNewNoteCreated: boolean; 
    initialRendering: boolean; 
}

const initialState: SavedConsultationState = {
    consultationId: '',
    section: 'consultations', 
    patientId: '',
    patientName: '', 
    patientsList: [],
    notes: [], 
    attachedFiles: [], 
    images: [],
    selectedConsultation: null, 
    selectedConsultationNote: null, 
    selectedAttachedFile: null, 
    selectedImage: null, 
    isNewNoteCreated: false, 
    initialRendering: true,  
};

const savedConsultationSlice = createSlice({
  name: 'savedConsultation',
  initialState,
  reducers: {
    setConsultationId: (state, action: PayloadAction<string>) => {
      state.consultationId = action.payload;
    },
    setSection: (state, action: PayloadAction<string>) => {
      state.section = action.payload;
    },
    setPatientId: (state, action: PayloadAction<string>) => {
      state.patientId = action.payload;
    },
    setPatientName: (state, action: PayloadAction<string>) => {
      state.patientName = action.payload;
    }, 
    setPatientsList: (state, action: PayloadAction<any>) => {
      state.patientsList = action.payload;
    },
    setNotes: (state, action: PayloadAction<any>) => {
      state.notes = action.payload;
    },
    setAttachedFiles: (state, action: PayloadAction<any>) => {
      state.attachedFiles = action.payload;
    },
    setImages: (state, action: PayloadAction<any>) => {
      state.images = action.payload;
    },
    setSelectedConsultation: (state, action: PayloadAction<any>) => {
      state.selectedConsultation = action.payload;
    },
    setSelectedConsultationNote: (state, action: PayloadAction<any>) => {
      state.selectedConsultationNote = action.payload; 
    },
    setSelectedAttachedFile: (state, action: PayloadAction<any>) => {
      state.selectedAttachedFile = action.payload; 
    },
    setSelectedImage: (state, action: PayloadAction<any>) => {
      state.selectedImage = action.payload; 
    },
    setIsNewNoteCreated: (state, action: PayloadAction<boolean>) => {
      state.isNewNoteCreated = action.payload;
    },
    setInitialRendering: (state, action: PayloadAction<boolean>) => {
      state.initialRendering = action.payload;
    },
    resetSavedConsultation: (state) => { 
        
      state.consultationId = '';
      state.section = 'consultations';
      state.patientId = '';
      state.patientName = ''; 
      state.patientsList = []; 
      state.notes = []; 
      state.selectedConsultationNote = null; 
      state.selectedAttachedFile = null; 
      state.selectedImage = null; 
      state.attachedFiles = []; 
      state.images = []; 
      state.selectedConsultation = null; 
      state.isNewNoteCreated = false; 
      state.initialRendering = true; 

    } 
  },
});

export const { 
  setConsultationId, 
  setSection, 
  setPatientId,
  setPatientName, 
  setPatientsList, 
  setNotes, 
  setAttachedFiles, 
  setImages, 
  setSelectedConsultation, 
  setSelectedConsultationNote, 
  setSelectedAttachedFile, 
  setSelectedImage,  
  setIsNewNoteCreated, 
  setInitialRendering, 
  resetSavedConsultation } = savedConsultationSlice.actions;

export default savedConsultationSlice.reducer;