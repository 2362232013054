import { createSlice, PayloadAction } from '@reduxjs/toolkit';


interface InitialState {
    selectedNote: any,
    blobFileUrl: any,
    fileExtension: any,  
    fileName: any, 
    isNoteViewerOpened: boolean, 
}

const initialState: InitialState = {
  selectedNote: null,
  blobFileUrl: null,
  fileExtension: null, 
  fileName: null, 
  isNoteViewerOpened: false, 
};

export const noteViewerSlice = createSlice({
  name: 'noteViewer',
  initialState,
    reducers: {
    setSelectedNote: (state, action: PayloadAction<any>) => {
      state.selectedNote = action.payload 
    },
    setBlobFileUrl: (state, action: PayloadAction<any>) => {
      state.blobFileUrl = action.payload 
    },
    setFileExtension: (state, action: PayloadAction<any>) => {
      state.fileExtension = action.payload 
    },
    setFileName: (state, action: PayloadAction<any>) => {
      state.fileName = action.payload 
    },
    setIsNoteViewerOpened: (state, action: PayloadAction<boolean>) => {
      state.isNoteViewerOpened = action.payload 
    },
    resetNoteViewer: (state) => { 

      state.selectedNote = null; 
      state.blobFileUrl = null; 
      state.fileExtension = null; 
      state.fileName = null; 
      state.isNoteViewerOpened = false; 

    } 
  },
});

export const {
  setSelectedNote, 
  setBlobFileUrl, 
  setFileExtension, 
  setFileName, 
  setIsNoteViewerOpened, 
  resetNoteViewer
} = noteViewerSlice.actions;

export default noteViewerSlice.reducer;