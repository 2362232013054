const publicRoutes = [
    '/', 
    '/login', 
    '/legal', 
    '/hacker/login', 
    /^\/signup\/doctor\/[^/]+$/, 
    /^\/signup\/insurer\/[^/]+$/, 
    /^\/signup\/admin\/[^/]+$/, 
    '/tos', 
    '/privacy',
    '/support',
    '/faq',
    /^\/scan-template\/[^/]+$/, 
    /^\/patient\/access\/request\/[^/]+$/,
    /^\/login\/mobile\/consultation\/[^/]+$/, 
    /^\/consultation\/note\/mic\/[^/]+$/, 
    /^\/camera\/mic\/[^/]+$/, 
    
];

export default publicRoutes;