import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface CameraState {
    section: string; 
    imageUrl: any; 
}; 

const initialState: CameraState = {
    section: 'video',
    imageUrl: null, 
};

const cameraSlice = createSlice({
  name: 'camera',
  initialState,
  reducers: {
    setSection: (state, action: PayloadAction<string>) => {
      state.section = action.payload;
    },
    setImageUrl: (state, action: PayloadAction<any>) => {
        state.imageUrl = action.payload;
    },
    resetCamera: (state) => { 
        
      state.section = 'video';
      state.imageUrl = null; 

    } 
  },
});

export const { 
  setSection, 
  setImageUrl, 
  resetCamera } = cameraSlice.actions;

export default cameraSlice.reducer;