import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { useAppDispatch, useAppSelector } from '../../../../../redux/hooks';
import { useTranslation } from 'react-i18next';

// Redux 

import { setRefreshFileExplorer } from '../../../../../redux/features/doctor/Dashboard/documents/fileExplorer';
import { resetCreateFolder, setTitle } from '../../../../../redux/features/doctor/Dashboard/documents/createFolder';

// Routes 

import { createFolder } from '../../../../../routes/doctor/files';

// Components

import FolderIcon from '@mui/icons-material/Folder';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { setNotificationMessage } from '../../../../../redux/features/general/notification';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';


const CreateFolder: React.FC = () => {

    const { t } = useTranslation();
    const dispatch = useAppDispatch(); 

    const colors = useAppSelector(state => state.theme.colors);
    const currentFolder = useAppSelector((state) => state.fileExplorer.currentFolder); 

    const title = useAppSelector((state) => state.createFolder.title); 
    const description = useAppSelector((state) => state.createFolder.description); 
    const relatedPatient = useAppSelector((state) => state.createFolder.relatedPatient); 
    const folderView = useAppSelector((state) => state.fileExplorer.folderView); 

    const [titleInput, setTitleInput] = useState('');
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');  

    // -- 

    const handleFolderCreation = async () => { 

        if (title) { 

            const content = { 
                title: title,
                description: description,
                folder: currentFolder,
                relatedPatient: relatedPatient
            }; 

            const res = await createFolder(content) as any; 

            if (res.data.status === 200) { 

                dispatch(setRefreshFileExplorer(true)); 

                dispatch(setNotificationMessage(t('folderCreated'))); 

                dispatch(resetCreateFolder()); 

            } else if (res.data.status === 401) { 

                if (res.data.error === 'existingFolder') { 

                    setError(true); 
                    setErrorMessage(String(t('existingFolderError'))); 

                } else if (res.data.error === 'invalidTitle') { 

                    setError(true); 
                    setErrorMessage(String(t('invalidTitleError'))); 

                };

            }; 

        }; 

    }; 

    // --

    const handleInput = (key: string) => { 

        if (key == 'Enter') { 

            handleFolderCreation(); 

        }; 

    };

    // -- 

    const closeFolderCreation = () => { 

        dispatch(resetCreateFolder()); 

    }; 

    // -- 

    useEffect(() => {
        
        setError(false); 
        setErrorMessage(''); 

        dispatch(setTitle(titleInput)); 

    },[titleInput]); 

    // -- 

    return (

        <>

            { folderView === 'row' && 
            
                <Box sx={{ 
                    display: 'flex', 
                    flexDirection: 'row',
                    alignItems: 'center', 
                    width: '100%',
                    fontSize: 12, 
                    marginTop: 1, 
                    height: 50,
                }}>
                    
                        <div style={{ 
                            display: 'flex', 
                            flexDirection: 'row',
                            alignItems: 'center',
                            minWidth: '10%', 
                            marginLeft: '2%',  
                        }}>

                            <Box
                                component="img"
                                sx={{
                                    width: 20,
                                    marginLeft: 1, 
                                }}
                                src={require('../../../../../assets/icons/folder-icon.png')}
                            />

                        </div>

                        <TextField 
                            sx={{ width: '40%', textAlign: 'left' }} 
                            onChange={(e) => setTitleInput(e.target.value)}
                            InputProps={{ 
                                style: { 
                                    color: colors?.text,
                                    fontSize: 12, 
                                }
                            }}
                            id="standard-basic" 
                            value={title}
                            variant="standard" 
                            autoFocus={true}
                            error={error}
                            helperText={errorMessage}
                            onKeyDown={(e) => handleInput(e.key)}
                        />

                    { titleInput && <Button sx={{ marginLeft: 4, height: 30 }} onClick={() => handleFolderCreation()} variant="contained">{t('createFolder')}</Button>}

                    <Button 
                        onClick={() => closeFolderCreation()} 
                        sx={{ 
                            marginLeft: 2,
                            height: 30,
                            backgroundColor: colors?.closeButtonBackground,
                            borderColor: colors?.closeButtonBackground,
                            '&:hover': 
                            { 
                                backgroundColor: colors?.closeButtonBackground,
                                color: colors?.closeButtonText,
                                borderColor: colors?.closeButtonBackground
                            }
                        }}
                    variant="contained">{t('cancel')}</Button>
                
                </Box>}


                { (folderView === 'box') && 

                <Box
                    sx={{ 
                        display: 'flex', 
                        flexDirection: 'row',
                        alignItems: 'center', 
                        width: '100%',
                    }}
                >
                
                    <Box 
                        sx={{ 
                        display: 'flex', 
                        flexDirection: 'column',
                        alignItems: 'center', 
                        width: 150,
                        marginLeft: 5,
                        marginRight: 5,
                        borderRadius: 3,
                        marginTop: 1, 
                        height: 200, 
                        overflow: 'hidden', 
                    }}>
                        
                        <Box
                            component="img"
                            sx={{
                                width: 100,
                                marginTop: 1, 
                                marginBottom: 2, 
                            }}
                            src={require('../../../../../assets/icons/folder-icon.png')}
                        />
                        
                        <TextField 
                            sx={{ width: '90%', textAlign: 'center' }} 
                            onChange={(e) => setTitleInput(e.target.value)}
                            InputProps={{ 
                                style: { 
                                    color: colors?.text,
                                    fontSize: 12, 
                                    textAlign: 'center'
                                }
                            }}
                            id="standard-basic" 
                            value={title}
                            variant="standard" 
                            autoFocus={true}
                            error={error}
                            helperText={errorMessage}
                            onKeyDown={(e) => handleInput(e.key)}
                        />

                        <Box
                            sx={{ 
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                justifyContent: 'space-evenly', 
                                marginTop: 1, 
                                widht: '100%', 
                            }}
                        >

                            <HighlightOffIcon onClick={() => closeFolderCreation()} 
                                sx={{ 
                                    height: 30,
                                    cursor: 'pointer', 
                                    '&:hover': {  color: colors?.exitButtonColorHover }
                                }}
                            ></HighlightOffIcon>

                            { titleInput && 
                            <CheckCircleOutlinedIcon onClick={() => handleFolderCreation()} 
                                sx={{ 
                                    height: 30,
                                    cursor: 'pointer', 
                                    color: colors?.lightBlue, 
                                    marginLeft: 1, 
                                    '&:hover': {  color: colors?.darkBlue }
                                }}
                            ></CheckCircleOutlinedIcon>}

                        </Box>
                        
                        </Box>

                </Box>}

        </>

    );

};

export default CreateFolder;