import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useAppSelector, useAppDispatch } from '../../../../redux/hooks';
import Box from '@mui/material/Box';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

// Utils 

import SettingsRow from './SettingsRow';
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import SupportAgentOutlinedIcon from '@mui/icons-material/SupportAgentOutlined';
import PolicyOutlinedIcon from '@mui/icons-material/PolicyOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import { setIsNavigationDropdownOpened } from '../../../../redux/features/general/navigation';

type Props = {
    children?: React.ReactNode
};

const Settings: React.FC<Props> = ({children}) => {

    const navigate = useNavigate();
    const { t } = useTranslation();
    const dispatch = useAppDispatch(); 
    const location = useLocation();

    const colors = useAppSelector(state => state.theme.colors);

    const isNavigationDropdownOpened = useAppSelector(state => state.navigation.isNavigationDropdownOpened);

    // -- 

    const handleDropdown = () => { 

        dispatch(setIsNavigationDropdownOpened(!isNavigationDropdownOpened)); 

    }; 

    // -- 

    useEffect(() => {

        dispatch(setIsNavigationDropdownOpened(false)); 

    },[location.pathname]); 
    
    // -- 

    return (


        <Box
            sx={{ 
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'space-evenly',
                position: 'absolute', 
                right: '2vw', 
            }}
        >
            <AccountCircleIcon 
                onClick={handleDropdown}
                sx={{ 
                    cursor: 'pointer', 
                    color: isNavigationDropdownOpened ? colors?.lightBlue : 'rgb(100, 100, 100)',
                    '&:hover': { color: colors?.sidebarItemSelectedSection }
                }} />
            
                <Box
                    sx={{ 
                        display: 'flex', 
                        flexDirection: 'column',
                        alignItems: 'center', 
                        justifyContent: 'space-evenly',
                        width: 200,
                        height: isNavigationDropdownOpened ? 200 : 0,
                        top: 30, 
                        right: 0, 
                        position: 'absolute',
                        backgroundColor: colors?.fileExplorerDropdown,
                        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.2)',
                        borderRadius: 4,
                        zIndex: 10000000, 
                        transition: 'height 0.3s',
                    }}
                >

                    {isNavigationDropdownOpened && 
                    
                    <>

                        <SettingsRow section={'dashboard'} >
                            <DashboardOutlinedIcon /> 
                        </SettingsRow>

                        <SettingsRow section={'support'} >
                            <SupportAgentOutlinedIcon /> 
                        </SettingsRow>

                        <SettingsRow section={'legal'} >
                            <PolicyOutlinedIcon /> 
                        </SettingsRow>

                        <SettingsRow section={'settings'} >
                            <SettingsOutlinedIcon /> 
                        </SettingsRow>

                        <SettingsRow section={'logout'} >
                            <LogoutOutlinedIcon /> 
                        </SettingsRow>

                    </>}

                </Box>

        </Box>

    );
};

export default Settings;