import React, { useState, useEffect, useRef } from 'react';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

// Components 

import Box from '@mui/material/Box';
import Logo from './items/Logo';

const Header: React.FC = () => {

    const dispatch = useAppDispatch(); 
    const { t } = useTranslation(); 
    const navigate = useNavigate(); 

    const { securityPin } = useParams(); 

    const colors = useAppSelector(state => state.theme.colors); 

    const consultationId = useAppSelector(state => state.consultationNote.consultationId); 

    // -- 

    const handleCamera = () => { 

      navigate(`/camera/${consultationId}/${securityPin}`); 

    }; 

    // -- 

    // -- 

    return (

      <Box sx={{ 
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center', 
          justifyContent: 'space-evenly', // space-between 
          width: '95vw',
          height: '7.5vh',
          position: 'absolute',
          top: 0,
          overflow: 'hidden',  
      }}>

        {/* <InfoOutlinedIcon 
          sx={{ width: 30, height: 30, color: colors?.darkBlue }} 
        />  */}

        <Logo />

        {/* <Account />  */}

        <Box 
          onClick={handleCamera}
          component="img" 
          sx={{ 
              width: 40, 
              marginLeft: 1,
              position: 'absolute', 
              right: 2, 
              }} 
              src={require('../../../assets/icons/consultation/camera-microphone.png')} 
          />

      </Box>

  );

};

export default Header;