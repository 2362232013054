import React, { useState, useEffect } from 'react'; 
import { useAppDispatch, useAppSelector } from '../../../../../redux/hooks';
import Box from '@mui/material/Box';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useNavigate } from 'react-router-dom';

// Components 

import { useTranslation } from 'react-i18next';
import Categories from './Categories';
import Actions from './sections/actions/Actions';
import Header from './Header';
import Notes from './sections/notes/Notes';
import { setAttachedFiles, setCategory, setImages, setIsImageSlideOpened, setIsNewFileAttached, setIsNewImageUploaded, setIsNewNoteCreated, setIsNewTemplateCreated, setIsRefreshContent, setNotes, setTemplates } from '../../../../../redux/features/doctor/Dashboard/consultation/consultation';
import { setNotificationMessage } from '../../../../../redux/features/general/notification';
import Files from './sections/files/Files';
import Images from './sections/images/Images';
import { getAttachedFiles, getConsultationImages, getConsultationNotes, getNoteTemplates } from '../../../../../routes/doctor/consultation';
import Footer from './footer/Footer';
import { setSelectedImage } from '../../../../../redux/features/doctor/Dashboard/documents/imageViewer';

const Dashboard: React.FC = () => {

  const dispatch = useAppDispatch(); 
  const navigate = useNavigate(); 

  const { t } = useTranslation(); 

  const isSmallScreen = useMediaQuery('(max-width: 1300px)');
  const leftOffset = isSmallScreen ? '60px' : '5vw';

  const colors = useAppSelector(state => state.theme.colors);

  const category = useAppSelector(state => state.consultation.category); 
  const consultationId = useAppSelector(state => state.consultation.consultationId); 
  const isNewNoteCreated = useAppSelector(state => state.consultation.isNewNoteCreated); 
  const isNewFileAttached = useAppSelector(state => state.consultation.isNewFileAttached); 
  const isNewImageUploaded = useAppSelector(state => state.consultation.isNewImageUploaded); 
  const isNewTemplateCreated = useAppSelector(state => state.consultation.isNewTemplateCreated); 
  const isRefreshContent = useAppSelector(state => state.consultation.isRefreshContent); 

  // -- 

  const handleConsultationNotesList = async () => { 

      const res = await getConsultationNotes(consultationId) as any; 

      if (res.data.status === 200) { 

          dispatch(setNotes(res.data.consultationNotes)); 

      }; 

  }; 

  // -- 

  const handleAttachedFiles = async () => { 

      const res = await getAttachedFiles(consultationId) as any; 

      if (res.data.status === 200) { 

          dispatch(setAttachedFiles(res.data.attachedFiles)); 

      }; 

  }; 

  // -- 

  const handleImages = async (showSlide?: boolean) => { 

      const res = await getConsultationImages(consultationId) as any; 

      if (res.data.status === 200) { 

          dispatch(setImages(res.data.images)); 

          if (showSlide) { 

            dispatch(setSelectedImage(res.data.images[0])); 
            dispatch(setIsImageSlideOpened(true)); 

          };

      }; 

  }; 

    // -- 

    const handleTemplates = async () => { 

      const res = await getNoteTemplates() as any; 

      if (res.data.status === 200) { 

          dispatch(setTemplates(res.data.noteTemplates)); 

      }; 

  }; 

  // -- 

  useEffect(() => { 

    if (isNewNoteCreated) { 

        dispatch(setCategory('notes')); 

        dispatch(setIsNewNoteCreated(false)); 

        dispatch(setNotificationMessage(t('newNoteCreated'))); 

    }; 

  },[isNewNoteCreated]); 

  // -- 

  useEffect(() => { 

    if (isNewFileAttached) { 

        handleAttachedFiles(); 

        dispatch(setCategory('files')); 

        dispatch(setIsNewFileAttached(false)); 

        dispatch(setNotificationMessage(t('fileAttachedToConsultation'))); 

    }; 

  },[isNewFileAttached]); 

    // -- 

    useEffect(() => { 

      if (isNewImageUploaded) { 

          handleImages(true); 
  
          dispatch(setCategory('images')); 
  
          dispatch(setIsNewImageUploaded(false)); 
  
          dispatch(setNotificationMessage(t('imageUploadedToConsultation'))); 
  
      }; 
  
    },[isNewImageUploaded]); 

    // -- 

    useEffect(() => { 

      if (isNewTemplateCreated) { 

          handleTemplates(); 
  
          dispatch(setCategory('actions')); 
  
          dispatch(setIsNewTemplateCreated(false)); 
  
          dispatch(setNotificationMessage(t('templateCreated'))); 
  
      }; 
  
    },[isNewTemplateCreated]); 

    // -- 

    useEffect(() => { 

      if (isRefreshContent) { 

        handleConsultationNotesList(); 
        handleAttachedFiles(); 
        handleImages(); 
        handleTemplates(); 

        dispatch(setIsRefreshContent(false)); 

      }; 

    },[isRefreshContent]); 

  // --

  return (

    <Box 
        style={{ 
            display: 'flex', 
            flexDirection: 'row',
            alignItems: 'center', 
            justifyContent: 'space-evenly',
            width: '100%',
            height: '100%', 
        }}>

            <Categories /> 

            <Box 
            
                style={{ 
                    display: 'flex', 
                    flexDirection: 'column',
                    alignItems: 'center', 
                    width: '80%',
                    height: '95%', 
                    borderRadius: 10,
                    backgroundColor: colors?.container,
                    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.2)',
                    
                }}>

                    <Header /> 

                    { category === 'actions' && <Actions />}
                    { category === 'notes' && <Notes />}
                    { category === 'files' && <Files />}
                    { category === 'images' && <Images />}

                    { category !== 'actions' && <Footer />} 
                
            </Box>

        </Box>
  );
};

export default Dashboard;