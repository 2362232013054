import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { useAppDispatch, useAppSelector } from '../../../../../redux/hooks';

// Components 

import HeaderSection from '../header/HeaderSection';
import FilesList from './FilesList';
import Footer from '../footer/Footer';

const FilesSection: React.FC = () => {

    const dispatch = useAppDispatch(); 

    const colors = useAppSelector(state => state.theme.colors); 
    const mode = useAppSelector(state => state.fileExplorer.mode);

    return (

        <Box 
            
        style={{ 
            display: 'flex', 
            flexDirection: 'column',
            alignItems: 'center', 
            width: '80%',
            height: '95%', 
            borderRadius: 10,
            backgroundColor: colors?.container,
            boxShadow: '0 4px 6px rgba(0, 0, 0, 0.2)',
            
        }}>

            <HeaderSection />

            <FilesList />

            <Footer /> 
            
        </Box>

    );

};

export default FilesSection;