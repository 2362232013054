import { createSlice, PayloadAction } from '@reduxjs/toolkit';


interface InitialState {
    selectedFile: any,
    blobFileUrl: any,
    fileExtension: any,  
    fileName: any, 
    isFileViewerOpened: boolean; 
}

const initialState: InitialState = {
  selectedFile: null,
  blobFileUrl: null,
  fileExtension: null, 
  fileName: null, 
  isFileViewerOpened: false, 
};

export const fileViewerSlice = createSlice({
  name: 'fileViewer',
  initialState,
    reducers: {
    setSelectedFile: (state, action: PayloadAction<any>) => {
      state.selectedFile = action.payload 
    },
    setBlobFileUrl: (state, action: PayloadAction<any>) => {
      state.blobFileUrl = action.payload 
    },
    setFileExtension: (state, action: PayloadAction<any>) => {
      state.fileExtension = action.payload 
    },
    setFileName: (state, action: PayloadAction<any>) => {
      state.fileName = action.payload 
    },
    setIsFileViewerOpened: (state, action: PayloadAction<boolean>) => {
      state.isFileViewerOpened = action.payload 
    },
    resetFileViewer: (state) => { 

      state.selectedFile = null; 
      state.blobFileUrl = null; 
      state.fileExtension = null; 
      state.fileName = null; 
      state.isFileViewerOpened = false; 

    } 
  },
});

export const {
  setSelectedFile, 
  setBlobFileUrl, 
  setFileExtension, 
  setFileName,
  setIsFileViewerOpened,  
  resetFileViewer
} = fileViewerSlice.actions;

export default fileViewerSlice.reducer;