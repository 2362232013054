import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { useAppDispatch, useAppSelector } from '../../../../../redux/hooks';

// Components

import FolderIcon from '@mui/icons-material/Folder';

// Icons 

import ScheduleIcon from '@mui/icons-material/Schedule';
import PersonIcon from '@mui/icons-material/Person';
import DescriptionIcon from '@mui/icons-material/Description';
import PushPinOutlinedIcon from '@mui/icons-material/PushPinOutlined';
import DownloadIcon from '@mui/icons-material/Download';

type Props = {
    _id?: string; 
    path?: string; 
    title: string;
    description?: string;
    status?: string;
    folder?: string; 
    uploadedBy: string;
    pinned: Boolean; 
    relatedPatient?: string;
    createdAt?: Date;
    updatedAt?: Date;
};


const FolderRow: React.FC<Props> = ({ _id, path, title, pinned, description, status, folder, uploadedBy, relatedPatient, createdAt, updatedAt }) => {

    const dispatch = useAppDispatch(); 

    const colors = useAppSelector(state => state.theme.colors); 
    const searchSection = useAppSelector((state) => state.fileExplorer.searchSection); 

    const [srcUrl, setSrcUrl] = useState(''); 


    useEffect(() => { 

        if (path === 'Documents') { 

            setSrcUrl(require('../../../../../assets/icons/files/documents-column.png')); 

        } else if (path === 'Downloads') { 

            setSrcUrl(require('../../../../../assets/icons/files/downloads-column.png'))

        } else if (path === 'Recent') { 

            setSrcUrl(require('../../../../../assets/icons/files/recent-column.png'))

        } else if (path === 'Patients') { 

            setSrcUrl(require('../../../../../assets/icons/files/patients-column.png'))

        } else { 

            setSrcUrl(require('../../../../../assets/icons/files/folder-column.png')); 

        }; 

    },[path]); 

    return (

        <Box 
            sx={{
            display: 'flex', 
            flexDirection: 'row',
            alignItems: 'center', 
            width: '100%',
            height: 40,
            overflow: 'hidden',
            color: colors?.text,
            backgroundColor: searchSection === _id ? colors?.sectionHover : 'transparent',
            '&:hover': { backgroundColor: searchSection === _id ? colors?.selectedSectionHover : colors?.sectionHover }
        }}>

            {srcUrl &&
                
                <Box
                    component="img"
                    sx={{
                        width: 25,
                        marginLeft: 2, 
                    }}
                    src={srcUrl}
                />
            }

            <Box sx={{ width: '50%', fontSize: 14, marginLeft: 2, overflow: 'hidden', whiteSpace: 'nowrap', paddingRight: 2 }}>{title}</Box>

            { pinned && 
            
                <PushPinOutlinedIcon 
                    sx={{ 
                        color: colors?.pinIconColor, 
                        fontSize: 15, 
                        marginLeft: 10,
                        right: 10, 
                        position: 'absolute', 
                }}/>}

        </Box>

    );

};

export default FolderRow;