import React, { useState, useEffect } from 'react'; 
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import Box from '@mui/material/Box';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useNavigate } from 'react-router-dom';

// Components 

import { useTranslation } from 'react-i18next';
import SelectPatient from './selectPatient/SelectPatient';
import Dashboard from './dashboard/Dashboard';
import Popup from '../../../general/Popup';
import ExitPopup from './popups/ExitPopup';
import LoadingPopup from './dashboard/sections/files/LoadingPopup';
import ImageLoadingPopup from './dashboard/sections/images/ImageLoadingPopup';
import ConfirmationPopup from './popups/ConfirmationPopup';
import { setClosePopup, setOpenPopup, setPopupAction } from '../../../../redux/features/general/popup';
import { getConsultation } from '../../../../routes/doctor/consultation';
import { setNotificationMessage } from '../../../../redux/features/general/notification';
import AccessPopup from './popups/AccessPopup';
import CameraPopup from './popups/CameraPopup';
import { setIsNewImageUploaded, setSecurityPin } from '../../../../redux/features/doctor/Dashboard/consultation/consultation';
import NewImageSlide from './popups/NewImageSlide';
import CreatingNote from './popups/CreatingNote';

const Consultation: React.FC = () => {

  const dispatch = useAppDispatch(); 
  const navigate = useNavigate(); 

  const { t } = useTranslation(); 

  const isSmallScreen = useMediaQuery('(max-width: 1300px)');
  const leftOffset = isSmallScreen ? '60px' : '5vw';

  const colors = useAppSelector(state => state.theme.colors);
  const isPopupOpened = useAppSelector((state) => state.popup.isPopupOpened); 
  const action = useAppSelector(state => state.popup.action); 
  const consultationId = useAppSelector(state => state.consultation.consultationId); 
  const section = useAppSelector(state => state.consultation.section); 
  const isImageSlideOpened = useAppSelector(state => state.consultation.isImageSlideOpened); 

  const [timeLeft, setTimeLeft] = useState(2); 

  // -- 

  const verifyConsultation = async () => { 

      const res = await getConsultation(consultationId) as any; 

      if (res.data.status === 200) { 

          if (res.data.isNewImageUploaded) { 

            dispatch(setIsNewImageUploaded(true)); 

          }; 

          if (res.data.isAccessRequested) { 

              dispatch(setSecurityPin(res.data.consultation.securityPin)); 

              dispatch(setPopupAction('securityPin')); 
              dispatch(setOpenPopup()); 

          }; 

          if (res.data.isDeviceConnected) { 

            dispatch(setNotificationMessage(t('deviceConnected'))); 

            if (isPopupOpened && (action === 'securityPin')) { 

              dispatch(setClosePopup()); 

            }; 

          }; 

      }; 

  }; 

  // -- 

  useEffect(()=>{

      let myInterval = setInterval(() => {

          if (timeLeft > 0) {

              setTimeLeft(timeLeft - 1);

          }; 

          if (timeLeft === 1) { 

            if (consultationId) { 

              verifyConsultation(); 

            };

            setTimeLeft(2); 

          }; 

          if (timeLeft === 0) {

              if (timeLeft === 0) {
                  clearInterval(myInterval)
              };
          }; 

      }, 500); 

      return ()=> {
          clearInterval(myInterval);
      };

  },[timeLeft]);

  // --

  return (
    <Box sx={{ 
      display: 'flex', 
      flexDirection: 'column', 
      alignItems: 'center',
      justifyContent: 'space-evenly',
      width: '95vw',
      height: '95vh', 
      left: leftOffset,
      bottom: 0,
      position: 'absolute',
      backgroundColor: colors?.interface,
      transition: 'left 0.3s',
      overflow: 'hidden'
    }}>

        { section === 'selectPatient' && <SelectPatient />}

        { section === 'dashboard' && <Dashboard />}

        {isPopupOpened &&
          <Popup>
            {action === 'quitConsultation' && <ExitPopup />}
            {action === 'saveConsultation' && <ConfirmationPopup />}
            {action === 'uploadFile' && <LoadingPopup />}
            {action === 'uploadImage' && <ImageLoadingPopup />}
            {action === 'securityPin' && <AccessPopup />}
            {action === 'creatingNote' && <CreatingNote />}
            {action === 'camera' && <CameraPopup />}
          </Popup>}

        {isImageSlideOpened && <NewImageSlide />} 

    </Box>
  );
};

export default Consultation;