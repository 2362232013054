import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { useAppDispatch, useAppSelector } from '../../../../../../redux/hooks';

// Redux 

// Components 

import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
import { setAttachedFiles, setSelectedAttachedFile } from '../../../../../../redux/features/doctor/Dashboard/consultation/consultation';
import { editAttachedFile, getAttachedFiles } from '../../../../../../routes/doctor/consultation';
import { setNotificationMessage } from '../../../../../../redux/features/general/notification';

type Props = {
    children?: React.ReactNode;
};


const FileOptions: React.FC<Props> = ({ children }) => {

    const { t } = useTranslation(); 
    const dispatch = useAppDispatch(); 

    const colors = useAppSelector(state => state.theme.colors);

    const selectedAttachedFile = useAppSelector((state) => state.consultation.selectedAttachedFile); 
    const consultationId = useAppSelector((state) => state.consultation.consultationId); 


    const [title, setTitle] = useState(''); 

    const [isTitleModified, setIsTitleModified] = useState(false); 

    // -- 

    const handleInput = (key: string) => { 

        if (key == 'Enter') { 

            handleTitleChange(); 

        } else if (key === 'Escape') { 

            dispatch(setSelectedAttachedFile('')); 

        };

    };

    // -- 

    const handleTitleChange = async () => { 

        const res = await editAttachedFile(selectedAttachedFile._id, title); 

        if (res?.data.status === 200) { 

            dispatch(setNotificationMessage(t('fileUpdatedOK'))); 
            dispatch(setSelectedAttachedFile('')); 

            const res = await getAttachedFiles(consultationId) as any; 

            if (res.data.status === 200) { 

                dispatch(setAttachedFiles(res.data.attachedFiles)); 

            }; 

        };  

    }; 

    // -- 

    const handleUnSelection = () => { 

        dispatch(setSelectedAttachedFile('')); 

    }; 

    // -- 

    useEffect(() => { 

        if (selectedAttachedFile) { 

            setTitle(selectedAttachedFile.title); 

        }; 

    },[selectedAttachedFile]); 

    // -- 

    useEffect(() => { 

        if (title) { 

            if (title === selectedAttachedFile?.title) { 

                setIsTitleModified(false); 

            } else { 

                setIsTitleModified(true); 

            }; 

        }; 

    },[title]); 

    // -- 

    return (

        <Box sx={{ 
            display: 'flex', 
            flexDirection: 'row',
            alignItems: 'center', 
            width: '100%', 
            justifyContent: 'space-between', 
            marginRight: 10, 
            maxHeight: 50, 
        }}>

            <Box
                sx={{ 
                    display: 'flex', 
                    flexDirection: 'row',
                    alignItems: 'center', 
                    maxHeight: 40,
                    paddingTop: 1, 
                    paddingBottom: 1, 
                    paddingLeft: 2,
                    paddingRight: 2,
                    borderRadius: 4,
                    marginLeft: 2
                }}
            >

                <InsertDriveFileIcon sx={{ color: colors?.driveIcon, fontSize: 20, marginRight: 5 }} />

                <TextField 
                    sx={{ 
                        width: 500,
                        color: colors?.text, 
                        textAlign: 'left',
                    }} 
                    onChange={(e) => setTitle(e.target.value)}
                    InputProps={{ 
                        style: { 
                            color: colors?.text,
                            fontSize: 12, 
                        }
                    }}
                    id="standard-basic" 
                    value={title}
                    variant="standard" 
                    autoFocus={true}
                    // error={error}
                    // helperText={errorMessage}
                    onKeyDown={(e) => handleInput(e.key)}
                />

            </Box>

            <Box style={{ 
                    display: 'flex', 
                    flexDirection: 'row',
                    alignItems: 'center', 
                    height: 27, 
                    marginBottom: 2,
                    right: '3%',
                    position: 'absolute'
                }}>

                { isTitleModified ? 
                <Button onClick={() => handleTitleChange()} sx={{ marginLeft: 5 }} variant="contained">{t('confirm')}</Button>: 
                <Button 
                disabled={true}
                sx={{ 
                    backgroundColor: 'transparent',
                    borderColor: 'lightblue',
                    marginLeft: 5
                }}
                variant="outlined">{t('confirm')}</Button>}

                <Button 
                    onClick={() => handleUnSelection()} 
                    sx={{ 
                        marginLeft: 1,
                        backgroundColor: colors?.closeButtonBackground,
                        borderColor: colors?.closeButtonBackground,
                        textDecoration: 'none',
                        '&:hover': 
                        { 
                            backgroundColor: colors?.closeButtonBackground,
                            color: colors?.closeButtonText,
                            borderColor: colors?.closeButtonBackground
                        }
                    }}
                    variant="contained">{t('cancel')}</Button>
                    
            </Box>
            
        </Box>
    );

};

export default FileOptions;