import React, { useState, useEffect, useRef } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import { useTranslation } from 'react-i18next';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';

// Components 

import Box from '@mui/material/Box';
import MicNoneIcon from '@mui/icons-material/MicNone';
import PauseIcon from '@mui/icons-material/Pause';
import { editConsultationNote } from '../../../../routes/doctor/consultation';

// -- 

const Microphone: React.FC = () => {

    const dispatch = useAppDispatch(); 
    const { t } = useTranslation(); 
    const navigate = useNavigate(); 

    const { transcript, listening, resetTranscript, browserSupportsSpeechRecognition } = useSpeechRecognition();

    const colors = useAppSelector(state => state.theme.colors); 
    const consultationNoteId = useAppSelector(state => state.noteMicrophone.consultationNoteId); 
    const language = useAppSelector(state => state.noteMicrophone.language); 
    const securityPin = useAppSelector(state => state.noteMicrophone.securityPin); 
    const mode = useAppSelector(state => state.noteMicrophone.mode); 
    const selectedNoteField = useAppSelector(state => state.noteMicrophone.selectedNoteField); 

    const [timeLeft, setTimeLeft] = useState(0); 

    // -- 

    const handleMicrophone = () => { 

        if (listening) { 

            setTimeLeft(2); 

        } else { 

            if (mode === 'autoComplete') { 

                SpeechRecognition.startListening({ continuous: true, language: language });

            } else { 

                SpeechRecognition.startListening({ language: language });

            }; 

        }; 

    }; 

    // -- 

    const handleNewText = async () => { 

        if (mode === 'autoComplete') { 

            const content = { 
                consultationNoteId, 
                newDescription: transcript,
                mode: 'autoComplete',
                securityPin: securityPin, 
                selectedNoteFieldId: selectedNoteField._id, 
            }; 

            await editConsultationNote(content) as any; 

        } else { 

            const content = { 
                consultationNoteId, 
                newDescription: transcript,
                mode: 'speaking',
                securityPin: securityPin, 
            }; 

            await editConsultationNote(content) as any; 

        }; 

        resetTranscript(); 

    }; 

    // --

    useEffect(() => { 

        if (!listening && transcript) { 

            handleNewText(); 

        }; 

    },[listening]); 

    // -- 
  
    useEffect(()=>{
  
        let myInterval = setInterval(() => {
  
            if (timeLeft > 0) {
  
                setTimeLeft(timeLeft - 1);
  
            }; 
  
            if (timeLeft === 1) { 
  
                SpeechRecognition.stopListening(); 

            }; 
  
            if (timeLeft === 0) {
  
                if (timeLeft === 0) {
                    clearInterval(myInterval)
                };
            }; 
  
        }, 500); 
  
        return ()=> {
            clearInterval(myInterval);
        };
  
    },[timeLeft]);

    // -- 

    return (

        <Box
            onClick={handleMicrophone}
            sx={{ 
                display: 'flex',
                flexDirection: 'row', 
                alignItems: 'center', 
                justifyContent: 'space-evenly', 
                borderRadius: '50%', 
                width: 100, 
                height: 100,
                backgroundColor: listening ? colors?.microphoneEnabled : colors?.microphoneDisabled,
            }}
        >

        {((mode === 'autoComplete') && listening) ? 
            <PauseIcon sx={{ fontSize: 50, color: colors?.microphoneIcon }} />:
            <MicNoneIcon sx={{ fontSize: 50, color: colors?.microphoneIcon }} />}

        </Box>

  );

};

export default Microphone;