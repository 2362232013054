import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

// Components

import TextField from '@mui/material/TextField';
import SearchIcon from '@mui/icons-material/Search';
import { setAction, setManualAttributeSearchQuery, setSelectedAttribute } from '../../../../redux/features/doctor/Dashboard/consultation/consultationNote';


const SearchBar: React.FC = () => {

    const { t } = useTranslation();
    const navigate = useNavigate(); 
    const dispatch = useAppDispatch(); 

    const colors = useAppSelector((state) => state.theme.colors);
    const searchQuery = useAppSelector((state) => state.consultationNote.searchQuery);
    const hightlightedAttribute = useAppSelector((state) => state.consultationNote.hightlightedAttribute);

    const [text, setText] = useState(''); 

    // -- 

    const handleText = (e: any) => { 

        setText(e.target.value); 

        dispatch(setManualAttributeSearchQuery(e.target.value)); 

    }; 

    // -- 

    const handleKey = (e: any) => { 

        if (e.key === 'Enter') { 

            dispatch(setSelectedAttribute(hightlightedAttribute)); 
            dispatch(setAction('newAttribute')); 

        }; 

    }; 

    // -- 

    return (
                
        <Box
            sx={{ 
                display: 'flex', 
                flexDirection: 'row',
                alignItems: 'center',
                width: '100%', 
                height: 40,
                fontSize: 12,  
                borderRadius: 1, 
            }}
        >
            <Box
                style={{ 
                    display: 'flex', 
                    flexDirection: 'row',
                    alignItems: 'center',
                    marginLeft: 10
                }}
            >

                <SearchIcon sx={{ fontSize: 18, color: 'rgb(150, 150, 150)' }}/>

                <TextField
                    id="note-attributes-search"
                    placeholder={t('search') || 'Search'}
                    variant="standard"
                    value={text}
                    InputProps={{
                        style: { 
                            marginLeft: 3, 
                            fontSize: 12, 
                            color: colors?.text 
                        },
                        disableUnderline: true, 
                    }}
                    onChange={handleText}
                    onKeyDown={handleKey}
                    autoComplete='off'
                    sx={{
                        marginLeft: 1,
                        fontSize: 12,
                        width: 200, 
                        borderBottomColor: colors?.searchIcon
                    }}
                />

            </Box>

    </Box>

    );
};

export default SearchBar;