import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import { useAppDispatch, useAppSelector } from '../../../../../../../redux/hooks';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { setIsRefreshContent, setSelectedTemplate, setTemplates } from '../../../../../../../redux/features/doctor/Dashboard/consultation/consultation';
import { createNoteWithTemplate, deleteConsultationNote, duplicateTemplate, getNoteTemplates } from '../../../../../../../routes/doctor/consultation';
import { setClosePopup, setOpenPopup, setPopupAction } from '../../../../../../../redux/features/general/popup';
import { setNotificationMessage } from '../../../../../../../redux/features/general/notification';

type Props = {
    children?: React.ReactNode;
    section: string; 
};


const OptionRow: React.FC<Props> = ({ children, section }) => {

    const { t } = useTranslation();
    const navigate = useNavigate(); 
    const dispatch = useAppDispatch(); 

    const colors = useAppSelector((state) => state.theme.colors);
    const location = useLocation();

    const consultationId = useAppSelector(state => state.consultation.consultationId);
    const selectedTemplate = useAppSelector(state => state.consultation.selectedTemplate); 

    const [timeLeft, setTimeLeft] = useState(0); 
    const [templateRedirectionUrl, setTemplateRedirectionUrl] = useState(''); 

    // -- 

    const handleOptions = async () => { 

        if (section === 'useTemplate') { 

            dispatch(setSelectedTemplate('')); 

            const res = await createNoteWithTemplate(selectedTemplate._id, consultationId) as any; 

            if (res.data.status === 200) { 

                setTimeLeft(3); 

                dispatch(setPopupAction('creatingNote')); 
                dispatch(setOpenPopup()); 

                setTemplateRedirectionUrl(`/consultation/note/${consultationId}/${res.data.consultationNote._id}`); 

                dispatch(setSelectedTemplate('')); 

            }; 
            
        } else if (section === 'duplicateTemplate') { 

            const res = await duplicateTemplate(selectedTemplate._id) as any; 

            if (res.data.status === 200) { 

                dispatch(setNotificationMessage(t('templateDuplicated'))); 

                dispatch(setIsRefreshContent(true)); 

            }; 

            dispatch(setSelectedTemplate('')); 
            
        } else if (section === 'editTemplate') { 

            navigate(`/consultation/note/template/${consultationId}/${selectedTemplate._id}`); 

            dispatch(setSelectedTemplate('')); 

        } else if (section === 'deleteTemplate')  {

            const res = await deleteConsultationNote(selectedTemplate._id) as any; 

            if (res.data.status === 200) { 

                dispatch(setNotificationMessage(t('templateDeleted'))); 

                dispatch(setIsRefreshContent(true)); 

            }; 

            dispatch(setSelectedTemplate('')); 

        }; 

    }; 

    // -- 

    useEffect(()=>{

        let myInterval = setInterval(() => {

            if (timeLeft > 0) {

                setTimeLeft(timeLeft - 1);

            }; 

            if (timeLeft === 1) { 

                dispatch(setClosePopup()); 

                navigate(templateRedirectionUrl); 

            }; 

            if (timeLeft === 0) {

                if (timeLeft === 0) {
                    clearInterval(myInterval)
                };
            }; 

        }, 500); 

        return ()=> {
            clearInterval(myInterval);
        };

    },[timeLeft]);

    // -- 

    return (
                
        <Box
            onClick={handleOptions}
            sx={{ 
                display: 'flex', 
                flexDirection: 'row',
                alignItems: 'center',
                width: '100%', 
                height: 40,
                fontSize: 12,  
                borderRadius: 1,
                cursor: 'pointer', 
                '&:hover': { backgroundColor: colors?.sectionHover}
            }}
        >
            <Box
                style={{ 
                    display: 'flex', 
                    flexDirection: 'row',
                    alignItems: 'center',
                    marginLeft: 10,
                    color: colors?.text
                }}
            >
                {children}
                <Box sx={{ marginLeft: 3 }}>{t(section)}</Box>
            </Box>

    </Box>

    );
};

export default OptionRow;