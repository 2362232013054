import React, { useState, useEffect, useRef } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../../../../redux/hooks';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

// Components 

import Box from '@mui/material/Box';
import { serveFile } from '../../../../../../../routes/doctor/files';
import Options from './Options';

interface Props { 
    template: any; 
}

const Template: React.FC<Props> = ({ template }) => {

    const dispatch = useAppDispatch(); 
    const { t } = useTranslation(); 
    const navigate = useNavigate(); 

    const colors = useAppSelector(state => state.theme.colors); 
    const selectedTemplate = useAppSelector(state => state.consultation.selectedTemplate); 

    const [thumbnailUrl, setThumbnailUrl] = useState(''); 

    // -- 

    const handleFileThumbnail = async () => { 

        const res = await serveFile(template?._id, 'note', true) as any; 
        
        const fileBlob = new Blob([res.data], { type: res.headers['content-type'] });

        setThumbnailUrl(URL.createObjectURL(fileBlob)); 

    }; 

    // -- 

    useEffect(() => { 

        if (template?.previewUrl) { 

            handleFileThumbnail(); 

        } else { 

            setThumbnailUrl(require('../../../../../../../assets/consultation/blank.png')); 

        }; 

    },[template]); 

    // -- 
    

    return (

      <Box sx={{ 
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center', 
          width: '100%',
          height: '100%', 
      }}>

        <Box 
            sx={{ 
            display: 'flex', 
            flexDirection: 'column',
            alignItems: 'center', 
            width: 150,
            marginLeft: 5,
            marginRight: 5,
            borderRadius: 3,
            marginTop: 1, 
            height: 200, 
            cursor: 'pointer',
            overflow: 'hidden', 
            backgroundColor: (selectedTemplate?._id == template._id) ? 'rgba(77, 75, 89, 0.2)' : 'transparent',
            '&:hover': { backgroundColor: 'rgba(77, 75, 89, 0.2)' }
        }}>

            <Box
                component="img"
                sx={{
                    width: 100,
                    marginTop: 1, 
                    marginBottom: 2, 
                    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.2)',
                    backgroundColor: 'white', 
                }}
                src={thumbnailUrl}
            />

            <div style={{ 
                width: '95%',
                color: colors?.text, 
                textAlign: 'center',
                overflow: 'hidden',
                fontSize: 12, 
                whiteSpace: 'wrap'
            }}>{template?.title}</div>

        </Box>

        { (selectedTemplate?._id == template._id) && <Options />}

      </Box>

  );

};

export default Template;