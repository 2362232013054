import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

// Components
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import TitleIcon from '@mui/icons-material/Title';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import EventNoteIcon from '@mui/icons-material/EventNote';
import WcIcon from '@mui/icons-material/Wc';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import MedicalServicesIcon from '@mui/icons-material/MedicalServices';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import AddHomeIcon from '@mui/icons-material/AddHome';
import EmailIcon from '@mui/icons-material/Email';
import FamilyRestroomIcon from '@mui/icons-material/FamilyRestroom';
import TollIcon from '@mui/icons-material/Toll';
import NotesIcon from '@mui/icons-material/Notes';
import { setAction, setSearchQuery, setSelectedAttribute } from '../../../../redux/features/doctor/Dashboard/consultation/consultationNote';

type Props = {
    children?: React.ReactNode;
    attribute: string;
    index: number; 
};


const AttributeRow: React.FC<Props> = ({ children, attribute, index }) => {

    const { t } = useTranslation();
    const navigate = useNavigate(); 
    const dispatch = useAppDispatch(); 

    const colors = useAppSelector((state) => state.theme.colors);
    const hightlightedAttribute = useAppSelector((state) => state.consultationNote.hightlightedAttribute);

    const [attributeColor, setAttributeColor] = useState(''); 
    const [iconUrl, setIconUrl] = useState(''); 

    // -- 

    const handleAction = async () => { 

        dispatch(setSelectedAttribute(attribute)); 
        dispatch(setAction('newAttribute')); 

    }; 

    // -- 

    useEffect(() => { 

        if (attribute) { 

            attribute === 'patientId' && setIconUrl(require('../../../../assets/logos/emblem.png')); 
            attribute === 'firstName' && setIconUrl(require('../../../../assets/icons/patient/firstName.png')); 
            attribute === 'lastName' && setIconUrl(require('../../../../assets/icons/patient/lastName.png')); 
            attribute === 'gender' && setIconUrl(require('../../../../assets/icons/patient/sex.png')); 
            attribute === 'dateOfBirth' && setIconUrl(require('../../../../assets/icons/patient/dateOfBirth.png')); 
            attribute === 'healthCardNumber' && setIconUrl(require('../../../../assets/icons/patient/healthCardNumber.png')); 
            attribute === 'phone' && setIconUrl(require('../../../../assets/icons/patient/phone.png')); 
            attribute === 'address' && setIconUrl(require('../../../../assets/icons/patient/address.png'));   
            attribute === 'email' && setIconUrl(require('../../../../assets/icons/patient/email.png')); 
            attribute === 'emergencyContactName' && setIconUrl(require('../../../../assets/icons/patient/name.png')); 
            attribute === 'emergencyContactRelation' && setIconUrl(require('../../../../assets/icons/patient/relation.png')); 
            attribute === 'emergencyContactPhone' && setIconUrl(require('../../../../assets/icons/patient/phone.png')); 

        } else { 

            setAttributeColor('rgb(150, 150, 150)');

        };

    },[attribute]); 

    // -- 

    return (
                
        <Box
            onClick={handleAction}
            sx={{ 
                display: 'flex', 
                flexDirection: 'row',
                alignItems: 'center',
                width: '100%', 
                height: 40,
                fontSize: 12,  
                borderRadius: 1, 
                backgroundColor: (attribute === hightlightedAttribute) ? colors?.sectionHover : 'transparent', 
                '&:hover': { backgroundColor: colors?.sectionHover}
            }}
        >
            <Box
                style={{ 
                    display: 'flex', 
                    flexDirection: 'row',
                    alignItems: 'center',
                    marginLeft: 10
                }}
            >
                <Box component="img" sx={{ width: 18 }} src={iconUrl} />

                <Box sx={{ marginLeft: 3 }}>{t(attribute)}</Box>
            </Box>

    </Box>

    );
};

export default AttributeRow;