import React from 'react';
import { Container, Box, Typography, Link, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import LanguageSelector from '../components/general/navBar/LanguageSelector';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import Logo from '../components/general/navBar/Logo';
import ThemeToggle from '../components/general/navBar/ThemeToggle';
import Footer from '../components/general/navigation/Footer';
import { useAppSelector } from '../redux/hooks';
import NavBar from '../components/general/navBar/NavBar';
import Settings from '../components/doctor/navigation/navBar/Settings';

const Tos: React.FC = () => {

  const { t, ready } = useTranslation();
  const navigate = useNavigate();
  const theme = useTheme();

  if (!ready) {
    return <div>Loading...</div>;
  }

  const tosContent = t('tosContent', { returnObjects: true }) as any;

  const colors = useAppSelector(state => state.theme.colors); 

  const paragraphStyling = { 
    marginBottom: 2,
    color: colors?.text
  };

  const titleStyling = { 
    marginBottom: 2, 
    color: colors?.legalHeader
  };

  const subTitleStyling = { 
    marginBottom: 2, 
    color: colors?.legalSubTitle
  }

  return (
    <Box
      component="main"
      maxWidth="xs"
      sx={{
        display: 'flex', 
        flexDirection: 'column', 
        alignItems: 'center', 
        height: '100vh', 
        width: '100vw',
        left: 0, 
        overflowX: 'hidden',
        position: 'absolute',
        color: colors?.highlightedText,
        backgroundColor: colors?.interface
    }}
    >

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems:'center',
          width: '50%',
          height: 'auto', 
          marginTop: '10vh', 
          borderRadius: 4, 
          padding: 10, 
          marginBottom: 10, 
          backgroundColor: colors?.container,
          color: colors?.text,
          boxShadow: '0 4px 6px rgba(0, 0, 0, 0.2)',
        }}
      >

      <Box sx={{ mb: 2 }}>
          <Logo size={30} />
        </Box>

      <Typography
          component="h1"
          variant="h5"
          sx={titleStyling}
        >
          {t('termsOfService')}
        </Typography>

        <Box sx={{ textAlign: 'justify', width: '100%', marginTop: 2, marginBottom: 4 }}>
          <Typography
            variant="h6"
            sx={subTitleStyling}
          >
            {tosContent?.acceptanceOfTerms?.title}
          </Typography>
          <Typography
            variant="body1"
            sx={paragraphStyling}
          >
            {tosContent?.acceptanceOfTerms?.description}
          </Typography>

          <Typography
            variant="h6"
            sx={subTitleStyling}
          >
            {tosContent?.platformUsage?.title}
          </Typography>
          <Typography
            variant="body1"
            sx={paragraphStyling}
          >
            {tosContent?.platformUsage?.description}
          </Typography>
          <Box sx={{ pl: 4 }}>
            <ul>
              {tosContent?.platformUsage?.usage1 && <li>{tosContent.platformUsage.usage1}</li>}
              {tosContent?.platformUsage?.usage2 && <li>{tosContent.platformUsage.usage2}</li>}
            </ul>
          </Box>

          <Typography
            variant="h6"
            sx={subTitleStyling}
          >
            {tosContent?.accountManagement?.title}
          </Typography>
          <Typography
            variant="body1"
            sx={paragraphStyling}
          >
            {tosContent?.accountManagement?.description}
          </Typography>
          <Box sx={{ pl: 4 }}>
            <ul>
              {tosContent?.accountManagement?.management1 && <li>{tosContent.accountManagement.management1}</li>}
              {tosContent?.accountManagement?.management2 && <li>{tosContent.accountManagement.management2}</li>}
              {tosContent?.accountManagement?.management3 && <li>{tosContent.accountManagement.management3}</li>}
            </ul>
          </Box>

          <Typography
            variant="h6"
            sx={subTitleStyling}
          >
            {tosContent?.dataProtection?.title}
          </Typography>
          <Typography
            variant="body1"
            sx={paragraphStyling}
          >
            {tosContent?.dataProtection?.description}
          </Typography>

          <Typography
            variant="h6"
            sx={subTitleStyling}
          >
            {tosContent?.serviceAvailability?.title}
          </Typography>
          <Typography
            variant="body1"
            sx={paragraphStyling}
          >
            {tosContent?.serviceAvailability?.description}
          </Typography>

          <Typography
            variant="h6"
            sx={subTitleStyling}
          >
            {tosContent?.limitationOfLiability?.title}
          </Typography>
          <Typography
            variant="body1"
            sx={paragraphStyling}
          >
            {tosContent?.limitationOfLiability?.description}
          </Typography>

          <Typography
            variant="h6"
            sx={subTitleStyling}
          >
            {tosContent?.governingLaw?.title}
          </Typography>
          <Typography
            variant="body1"
            sx={paragraphStyling}
          >
            {tosContent?.governingLaw?.description}
          </Typography>

          <Typography
            variant="h6"
            sx={subTitleStyling}
          >
            {tosContent?.userConduct?.title}
          </Typography>
          <Typography
            variant="body1"
            sx={paragraphStyling}
          >
            {tosContent?.userConduct?.description}
          </Typography>
          <Box sx={{ pl: 4 }}>
            <ul>
              {tosContent?.userConduct?.conduct1 && <li>{tosContent.userConduct.conduct1}</li>}
              {tosContent?.userConduct?.conduct2 && <li>{tosContent.userConduct.conduct2}</li>}
              {tosContent?.userConduct?.conduct3 && <li>{tosContent.userConduct.conduct3}</li>}
            </ul>
          </Box>

          <Typography
            variant="h6"
            sx={subTitleStyling}
          >
            {tosContent?.intellectualProperty?.title}
          </Typography>
          <Typography
            variant="body1"
            sx={paragraphStyling}
          >
            {tosContent?.intellectualProperty?.description}
          </Typography>

          <Typography
            variant="h6"
            sx={subTitleStyling}
          >
            {tosContent?.termination?.title}
          </Typography>
          <Typography
            variant="body1"
            sx={paragraphStyling}
          >
            {tosContent?.termination?.description}
          </Typography>

          <Typography
            variant="h6"
            sx={subTitleStyling}
          >
            {tosContent?.modificationOfTerms?.title}
          </Typography>
          <Typography
            variant="body1"
            sx={paragraphStyling}
          >
            {tosContent?.modificationOfTerms?.description}
          </Typography>

          <Typography
            variant="h6"
            sx={subTitleStyling}
          >
            {tosContent?.disputeResolution?.title}
          </Typography>
          <Typography
            variant="body1"
            sx={paragraphStyling}
          >
            {tosContent?.disputeResolution?.description}
          </Typography>

          <Typography
            variant="h6"
            sx={subTitleStyling}
          >
            {tosContent?.disclaimerOfWarranties?.title}
          </Typography>
          <Typography
            variant="body1"
            sx={paragraphStyling}
          >
            {tosContent?.disclaimerOfWarranties?.description}
          </Typography>

          <Typography
            variant="h6"
            sx={subTitleStyling}
          >
            {tosContent?.contactInformation?.title}
          </Typography>
          <Typography
            variant="body1"
            sx={paragraphStyling}
          >
            {tosContent?.contactInformation?.description}
          </Typography>

        </Box>

    </Box>

    <Footer isScrollPage={true} />

    <NavBar>
      <Settings />
    </NavBar>

    </Box>
  );
};

export default Tos;



