import React, { useState, useEffect, useRef } from 'react'; 
import { useAppDispatch, useAppSelector } from '../../../../../redux/hooks';
import Box from '@mui/material/Box';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useNavigate } from 'react-router-dom';

// Components 

import { useTranslation } from 'react-i18next';
import { serveFile } from '../../../../../routes/doctor/files';
import Header from '../items/Header';
import CircularProgress from '@mui/material/CircularProgress';
import { getConsultationNote } from '../../../../../routes/doctor/consultation';


const NoteViewer: React.FC = () => {

    const dispatch = useAppDispatch(); 
    const navigate = useNavigate(); 

    const { t } = useTranslation(); 

    const iframeRef = useRef<HTMLIFrameElement | null>(null);

    const isSmallScreen = useMediaQuery('(max-width: 1300px)');

    const colors = useAppSelector(state => state.theme.colors);

    const selectedNote = useAppSelector(state => state.noteViewer.selectedNote) as any; 

    const [fileUrl, setFileUrl] = useState(''); 

    // -- 

    const handleNote = async () => { 

        const res = await serveFile(selectedNote?._id, 'note') as any; 
        
        const fileBlob = new Blob([res.data], { type: res.headers['content-type'] });
    
        setFileUrl(URL.createObjectURL(fileBlob)); 

    }; 

    // -- 

    useEffect(() => { 

        if (selectedNote) { 

            handleNote(); 

        }; 

    },[selectedNote]); 

    // --

    return (

        <Box 
            style={{ 
                display: 'flex', 
                flexDirection: 'row',
                alignItems: 'center', 
                justifyContent: 'space-evenly',
                paddingTop: 50, 
                width: '100vw',
                height: '100vh', 
                backgroundColor: 'black', 
                overflow: 'hidden', 
                position: 'absolute', 
                zIndex: 1000000000
        }}>

            <Header title={selectedNote?.title} />

            {fileUrl ?
                    <iframe
                    ref={iframeRef}
                    src={fileUrl}
                    style={{ 
                        width: '100%', 
                        height: '90vh',
                    }}
                    title="PDF Viewer"
                    />: 
                <CircularProgress size={30} />}


            {/* <Box
                id='consultationNote'
                sx={{ 
                display: 'flex',
                flexDirection: 'column', 
                alignItems: 'center', 
                width: '100vw', 
                height: '95vh',
                borderRadius: 3,
                position: 'absolute',
                right: 0,
                bottom: 0, 
                overflow: 'scroll',
                overflowX: 'hidden', 
                scrollbarWidth: '1',  
                }}
        >

            <Box
                sx={{ 
                    borderWidth: 'none', 
                    border: 0,
                    marginTop: '3vh', 
                    cursor: 'default',
                    backgroundColor: 'rgb(255, 255, 255)',
                    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.2)',
                    height: '877px', 
                    width: '620px',
                    paddingLeft: '40px',
                    paddingRight: '40px', 
                    paddingTop: 5, 
                    color: colors?.text, 
                    position: 'absolute', 
                    // fontSize: 12, 
                    borderRadius: 3, 
                    marginBottom: 5, 
                }}
            >{selectedNote?.description}

            </Box>

        </Box>
                   
        { !selectedNote?.description && <CircularProgress size={30} />} */}

        </Box>
  );
};

export default NoteViewer;