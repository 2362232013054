import React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { useAppDispatch, useAppSelector } from '../../../../../redux/hooks';
// Redux 

import { setClosePopup } from '../../../../../redux/features/general/popup';
import { useTranslation } from 'react-i18next';
import { resetConsultation } from '../../../../../redux/features/doctor/Dashboard/consultation/consultation';
import { deleteConsultation, saveConsultation } from '../../../../../routes/doctor/consultation';
import { resetFileExplorer, setMode } from '../../../../../redux/features/doctor/Dashboard/documents/fileExplorer';
import { resetSavedConsultation, setPatientId } from '../../../../../redux/features/doctor/Dashboard/consultation/savedConsultation';
import { setNotificationMessage } from '../../../../../redux/features/general/notification';
import { setDashboardSection } from '../../../../../redux/features/general/navigation';

type Props = {
    children?: React.ReactNode,
    isPortraitMode?: boolean,
};

const ConfirmationPopup: React.FC<Props> = ({ children, isPortraitMode }) => {

    const dispatch = useAppDispatch();
    const { t } = useTranslation();

    const colors = useAppSelector(state => state.theme.colors);
    const consultationId = useAppSelector(state => state.consultation.consultationId); 
    const patientId = useAppSelector((state) => state.consultation.patientId); 

    // -- 

    // -- 

    const handleSaveConsultation = async () => {

        dispatch(resetFileExplorer()); 
        dispatch(resetSavedConsultation()); 

        const res = await saveConsultation(consultationId) as any;  

        if (res.data.status === 200) { 

            dispatch(setNotificationMessage(t('newConsultationCreated'))); 

            dispatch(setPatientId(patientId)); 
            
            dispatch(setMode('consultations')); 
            
            dispatch(resetConsultation()); 

            dispatch(setDashboardSection('documents')); 

            dispatch(setClosePopup());

        }; 

    }; 

    // -- 

    const goBackToConsultation = () => { 

        dispatch(setClosePopup()); 

    }; 


  return (
    <Box sx={{ 
        display: 'flex', 
        flexDirection: 'column',
        alignItems: 'center', 
        justifyContent: 'space-evenly',
        width: '100%',
        height: '100%',
        borderRadius: 5,
        backgroundColor: colors?.container,
        overflowY: 'auto',
        overflowX: 'hidden',
    }}>

        <Box>{t('saveConsultationWarning')}</Box>

        <Box
            sx={{ 
                display: 'flex', 
                flexDirection: 'column', 
                alignItems: 'center',
                width: '100%'}}>

            <Button 
                onClick={handleSaveConsultation}
                sx={{ 
                    minWidth: 200, 
                    marginBottom: 2, 
                    textTransform: 'none',
                    backgroundColor: colors?.createPatient 
                }} 
                variant="contained">{t('saveConsulationNote')}
            </Button>

            <Button 
                sx={{ 
                    width: 200,
                    backgroundColor: colors?.cancelButtonColor, 
                    borderColor: colors?.text,
                    color: colors?.text,
                    '&:hover': 
                    { 
                        backgroundColor: colors?.cancelButtonBackground,
                        color: colors?.text,
                        borderColor: colors?.cancelButtonBackground
                    }
                }}
                onClick={() => goBackToConsultation()}
                variant="outlined">{t('back')}
            </Button>

          </Box>

    </Box>
  );
};

export default ConfirmationPopup;