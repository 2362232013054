import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../../../../redux/hooks';
import Box from '@mui/material/Box';
import { serveFile } from '../../../../../../routes/doctor/files';
import { serveConsultationImage } from '../../../../../../routes/doctor/consultation';


type Props = {
  number: number;
  templateId: string, 
  fileUrl: string;
  title: string;
};

const Template: React.FC<Props> = ({ templateId, number, fileUrl, title }) => {

  const navigate = useNavigate(); 

  const colors = useAppSelector(state => state.theme.colors);

  const [blobFileUrl, setBlobFileUrl] = useState('');
  const [fileExtension, setFileExtension] = useState('');  

  const iframeRef = useRef<HTMLIFrameElement | null>(null);
  const containerRef = useRef<HTMLDivElement | null>(null);

  // -- 

  const handleFileThumbnail = async () => { 

    const res = await serveFile(templateId, 'template') as any; 
    
    const fileBlob = new Blob([res.data], { type: res.headers['content-type'] });

    setBlobFileUrl(URL.createObjectURL(fileBlob)); 

  }; 

  // -- 

  const handleSelection = async () => { 

    navigate(`/template/${templateId}`); 
    window.location.reload(); 

  }; 

// -- 

useEffect(() => { 

    handleFileThumbnail(); 

},[]); 

// -- 

  return (
    <Box 
      onClick={handleSelection}
      sx={{ 
        display: 'flex', 
        flexDirection: 'column',
        alignItems: 'center', 
        width: 150,
        height: 230,
        borderRadius: 5,
        marginLeft: 2, 
        marginRight: 2, 
        cursor: 'pointer',
        color: colors?.templateBoxText,
        fontSize: 12,
        '&:hover': { backgroundColor: colors?.templateHover, color: colors?.templateTextHover }
    }}>

    { fileUrl && 
      <Box
        component="img"
        sx={{
          height: '100%',
          width: '100%',
        }}
        src={blobFileUrl}
      />}

    </Box>
  );
};

export default Template;