
import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { useAppDispatch, useAppSelector } from '../../../../../redux/hooks';
import { useTranslation } from 'react-i18next';

// Redux 

// Components

import FieldRow from './FieldRow';
import { getNoteFields } from '../../../../../routes/doctor/consultation';
import { setIsRefreshFieldsList, setNoteFields } from '../../../../../redux/features/doctor/Dashboard/consultation/consultationNote';
import Header from './Header';

const PatientsList: React.FC = () => {

    const dispatch = useAppDispatch(); 
    const { t } = useTranslation();

    const colors = useAppSelector(state => state.theme.colors); 
    const consultationNoteId = useAppSelector(state => state.consultationNote.consultationNoteId); 
    const noteFields = useAppSelector(state => state.consultationNote.noteFields); 
    const fieldSearchQuery = useAppSelector(state => state.consultationNote.fieldSearchQuery); 
    const isRefreshFieldsList = useAppSelector(state => state.consultationNote.isRefreshFieldsList); 

    const [fields, setFields] = useState([]) as any; 
    const [timeLeft, setTimeLeft] = useState(2); 

    // -- 

    const handleNoteFields = async () => { 

        const res = await getNoteFields(consultationNoteId) as any; 

        if (res.data.status === 200) { 

            dispatch(setNoteFields(res.data.noteFields)); 

        }; 

    }; 

    // -- 

    useEffect(() => { 

        if (consultationNoteId) { 

            handleNoteFields(); 

        }; 

    },[consultationNoteId]); 

    // -- 

    useEffect(() => { 

        if (fieldSearchQuery) { 
            
            const filteredList = noteFields?.filter((noteField) => noteField.title.includes(fieldSearchQuery)); 

            setFields(filteredList);      

        }; 

    },[fieldSearchQuery]); 

    // -- 

    useEffect(() => { 

        if (isRefreshFieldsList) { 

            handleNoteFields(); 

            dispatch(setIsRefreshFieldsList(false)); 

        }; 

    },[isRefreshFieldsList]); 

    // -- 

    useEffect(() => { 

        if (noteFields) { 

            setFields(noteFields); 

        }; 

    },[noteFields]); 

    // -- 

    useEffect(()=>{

        let myInterval = setInterval(() => {

            if (timeLeft > 0) {

                setTimeLeft(timeLeft - 1);

            }; 

            if (timeLeft === 1) { 

                handleNoteFields(); 

                setTimeLeft(2); 

            }; 

            if (timeLeft === 0) {

                if (timeLeft === 0) {
                    clearInterval(myInterval)
                };
            }; 

        }, 500); 

        return ()=> {
            clearInterval(myInterval);
        };

    },[timeLeft]);

    // -- 

    return (

        <Box
            sx={{ 
                display: 'flex',
                flexDirection: 'column', 
                alignItems: 'center',
                width: '100%',
                marginTop: 1,
            }}
        >

            <Header /> 

            { fields?.length >= 1 && 

            <Box 
                
                sx={{ 
                overflow: 'auto',
                alignItems: 'center', 
                width: '100%',
                height: '100%',
                scrollbarWidth: 'none' }}>
        
                {fields?.map((item, index) => (
                    <FieldRow
                        key={index}
                        _id={item._id}
                        title={item.title}
                    />
                ))}
            
            </Box>}

            {(fields?.length === 0) &&   
          
            <Box
                sx={{ 
                    display: 'flex', 
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'space-around',
                    width: '100%',
                    height: '100%',
                }}>
            
                <Box
                    sx={{
                        width: 150,
                        height: 'auto', 
                        marginTop: 10,
                    }}
                    component="img"
                    alt="Solutions Medca Inc."
                    src={require('../../../../../assets/logos/empty_list.png')}
                />

                <Box sx={{ 
                    color: colors?.text,
                    fontSize: 12,
                    marginTop: 3, 
                    marginBottom: '20%',
                }}>{t('emptyNoteFields')}</Box>

            </Box>}

          </Box>

    );

};

export default PatientsList;