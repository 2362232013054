import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import { useTranslation } from 'react-i18next';

// Components 
 
import Box from '@mui/material/Box';
import AttributeRow from './AttributeRow';
import SearchBar from './SearchBar';


const AttributesDropdown: React.FC = () => {

    const colors = useAppSelector(state => state.theme.colors);

    const attributesQueryList = useAppSelector(state => state.consultationNote.attributesQueryList); 

    // -- 

    return ( 

        <Box
            sx={{ 
                display: 'flex', 
                flexDirection: 'row',
                alignItems: 'center', 
                justifyContent: 'space-evenly',
                width: 250,
                height: 'auto',
                top: '15vh', 
                right: '3vw', 
                position: 'absolute',
                backgroundColor: colors?.fileExplorerDropdown,
                boxShadow: '0 4px 6px rgba(0, 0, 0, 0.2)',
                borderRadius: 4,
                zIndex: 10000, 
                transition: 'width 0.3s',
            }}
        >

            <Box
                sx={{ 
                    display: 'flex', 
                    flexDirection: 'column',
                    alignItems: 'center', 
                    width: '100%', 
                    cursor: 'pointer',
                }}
            >

                <SearchBar />
                        
                {attributesQueryList?.map((item, index) => (
                        
                        <AttributeRow 
                            attribute={item} 
                            index={index}
                            key={index}>
                        </AttributeRow>

                ))}

            </Box>

        </Box>

    );

}; 

export default AttributesDropdown; 