import darkTheme from '../../../styles/themes/darkTheme';
import lightTheme from '../../../styles/themes/lightTheme';

const savedTheme = localStorage.getItem('theme');

const currentTheme = (savedTheme === 'dark') ? darkTheme : lightTheme as any; 

const menuItems = [
    { 
        type: 'item', 
        section: 'documents', 
        text: 'docs', 
        iconName: 'topic', 
        iconColor: currentTheme?.sidebarIconColor, 
        key:'i-documents' },
    { 
        type: 'item', 
        section: 'patients', 
        text: 'patients', 
        iconName: 'people', 
        iconColor: currentTheme?.sidebarIconColor, 
        key:'i-patients'},
    { 
        type: 'item', 
        section: 'templates', 
        text: 'templates', 
        iconName: 'description', 
        iconColor: currentTheme?.sidebarIconColor, 
        key:'i-templates',
        hidden: process.env.REACT_APP_BACKEND_URL?.includes('https') ? true : false }, 
    { 
        type: 'item', 
        section: 'consultation', 
        text: 'charting', 
        iconName: 'addBoxOutlined', 
        iconColor: currentTheme?.sidebarIconColor, 
        key:'i-consultation'}, 
];

export default menuItems; 