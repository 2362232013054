import React, { useState, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../../redux/hooks';
import { useTranslation } from 'react-i18next';
import { QRCodeCanvas } from 'qrcode.react';

// Components 

import Box from '@mui/material/Box';
import { useParams } from 'react-router-dom';

const QrCode: React.FC = () => {

    const dispatch = useAppDispatch(); 
    const { t } = useTranslation(); 

    const { consultationId } = useParams(); 

    const colors = useAppSelector(state => state.theme.colors);
    const consultationNoteId = useAppSelector(state => state.consultationNote.consultationNoteId); 
    const securityPin = useAppSelector(state => state.consultationNote.securityPin); 

    const [url, setUrl] = useState(''); 

    // -- 

    useEffect(() =>  {

        if (consultationNoteId && consultationId) {
          
          setUrl(`https://bridge.medca.ai/login/mobile/consultation/${consultationId}/note/${consultationNoteId}`); 

        }; 

    },[consultationNoteId, consultationId]); 



    // -- 

    return (

      <Box 
        sx={{ 
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center', 
            marginTop: 2, 
            width: '100%', 
      }}>

        <QRCodeCanvas value={url} size={window.innerWidth * 0.125} />

        <Box 
            sx={{ 
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center', 
                marginTop: 2, 
                width: '80%', 
                textAlign: 'center', 
                color: colors?.text
        }}>{t('useConsultationNoteQrCode')}</Box>

        { securityPin && 
          <Box 
            sx={{ 
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center', 
                marginTop: 2, 
                width: '80%', 
                textAlign: 'center', 
                color: colors?.text
        }}>Security Pin: {securityPin}</Box>} 

      </Box>

  );

};

export default QrCode;