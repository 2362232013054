import React, { useState, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../../redux/hooks';
import { useNavigate } from 'react-router-dom';

// Redux 

import { useTranslation } from 'react-i18next';
import { setClosePopup } from '../../../../../redux/features/general/popup';

import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import { serveFile } from '../../../../../routes/doctor/files';
import ClearIcon from '@mui/icons-material/Clear';
import LinearProgress from '@mui/material/LinearProgress';
import { setIsImageSlideOpened } from '../../../../../redux/features/doctor/Dashboard/consultation/consultation';
import { resetImageViewer } from '../../../../../redux/features/doctor/Dashboard/documents/imageViewer';

type Props = {
    children?: React.ReactNode,
    isPortraitMode?: boolean,
};

const NewImageSlide: React.FC<Props> = ({ children, isPortraitMode }) => {

    const dispatch = useAppDispatch();
    const navigate = useNavigate(); 
    const { t } = useTranslation();

    const colors = useAppSelector(state => state.theme.colors);

    const selectedImage = useAppSelector(state => state.imageViewer.selectedImage) as any; 

    const [fileUrl, setFileUrl] = useState(''); 
    const [progress, setProgress] = useState(0);
    const [timeLeft, setTimeLeft] = useState(3); 

    // -- 

    const handleImage = async () => { 

        const res = await serveFile(selectedImage._id, 'image') as any; 
        
        const fileBlob = new Blob([res.data], { type: res.headers['content-type'] });
    
        setFileUrl(URL.createObjectURL(fileBlob)); 

    }; 

    const clearImageSlide = () => { 

        dispatch(resetImageViewer()); 
        dispatch(setIsImageSlideOpened(false)); 

    }; 

    // -- 

    useEffect(()=>{

        let myInterval = setInterval(() => {

            setProgress(progress + (100 / 3));

            if (timeLeft > 0) {

                setTimeLeft(timeLeft - 1);

            }; 

            if (timeLeft === 0) {

                clearImageSlide(); 

                if (timeLeft === 0) {
                    clearInterval(myInterval)
                };
            }; 

        }, 500); 

        return ()=> {
            clearInterval(myInterval);
        };

    },[timeLeft]);

    // -- 

    useEffect(() => { 

        if (selectedImage) { 

            handleImage(); 

        }; 

    },[selectedImage]); 

    // -- 

    return (
        <Box sx={{ 
            display: 'flex', 
            flexDirection: 'column-reverse',
            alignItems: 'center', 
            width: 500,
            height: 'auto',
            borderRadius: 5,
            backgroundColor: 'black',
            overflow: 'hidden', 
            position: 'absolute', 
            bottom: 10, 
            right: 10, 
        }}>

            <Box
                sx={{ 
                    display: 'flex', 
                    flexDirection: 'row',
                    alignItems: 'center', 
                    justifyContent: 'space-between',
                    width: '100%',
                    marginTop: 2, 
                    paddingLeft: 4, 
                    paddingRight: 4, 
                    color: 'white', 
                    marginBottom: 5, 
                }}
            >

                <Box>{selectedImage?.title}</Box> 

                <ClearIcon onClick={clearImageSlide} sx={{ color: 'white', cursor: 'pointer' }} /> 

            </Box>

            <Box sx={{ width: '100%' }}>
                <LinearProgress variant="determinate" value={progress} />
            </Box>    

            {fileUrl ?
                <Box
                    id='image-viewer'
                    component="img"
                    sx={{
                        height: 'auto',
                        maxWidth: 500,
                    }}
                    alt={selectedImage?.title}
                    src={fileUrl}
                />: 
                <CircularProgress size={30} sx={{ marginBottom: 2 }} />}

        </Box>
  );
};

export default NewImageSlide;